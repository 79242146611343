import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import styled from 'styled-components'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { ButtonWithDrop, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { CheckBox } from 'ui-lib/components/Checkbox'
import { SelectSearch, CreatableSelect } from 'ui-lib/components/Select'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import NotVerifiedIcon from 'ui-lib/icons/not_verified.svg'
import VerifiedIcon from 'ui-lib/icons/verified.svg'
import { getIn } from 'timm'
import { SortDown, SortReset } from 'ui-lib/components/Table'
import { Input } from 'ui-lib/components/Input'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { getNextSortOrder } from 'ui-tdm-app/utils/helpers'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import theme from 'ui-lib/utils/base-theme'
import {
	NAME_ALIASES,
	SortableKeys,
	TABLE_ALIAS,
	MARKETPLACE_TABLE_ALIAS,
	RFQ_STATUS,
	STATUS_TEXT_COLOR,
	STATUS_BACKGROUND,
	MONTHS,
	SHIPMENT_PERIOD,
} from '../config'
import { Status } from './Status'

const LinkLabel = styled(Label)({
	color: '#0000EE',
	textDecoration: 'underline',
	'&:hover': {
		color: '#551A8B',
	},
})

export const getColumnConfig = ({
	type,
	docType,
	sortsMap,
	onChange,
	orgDetails,
	activeLocale,
	actor,
	t,
	getCurrentOrgID,
	isMobile,
	selectedCPID,
	uploadDOAcceptedList = [],
}) => {
	const partnerKey =
		type === 'incoming' ? 'initiatingPartyID' : 'receivingPartyID'

	const isMill = actor.includes('palmoil_mill') || actor.includes('rice-mill')
	const isFFBDealer =
		actor.includes('palmoil_ffbdealer') || actor.includes('rice-dealer')
	const isPlantation = actor.includes('palmoil_plantation')

	const menuWithCPO = [
		{
			label: t('common.weighbridge'),
			name: 'weighbridge-slip',
		},
		{
			label: t('common.cpoQuality'),
			name: 'quality-cpo',
		},
	]

	const menuWithWeighbridgeOnly = [
		{
			label: t('common.weighbridge'),
			name: 'weighbridge-slip',
		},
	]

	const menuWithoutReports = [
		{
			label: t('common.weighbridge'),
			name: 'weighbridge-slip',
		},
	]

	const getAttachment = datum => {
		if (datum?.meta?.cpoAttachment) {
			return menuWithWeighbridgeOnly
		}

		return menuWithCPO
	}

	const getProductNameList = document => {
		const nameArray = []

		if (document?.products?.length > 0) {
			document.products.forEach(item => {
				if (!nameArray.find(ele => ele === item?.name)) {
					nameArray.push(item?.name)
				}
			})
		}

		return nameArray.length > 0 ? nameArray.join(', ') : ''
	}

	const getTotalProductsQty = document => {
		let total = 0
		if (document?.products?.length > 0) {
			total = document?.products.reduce(
				(acc, prodObj) => acc + (prodObj?.quantity || 0),
				0
			)
		}

		return total
	}

	const getQty = datum => {
		return `${getIn(datum, ['meta', 'quantityNonMetric']) ||
			getTotalProductsQty(datum) ||
			0} ${
			(actor.includes('palmoil_plantation') &&
				docType === 'delivery-order' &&
				getCurrentOrgID === datum?.initiatingPartyID) ||
			((actor.includes('palmoil_ffbdealer') ||
				actor.includes('palmoil_ffbsubdealer')) &&
				docType !== 'purchase-order' &&
				getCurrentOrgID !== datum?.initiatingPartyID) ||
			(actor.includes('palmoil_mill') &&
				docType !== 'purchase-order' &&
				getCurrentOrgID !== datum?.initiatingPartyID &&
				getIn(datum, ['meta', 'uomNonMetric']))
				? `(${getIn(datum, ['meta', 'uomNonMetric']) || 'Nos'})`
				: `(${getIn(datum, ['meta', 'uomNonMetric']) || 'MT'})`
		}`
	}

	return [
		...(!isMobile
			? [
					{
						property: 'createdAt',
						header: (
							<Box
								overflow
								row
								alignItems="center"
								style={{ cursor: 'pointer', width: '120px' }}
								onClick={() => {
									if (SortableKeys.includes('createdAt')) {
										// call onChange with appropriate invocation
										onChange('initiate_sort', {
											type,
											docType,
											field: 'createdAt',
											order: getNextSortOrder(
												sortsMap.createdAt
											),
										})
									}
								}}
							>
								{SortableKeys.includes('createdAt') && (
									<IconWrapper
										width={10}
										margin="0 5px"
										sortUp={sortsMap.createdAt === 'asc'}
										sortDown={sortsMap.createdAt === 'desc'}
									>
										<Icon
											rotate180={
												sortsMap.createdAt === 'asc'
											}
											glyph={
												sortsMap.createdAt
													? SortDown
													: SortReset
											}
										/>
									</IconWrapper>
								)}
								<TableTitle>
									{type === 'incoming'
										? t(TABLE_ALIAS.createdAt)
										: t(TABLE_ALIAS.issueDate)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const { createdAt } = datum
							const issueDate =
								getIn(datum, ['meta', 'issueDate']) || createdAt

							return (
								<Label as="span">
									{getDateByFormat(
										issueDate,
										null,
										activeLocale
									)}
								</Label>
							)
						},
						size: '15%',
					},

					{
						property: partnerKey,
						header: (
							<Box
								overflow
								row
								alignItems="center"
								style={{ cursor: 'pointer' }}
								onClick={() => {
									if (SortableKeys.includes(partnerKey)) {
										// call onChange with appropriate invocation
										onChange('initiate_sort', {
											type,
											docType,
											field: partnerKey,
											order: getNextSortOrder(
												sortsMap[partnerKey]
											),
										})
									}
								}}
							>
								{SortableKeys.includes(partnerKey) && (
									<IconWrapper
										width={10}
										margin="0 5px"
										sortUp={sortsMap[partnerKey] === 'asc'}
										sortDown={
											sortsMap[partnerKey] === 'desc'
										}
									>
										<Icon
											rotate180={
												sortsMap[partnerKey] === 'asc'
											}
											glyph={
												sortsMap[partnerKey]
													? SortDown
													: SortReset
											}
										/>
									</IconWrapper>
								)}
								<TableTitle>
									{t(TABLE_ALIAS.partner)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const targetKey =
								type === 'incoming'
									? datum.initiatingPartyID
									: datum.receivingPartyID
							const _orgDetails = orgDetails[targetKey]

							let orgName = ' - - - '
							if (_orgDetails) {
								const category =
									getIn(_orgDetails, [
										'categories',
										0,
										'id',
									]) || ''

								const parentName =
									getIn(_orgDetails, [
										'meta',
										'parentOrganization',
										0,
										'name',
									]) || ''
								const hasParent =
									category === 'palmoil-collectionPoint' &&
									parentName !== ''
								const name = getIn(_orgDetails, ['name'])
								orgName = hasParent
									? `${parentName} - ${name}`
									: name
							} else if (datum?.meta?.receiverEmailID) {
								orgName = datum.meta.receiverEmailID
							} else if (
								getIn(datum, ['meta', 'enableGlobalPartner']) &&
								datum.receivingPartyID === getCurrentOrgID
							) {
								orgName = getIn(datum, [
									'meta',
									'globalInitiator',
									'name',
								])
							} else if (
								getIn(datum, ['meta', 'enableGlobalPartner']) &&
								datum.receivingPartyID !== getCurrentOrgID
							) {
								orgName = getIn(datum, [
									'meta',
									'globalReceiver',
									'name',
								])
							}

							return <Label as="span">{orgName}</Label>
						},
						size: '20%',
					},
			  ]
			: ''),
		{
			property: 'id',
			primary: true,
			header: (
				<Box overflow row>
					<TableTitle>{`${t(NAME_ALIASES[docType])} #`}</TableTitle>
				</Box>
			),
			render: datum => (
				<LinkLabel
					as="span"
					link
					onClick={() => {
						onChange('open_document', { row: datum })
					}}
				>
					{datum.number}
				</LinkLabel>
			),
			size: isMobile ? '10%' : '20%',
		},
		{
			property: 'product',
			primary: true,
			header: (
				<Box overflow row>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => (
				<>
					<Label as="span">{getProductNameList(datum)}</Label>
					{isMobile && <Label as="span">{getQty(datum)}</Label>}
				</>
			),
			size: isMobile ? '15%' : '18%',
		},
		...(!isMobile
			? [
					{
						property: 'quantity',
						primary: true,
						header: (
							<TableTitle>{t(TABLE_ALIAS.quantity)}</TableTitle>
						),
						render: datum => (
							<Label as="span">{getQty(datum)}</Label>
						),
						size: '15%',
					},
			  ]
			: ''),
		{
			property: 'status',
			header: (
				<Box overflow row style={{ cursor: 'pointer', width: '100px' }}>
					<TableTitle>{t(TABLE_ALIAS.status)}</TableTitle>
				</Box>
			),
			render: datum => {
				const status = getIn(datum, ['status', 'state'])

				return (
					<Status
						currentStatus={status}
						editable={
							type === 'incoming' && status === 'submitted '
						}
						isDocumentOwner={type === 'outgoing'}
						onChange={active => {
							onChange('change_status', {
								type,
								docType,
								row: datum,
								newStatus: active,
							})
						}}
					/>
				)
			},
			size: isMobile ? '10%' : '15%',
		},
		...(!isMobile && docType === 'delivery-order' && type === 'incoming'
			? [
					{
						property: 'weighBridgeStatus',
						header: (
							<Box
								overflow
								style={{
									cursor: 'pointer',
									alignItems: 'center',
								}}
							>
								<TableTitle>
									{t('breadcrumb.wbSlipText')}
								</TableTitle>
								<TableTitle>{t('common.attached')}</TableTitle>
							</Box>
						),
						render: datum => {
							const weighBridgeAttached = getIn(datum, [
								'meta',
								'wbAttachment',
							])

							return (
								<Box style={{ alignItems: 'center' }}>
									<IconWrapper
										color={
											weighBridgeAttached
												? 'green'
												: 'red'
										}
										size={20}
									>
										<Icon
											glyph={
												weighBridgeAttached
													? VerifiedIcon
													: NotVerifiedIcon
											}
										/>
									</IconWrapper>
								</Box>
							)
						},
						size: '10%',
					},
			  ]
			: []),
		...(docType === 'delivery-order'
			? [
					{
						property: 'eudrCompliance',
						header: (
							<Box overflow row>
								<TableTitle>EUDR Compliant</TableTitle>
							</Box>
						),
						render: datum => {
							const isEUDRCompliant =
								isMill || isFFBDealer
									? datum?.meta?.incomingEUDRCompliance
									: datum?.meta?.eudrCompliance

							return (
								<Box style={{ alignItems: 'center' }}>
									<IconWrapper
										color={
											isEUDRCompliant ? 'green' : 'red'
										}
										size={20}
									>
										<Icon
											glyph={
												isEUDRCompliant
													? VerifiedIcon
													: NotVerifiedIcon
											}
										/>
									</IconWrapper>
								</Box>
							)
						},
						size: isMobile ? '15%' : '18%',
					},
					{
						property: 'addAttachment',
						header: (
							<Box
								overflow
								row
								style={{
									cursor: 'pointer',
									justifyContent: 'center',
									width:
										docType === 'delivery-order'
											? '80px'
											: 'auto',
								}}
							>
								{type === 'incoming' &&
									docType === 'delivery-order' && (
										<TableTitle>
											{t('common.accept')}
										</TableTitle>
									)}
							</Box>
						),
						render: datum => {
							const status = getIn(datum, ['status', 'state'])

							if (!selectedCPID) {
								if (
									type === 'incoming' &&
									docType === 'delivery-order'
								) {
									let content
									if (
										status === 'submitted' ||
										status === 'acknowledged'
									) {
										content = (
											<Box
												style={{
													alignItems: 'center',
													paddingLeft: '10px',
												}}
											>
												<CheckBox
													key={datum?.id}
													hideError
													name={datum?.id}
													id={datum?.id}
													checked={uploadDOAcceptedList.find(
														item =>
															item.id ===
															datum?.id
													)}
													returnKeyValue
													onChange={data => {
														onChange(
															'handle_accept_checkbox',
															{
																checkboxData: data,
																entity: datum,
															}
														)
													}}
												/>
											</Box>
										)
									}

									return content
								}
								if (
									type === 'outgoing' &&
									docType === 'delivery-order'
								) {
									return (
										<ButtonWithDrop
											items={
												isMill
													? getAttachment(datum)
													: menuWithoutReports
											}
											disabled={status === 'rejected'}
											label={t('common.add')}
											noBackground
											onChange={active =>
												onChange('create_attachment', {
													docType: active,
													row: datum,
												})
											}
										/>
									)
								}
							}
						},
						size: '15%',
					},
			  ]
			: []),
	]
}

export const contractsColumnConfig = ({
	t,
	type,
	activeLocale,
	onChange,
	isMobile = false,
}) => {
	const action = 'view'

	return [
		...(!isMobile
			? [
					{
						property: 'date',
						header: (
							<Box
								row
								alignItems="center"
								style={{ cursor: 'pointer' }}
								onClick={() => {}}
							>
								<TableTitle>
									{type === 'incoming'
										? t(TABLE_ALIAS.dateReceived)
										: t(TABLE_ALIAS.dateSent)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const date = getIn(datum, ['createdAt'])

							return (
								<Label as="span">
									{getDateByFormat(date, null, activeLocale)}
								</Label>
							)
						},
						size: '11%',
					},
			  ]
			: []),
		{
			property: 'orgName',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer' }}
					onClick={() => {}}
				>
					<TableTitle>
						{type === 'incoming'
							? t(TABLE_ALIAS.sellerName)
							: t(TABLE_ALIAS.buyerName)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const buyerName = getIn(datum, ['contract', 'buyer', 'name'])
				const sellerName = getIn(datum, ['contract', 'seller', 'name'])

				return (
					<Label as="span">
						{type === 'incoming' ? sellerName : buyerName}
					</Label>
				)
			},
			size: '11%',
		},
		{
			property: 'broker',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer' }}
					onClick={() => {}}
				>
					<TableTitle>{t(TABLE_ALIAS.broker)}</TableTitle>
				</Box>
			),
			render: datum => {
				const brokerName = getIn(datum, ['contract', 'broker', 'name'])

				return <Label as="span">{brokerName}</Label>
			},
			size: '10%',
		},
		...(!isMobile
			? [
					{
						property: 'contractType',
						header: (
							<Box
								row
								alignItems="center"
								style={{ cursor: 'pointer' }}
								onClick={() => {}}
							>
								<TableTitle>
									{t(TABLE_ALIAS.contractType)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const contractType = getIn(datum, [
								'contractType',
							]).toUpperCase()

							return (
								<Box row center>
									<Label as="span">{contractType}</Label>
								</Box>
							)
						},
						size: '10%',
					},
			  ]
			: []),
		{
			property: 'product',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer' }}
					onClick={() => {}}
				>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const product = getIn(datum, [
					'contract',
					'product',
					'product',
					'name',
				])
				const quantity = getIn(datum, ['contract', 'quantity'])

				return (
					<div>
						<Label as="span">{product}</Label>
						{isMobile && <Label as="span">{quantity}</Label>}
					</div>
				)
			},
			size: '10%',
		},
		...(!isMobile
			? [
					{
						property: 'quantityWithUOM',
						header: (
							<Box
								row
								alignItems="center"
								style={{ cursor: 'pointer' }}
								onClick={() => {}}
							>
								<TableTitle>
									{t(TABLE_ALIAS.quantityWithUOM)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const quantity = getIn(datum, [
								'contract',
								'quantity',
							])

							return <Label as="span">{quantity}</Label>
						},
						size: '9%',
					},
			  ]
			: []),
		...(!isMobile
			? [
					{
						property: 'deliveryPeriod',
						header: (
							<Box
								row
								alignItems="center"
								style={{ cursor: 'pointer' }}
								onClick={() => {}}
							>
								<TableTitle>
									{t(TABLE_ALIAS.deliveryPeriod)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							return (
								<Label as="span">{`${t(
									MONTHS[
										getIn(datum, [
											'contract',
											'deliveryPeriod',
											'month',
										])
									]
								)}, ${t(
									SHIPMENT_PERIOD[
										getIn(datum, [
											'contract',
											'deliveryPeriod',
											'period',
										])
									]
								)}`}</Label>
							)
						},
						size: '12%',
					},
			  ]
			: []),
		{
			property: 'status',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer' }}
					onClick={() => {}}
				>
					<TableTitle>{t(TABLE_ALIAS.statusLabel)}</TableTitle>
				</Box>
			),
			render: datum => {
				const status = getIn(datum, [
					'status',
					getIn(datum, ['status']).length - 1,
					'status',
				])

				return <Status currentStatus={status} isMobile={isMobile} />
			},
			size: '10%',
		},
		{
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() => {
							onChange('open_contract', {
								row: datum,
								action,
								type,
							})
						}}
					/>
				)
			},
			size: '10%',
		},
	]
}

export const rfqColumnConfig = ({ t, onChange, type }) => {
	return [
		{
			property: 'dateSent',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{type === 'incoming'
							? t(MARKETPLACE_TABLE_ALIAS.dateReceived)
							: t(MARKETPLACE_TABLE_ALIAS.dateSent)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const dateSent = getIn(datum, ['createdAt'])

				return <Label>{getDateByFormat(dateSent)}</Label>
			},
		},
		{
			property: 'seller-buyer',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{type === 'incoming'
							? t(MARKETPLACE_TABLE_ALIAS.buyer)
							: t(MARKETPLACE_TABLE_ALIAS.seller)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const buyer = getIn(datum, ['buyer', 'name'])
				const seller = getIn(datum, ['seller', 'name'])

				const org = type === 'incoming' ? buyer : seller

				return <Label>{org}</Label>
			},
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(MARKETPLACE_TABLE_ALIAS.product)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const productName = getIn(datum, ['product', 'name'])

				return <Label>{productName}</Label>
			},
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(MARKETPLACE_TABLE_ALIAS.quantity)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const quantity = getIn(datum, ['quantity'])
				const uom = getIn(datum, ['product', 'uom']) || ''

				return (
					<Label>
						{quantity} {uom.toUpperCase()}
					</Label>
				)
			},
		},
		{
			property: 'status',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(MARKETPLACE_TABLE_ALIAS.status)}</TableTitle>
				</Box>
			),
			render: datum => {
				const status = getIn(datum, ['status'])

				return (
					<Box
						style={{
							backgroundColor: STATUS_BACKGROUND[status],
							padding: '8px 16px',
							margin: '0 0 8px',
							borderRadius: '5px',
							opacity: 1,
							textAlign: 'center',
						}}
					>
						<Label
							style={{
								color: STATUS_TEXT_COLOR[status],
							}}
						>
							{t(RFQ_STATUS[status]).toUpperCase()}
						</Label>
					</Box>
				)
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() => {
							onChange('view_rfq', {
								row: datum,
								type,
							})
						}}
					/>
				)
			},
		},
	]
}

export const quoteColumnConfig = ({ t, onChange, type }) => {
	return [
		{
			property: 'dateSent',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(MARKETPLACE_TABLE_ALIAS.dateSent)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const dateSent = getIn(datum, ['createdAt'])

				return <Label>{getDateByFormat(dateSent)}</Label>
			},
		},
		{
			property: 'buyer',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{type === 'incoming'
							? t(MARKETPLACE_TABLE_ALIAS.seller)
							: t(MARKETPLACE_TABLE_ALIAS.buyer)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const buyer = getIn(datum, ['buyerOrg', 'name'])
				const seller = getIn(datum, ['organization', 'name'])

				const org = type === 'incoming' ? seller : buyer

				return <Label>{org}</Label>
			},
		},
		{
			property: 'country',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(MARKETPLACE_TABLE_ALIAS.country)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const country =
					getIn(datum, ['buyerOrg', 'primaryAddress', 'country']) ||
					'---'

				return <Label>{country}</Label>
			},
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(MARKETPLACE_TABLE_ALIAS.product)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const product = getIn(datum, ['rfq', 'product', 'name'])

				return <Label>{product}</Label>
			},
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(MARKETPLACE_TABLE_ALIAS.quantity)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const quantity = getIn(datum, ['quantity'])
				const uom = getIn(datum, ['rfq', 'product', 'uom']) || ''

				return (
					<Label>
						{quantity} {uom.toUpperCase()}
					</Label>
				)
			},
		},
		{
			property: 'status',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(MARKETPLACE_TABLE_ALIAS.status)}</TableTitle>
				</Box>
			),
			render: datum => {
				const status = getIn(datum, ['status'])

				return (
					<Box
						style={{
							backgroundColor: STATUS_BACKGROUND[status],
							padding: '8px 16px',
							margin: '0 0 8px',
							borderRadius: '5px',
							opacity: 1,
							textAlign: 'center',
						}}
					>
						<Label
							style={{
								color: STATUS_TEXT_COLOR[status],
							}}
						>
							{t(RFQ_STATUS[status]).toUpperCase()}
						</Label>
					</Box>
				)
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() => {
							onChange('view_quote', {
								row: datum,
								type,
							})
						}}
					/>
				)
			},
		},
	]
}

export const storageUnitColumnConfig = storageUnitColomnProps => {
	const {
		storageUnits,
		onChange,
		storageUnitData,
		isEdit,
		baseProduct,
		targetProducts,
		t,
		isMobile,
		attachFlow = false,
	} = storageUnitColomnProps
	const tableArray = []
	const getStorageValue = (storageUnitID, quantityData = false) => {
		let quantity = 0
		const product = quantityData ? targetProducts : baseProduct
		if (isEdit && product && product.length > 0) {
			product.forEach(item => {
				if (item.storageUnitID === storageUnitID) {
					// eslint-disable-next-line prefer-destructuring
					quantity = item.quantity
				}
			})
		}

		return quantity
	}

	if (!isMobile) {
		tableArray.push(
			{
				property: 'storageUnit',
				header: (
					<Box center style={{ cursor: 'pointer' }}>
						<Label bold style={{ color: '#4B4552', fontSize: 16 }}>
							{t('storage.storageUnit')}
						</Label>
					</Box>
				),
				render: datum => {
					const { storageUnitID } = datum
					const storageUnit = storageUnits.filter(
						unit => unit.id === storageUnitID
					)

					const storageUnitName = getIn(storageUnit, [0, 'name'])

					return (
						<Box center>
							<Label as="span">{storageUnitName}</Label>
						</Box>
					)
				},
			},
			{
				property: 'availableQty',
				header: (
					<Box center style={{ cursor: 'pointer' }}>
						<Label bold style={{ color: '#4B4552', fontSize: 16 }}>
							{`${t('tdmDetailsEntry.availableQty')} (MT)`}
						</Label>
					</Box>
				),
				render: datum => {
					const { storageUnitID } = datum
					const availableQty = getIn(datum, ['availableQty']) || 0

					return (
						<Box center>
							<Label as="span">
								{
									(
										availableQty +
										getStorageValue(storageUnitID)
									)
										.toString()
										.match(/^-?\d+(?:\.\d{0,3})?/)[0]
								}
							</Label>
						</Box>
					)
				},
			}
		)
	}
	tableArray.push({
		property: 'quantity',
		header: (
			<Box
				center
				style={{
					cursor: 'pointer',
					width: isMobile ? '214px' : 'auto',
				}}
			>
				<Label bold style={{ color: '#4B4552', fontSize: 16 }}>
					{`${t('tdmDetailsEntry.quantity')} (MT)`}
				</Label>
			</Box>
		),
		render: datum => {
			const { productID, storageUnitID, availableQty = 0 } = datum
			const storageUnit = storageUnitData.filter(
				st => st.storageUnitID === storageUnitID
			)
			const storageUnitForName = storageUnits.filter(
				unit => unit.id === storageUnitID
			)

			const quantityEntered = getIn(storageUnit, [0, 'quantity']) || 0
			const storageUnitName = getIn(storageUnitForName, [0, 'name'])

			const errorMessage =
				'Should be less than or equal to available quantity'
			const maxQuantity = availableQty + getStorageValue(storageUnitID)
			const getWidth = (currentWidth, mobileWidth) => {
				let width = currentWidth
				if (isMobile) {
					width = mobileWidth
				}

				return width
			}

			return (
				<Box
					center
					style={{
						width: attachFlow ? getWidth('auto', '214px') : '285px',
					}}
				>
					{isMobile && (
						<>
							<Box center style={{ marginBottom: '10px' }}>
								<Label
									bold
									style={{ color: '#4B4552', fontSize: 16 }}
								>
									{`${t('storage.storageUnit')} : `}
								</Label>
								<Label as="span">{storageUnitName}</Label>
							</Box>
							<Box center style={{ marginBottom: '10px' }}>
								<Label
									bold
									style={{ color: '#4B4552', fontSize: 16 }}
								>
									{`${t(
										'tdmDetailsEntry.availableQty'
									)} (MT) : `}
								</Label>
								<Label as="span">
									{availableQty +
										getStorageValue(storageUnitID)}
								</Label>
							</Box>
						</>
					)}
					<Input
						name="quantity"
						type="number"
						error={quantityEntered > maxQuantity && errorMessage}
						hideError={quantityEntered <= maxQuantity}
						value={getStorageValue(storageUnitID, true)}
						onChange={e => {
							onChange('storage_unit', {
								productID,
								storageUnitID,
								availableQty: maxQuantity,
								quantity: Number(e.target.value),
							})
						}}
						extendStyles={{
							width: isMobile ? 150 : 220,
							marginLeft: isMobile ? 33 : 50,
							marginRight: attachFlow ? 26 : 'auto',
						}}
					/>
				</Box>
			)
		},
	})

	return tableArray
}

export const excelUploadColumnConfig = ({
	t,
	onChange,
	getCurrentOrgID,
	setFullOrgList,
	uploadDODraftAcceptedList = [],
	fullOrgList = [],
	partnerList = [],
	isMobile = false,
	allProducts = [],
}) => {
	return [
		{
			property: 'accept',
			header: (
				<Box
					overflow
					row
					style={{
						cursor: 'pointer',
						justifyContent: 'center',
						width: 'auto',
					}}
				>
					<TableTitle>{t('common.accept')}</TableTitle>
				</Box>
			),
			render: datum => {
				return (
					<Box
						style={{
							paddingLeft: 10,
							alignItems: 'center',
						}}
					>
						<CheckBox
							key={datum?.id}
							hideError
							name={datum?.id}
							id={datum?.id}
							checked={uploadDODraftAcceptedList.find(
								item => item.id === datum?.id
							)}
							returnKeyValue
							onChange={data => {
								onChange('handle_draft_accept_checkbox', {
									checkboxData: data,
									entity: datum,
									currentUploadDODraftAcceptedList: uploadDODraftAcceptedList,
								})
							}}
						/>
					</Box>
				)
			},
			size: '10%',
		},
		...(!isMobile
			? [
					{
						property: 'date',
						header: (
							<Box
								row
								alignItems="center"
								style={{ cursor: 'pointer' }}
							>
								<TableTitle>
									{t(TABLE_ALIAS.generatedDate)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const dateSent = datum.meta.issueDate

							return (
								<DatePicker
									enableCurrentTime
									required
									noBorder
									hideError
									toDate
									name={`issueDate-${datum?.id}`}
									maxDate={new Date()}
									value={dateSent || new Date()}
									onChange={value => {
										const data = { ...datum }
										const currentMeta = { ...datum.meta }
										// Changing the selected value to time 00.00.00 as the backend needs local date with time 00
										currentMeta.issueDate = `${
											value.split('T')[0]
										}T00:00:00.000Z`
										data.meta = currentMeta
										onChange('handle_draft_change', {
											data,
											currentUploadDODraftAcceptedList: uploadDODraftAcceptedList,
										})
									}}
								/>
							)
						},
						size: '15%',
					},
			  ]
			: ''),
		{
			property: 'docNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t('productTrace.docNumber')}</TableTitle>
				</Box>
			),
			render: datum => {
				const documentNumber = datum.number || '---'

				return (
					<Input
						name="entityReference"
						value={documentNumber}
						id={datum.id}
						hideError
						extendStyles={{
							border: 'none',
							borderRadius: 0,
							borderBottom: `1px solid ${theme.color.blue8}`,
						}}
						// onChange refrshing the whole component and focus shifted
						onMouseOut={event => {
							const data = { ...datum }
							data.number = event.target.value
							onChange('handle_draft_change', {
								data,
								currentUploadDODraftAcceptedList: uploadDODraftAcceptedList,
							})
						}}
						onBlur={() => {}}
					/>
				)
			},
			size: '20%',
		},
		{
			property: 'partner',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.partner)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { initiatingPartyID, receivingPartyID } = datum
				const outgoingDoc = getCurrentOrgID === initiatingPartyID
				const partnerOrgID = outgoingDoc
					? receivingPartyID
					: initiatingPartyID
				const partnerNotInList = outgoingDoc
					? getIn(datum, ['meta', 'globalReceiver', 'name'])
					: getIn(datum, ['meta', 'globalInitiator', 'name'])
				const partnerName = getIn(datum, [
					'meta',
					'enableGlobalPartner',
				])
					? partnerNotInList ||
					  fullOrgList.find(item => item.id === partnerOrgID)
							?.label ||
					  ''
					: fullOrgList.find(item => item.id === partnerOrgID)
							?.label || ''

				return (
					<div>
						<CreatableSelect
							name="name"
							type="text"
							options={fullOrgList}
							disableBoxShadow
							value={partnerName}
							onChange={value => {
								const data = { ...datum }
								if (outgoingDoc) {
									data.receivingPartyID = value
									data.receivingUserID = value
								} else {
									data.initiatingPartyID = value
									data.initiatingUserID = value
								}

								const currentFullList = [...fullOrgList]
								const currentMeta = { ...datum.meta }
								currentMeta.enableGlobalPartner = !partnerList.find(
									item => item.id === value
								)
								if (
									!partnerList.find(item => item.id === value)
								) {
									if (outgoingDoc) {
										currentMeta.globalReceiver = {
											name: fullOrgList.find(
												item => item.id === value
											)
												? fullOrgList.find(
														item =>
															item.id === value
												  ).label
												: value,
										}
									} else {
										currentMeta.globalInitiator = {
											name: fullOrgList.find(
												item => item.id === value
											)
												? fullOrgList.find(
														item =>
															item.id === value
												  ).label
												: value,
										}
									}
								} else {
									currentMeta.globalReceiver = {}
									currentMeta.globalInitiator = {}
								}

								data.meta = currentMeta
								if (
									!fullOrgList.find(item => item.id === value)
								) {
									currentFullList.push({
										id: value,
										label: value,
										value,
									})
									setFullOrgList(currentFullList)
								}

								onChange('handle_draft_change', {
									data,
									currentUploadDODraftAcceptedList: uploadDODraftAcceptedList,
								})
							}}
							minLenth={3}
							returnOnlyValue
							singleBorderOnly
						/>
					</div>
				)
			},
			size: '22%',
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(MARKETPLACE_TABLE_ALIAS.product)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const product = datum.products[0].name
				const currentAllProducts = [...allProducts]
				if (!currentAllProducts.find(item => item.name === product)) {
					currentAllProducts.push(datum.products[0])
				}

				const activeProducts = currentAllProducts.map(
					({ code: _code, name }) => {
						return {
							name,
							label: `${name} (#${_code})`,
						}
					}
				)

				return (
					<div>
						<SelectSearch
							name="product"
							id="product"
							placeholder={`${t('tdmDocumentListing.choose')} ${t(
								'tdmDocumentListing.one'
							)}`}
							hideError
							disableChooseOne
							options={activeProducts}
							onChange={product => {
								const data = { ...datum }
								const products = [...datum.products]
								const productItem = { ...products[0] }
								const selectedProduct = currentAllProducts.find(
									item => item.name === product.product.name
								)
								if (productItem.name !== selectedProduct.name) {
									productItem.name = selectedProduct.name
									productItem.id = selectedProduct.id
									productItem.code = selectedProduct.code
								}
								products[0] = productItem
								data.products = products
								onChange('handle_draft_change', {
									data,
									currentUploadDODraftAcceptedList: uploadDODraftAcceptedList,
								})
							}}
							value={product}
							labelKey="label"
							valueKey="name"
							style={{
								border: 'none',
								borderBottom: `1px solid ${theme.color.blue8}`,
							}}
						/>
					</div>
				)
			},
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(MARKETPLACE_TABLE_ALIAS.quantity)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const { quantity } = datum.products[0]
				const currentUom = getIn(datum, ['meta', 'uomNonMetric'])
					? getIn(datum, ['meta', 'uomNonMetric'])
					: getIn(datum, ['products', 0, 'uom']) || ''

				return (
					<div style={{ display: 'flex', paddingRight: '50px' }}>
						<Input
							name="quantity"
							value={quantity}
							type="number"
							hideError
							extendStyles={{
								textAlign: 'center',
								border: 'none',
								borderRadius: 0,
								borderBottom: `1px solid ${theme.color.blue8}`,
							}}
							// onChange refreshing the whole component and focus shifted
							onMouseOut={event => {
								const data = { ...datum }
								const products = [...datum.products]
								const productItem = { ...products[0] }
								productItem.quantity = Number(
									event.target.value
								)
								if (data?.meta?.uomNonMetric === 'Nos') {
									const meta = { ...data.meta }
									meta.quantityNonMetric = Number(
										event.target.value
									)
									data.meta = meta
								}
								products[0] = productItem
								data.products = products
								onChange('handle_draft_change', {
									data,
									currentUploadDODraftAcceptedList: uploadDODraftAcceptedList,
								})
							}}
							onBlur={() => {}}
						/>
						<div
							style={{
								display: 'flex',
								fontSize: '14px',
								alignItems: 'center',
								paddingLeft: '10px',
							}}
						>
							{currentUom}
						</div>
					</div>
				)
			},
			size: '15%',
		},
	]
}
