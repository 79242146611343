import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Box } from 'ui-lib/utils/Box'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import theme from 'ui-lib/utils/base-theme'
import { getIn } from 'timm'
import { useTranslation } from 'react-i18next'
import { getDateWithTimeByFormat, getDateByFormat } from 'ui-tdm-app/utils/date'
import backArrow from 'ui-lib/icons/arrow_back.svg'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import DownloadIcon from 'ui-lib/icons/file_download_black_24dp.svg'
import SuccessIcon from 'ui-lib/icons/success-round.svg'
import { useReactToPrint } from 'react-to-print'
import Html2Pdf from 'html2pdf.js'
import { Spacer } from 'ui-lib/utils/Spacer'
import { getAutoProductionInterval } from 'ui-tdm-app/config'
import {
	getVerificationStatus,
	getHeader,
	traceDetails,
	originatingOrganizationDetails,
	destinationOrganizationDetails,
	handleCertificateData,
} from '../../../components/TraceReportComponents'
import { colorArray } from '../../../helpers'
import { CollapsibleDocStacks } from '../../../components/CollapsibleDocStacks'
import { TraceReportTable } from '../../../components/TraceReportTable'
import { TradeDocDuc } from '../../../duc'

const LinkWrap = styled.div`
	&:hover {
		text-decoration: underline;
	}
`

const HeaderWrapper = styled.div`
	width: 80%;
	display: flex;
`

const Illustration = styled.img(p => ({
	width: p.forPdf ? '52%' : '50%',
	alignSelf: 'center',
}))

const IllustrationDibiz = styled.img(p => ({
	width: p.forPdf ? '22%' : '30%',
	alignSelf: 'center',
}))

const HeaderContainer = styled.div`
	display: flex;
`
const Header = styled.div`
	font-size: 32px;
	font-weight: 500;
	padding-left: 28px;
	color: ${theme.color.black3};
`

const CompleteTraceReport = ({ fromTraceReportTab = false, downloadPdfFn }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const { payload = {} } = useSelector(TradeDocDuc.selectors.location)
	const traceReportData = useSelector(TradeDocDuc.selectors.getBackwardTrace)
	const { rootModule, documentReference } = payload
	const [recursiveArray, setRecursiveArray] = useState([])
	const [recursiveArrayForPdf, setRecursiveArrayForPdf] = useState([])
	const [pdfStyle, setPdfStyle] = useState(fromTraceReportTab)
	const [updateNormalFlow, setUpdateNormalFlow] = useState(true)
	const componentRef = useRef()
	const hederaMessages = useSelector(TradeDocDuc.selectors.getHederaMessages)
	const activeDocument = useSelector(
		TradeDocDuc.selectors.getDocumentActiveRecord
	)
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)
	const doNumberListForTraceReport = []

	const getRecursiveArray = (secondarySource, indexVal, levelCount) => {
		let currentArray = []
		if (Object.keys(secondarySource).length > 0) {
			Object.keys(secondarySource).forEach(sourceObj => {
				if (
					secondarySource[sourceObj] &&
					secondarySource[sourceObj].sourceTraces.length > 0
				) {
					secondarySource[sourceObj].sourceTraces.forEach(
						(item, index) => {
							const newArray = []
							let nextSource = {}
							if (
								item &&
								item.orgTraceGroupMap &&
								Object.keys(item.orgTraceGroupMap).length > 0
							) {
								nextSource = item.orgTraceGroupMap
							}

							if (doNumberListForTraceReport?.length > 0) {
								doNumberListForTraceReport.push(
									getIn(item, ['entity', 'number'])
								)

								newArray.push({
									visible: pdfStyle,
									id: `${indexVal}-${index}`,
									component: { ...item, keyID: sourceObj },
									levelCount,
									traceID:
										item && item.traceID
											? item.traceID
											: '',
									children:
										nextSource &&
										Object.keys(nextSource).length > 0
											? getRecursiveArray(
													nextSource,
													`${indexVal}-${index}`,
													levelCount + 1
											  )
											: [],
								})
							} else {
								doNumberListForTraceReport.push(
									getIn(item, ['entity', 'number'])
								)
								newArray.push({
									visible: pdfStyle,
									id: `${indexVal}-${index}`,
									component: { ...item, keyID: sourceObj },
									traceID:
										item && item.traceID
											? item.traceID
											: '',
									levelCount,
									children:
										nextSource &&
										Object.keys(nextSource).length > 0
											? getRecursiveArray(
													nextSource,
													`${indexVal}-${index}`,
													levelCount + 1
											  )
											: [],
								})
							}

							currentArray = currentArray.concat(newArray)
						}
					)
				}
			})
		}

		return currentArray
	}

	useEffect(() => {
		if (
			traceReportData &&
			getIn(traceReportData, ['traceDataset', 'orgTraceGroupMap']) &&
			Object.keys(
				getIn(traceReportData, ['traceDataset', 'orgTraceGroupMap'])
			).length > 0
		) {
			Object.keys(
				getIn(traceReportData, ['traceDataset', 'orgTraceGroupMap'])
			).forEach(traceKey => {
				if (
					getIn(traceReportData, [
						'traceDataset',
						'orgTraceGroupMap',
					])[traceKey] &&
					getIn(traceReportData, [
						'traceDataset',
						'orgTraceGroupMap',
					])[traceKey].sourceTraces &&
					getIn(traceReportData, [
						'traceDataset',
						'orgTraceGroupMap',
					])[traceKey].sourceTraces.length > 0
				) {
					const data = getRecursiveArray(
						getIn(traceReportData, [
							'traceDataset',
							'orgTraceGroupMap',
						]),
						'index',
						0
					)
					if (
						data &&
						data.length > 0 &&
						updateNormalFlow &&
						!pdfStyle
					) {
						setRecursiveArray(data)
					} else if (pdfStyle) {
						setRecursiveArrayForPdf(data)
					}
				}
			})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [traceReportData, pdfStyle])

	const getNextDate = data => {
		let date = null
		const createdAtDate =
			getIn(data, ['createdAt']) &&
			getIn(data, ['createdAt']).split('T')[0]
		const createdAtDateFiltered = new Date(createdAtDate)

		const nextDate = createdAtDateFiltered.setDate(
			createdAtDateFiltered.getDate() +
				parseInt(getAutoProductionInterval(), 10)
		)

		if (new Date(nextDate) > new Date().setHours(5, 30, 0, 0)) {
			date = getDateByFormat(nextDate, 'dd MMM yyyy', activeLocale)
		}

		return date
	}

	const getPrimaryTraceReport = (forPdf = false) => {
		return (
			<Box
				style={{
					width: forPdf ? '730px' : '100%',
					paddingBottom: '20px',
				}}
			>
				{forPdf ? (
					<>
						<Box
							style={{
								fontWeight: 500,
								fontSize: '26px',
								paddingBottom: '20px',
							}}
						>
							{`${t(
								'tdmDetailsEntry.traceReport'
							)} - ${getIn(traceReportData, [
								'traceDataset',
								'entity',
								'number',
							]) || ''}`}
						</Box>
					</>
				) : (
					''
				)}
				<Box>
					<div
						style={{
							display: 'flex',
							padding: '0px 12px',
							alignItems: 'center',
						}}
					>
						<div>
							<IllustrationDibiz
								src="/images/DIBIZ-LOGO.png"
								alt="dibizTour"
								forPdf={forPdf}
							/>
						</div>
						<div style={{ display: 'grid', justifyItems: 'end' }}>
							<Illustration
								src="/images/CU-verified.png"
								alt="dibizTour"
								forPdf={forPdf}
							/>
						</div>
					</div>
					{getIn(traceReportData, [
						'blockChainReference',
						'traceDataHashValue',
					]) ? (
						<Box>
							<div
								style={{
									display: 'flex',
									paddingTop: '27px',
								}}
							>
								<Box
									style={{
										width: '53%',
									}}
								>
									<Box
										style={{
											fontWeight: 500,
											fontSize: forPdf ? '12px' : '14px',
											marginBottom: '12px',
											color: theme.color.black3,
										}}
									>
										{t(
											'traceReport.hashValueCombinationOfDeliveryOrderAndTraceReport'
										)}
									</Box>
									<div
										style={{
											display: 'flex',
											height: forPdf ? '75px' : '90px',
											fontSize: forPdf ? '10px' : '13px',
											marginRight: '65px',
											borderRadius: '6px',
											padding: '18px 13px',
											alignItems: 'center',
											color: theme.color.grey15,
											backgroundColor:
												theme.color.themeBlue4,
											border: `1px solid ${theme.color.themeBlue3}`,
										}}
									>
										<Box
											style={{
												width: '89%',
												marginTop: '2px',
												paddingLeft: '5px',
												wordBreak: 'break-all',
											}}
										>
											{getIn(traceReportData, [
												'blockChainReference',
												'traceDataHashValue',
											]) || ''}
										</Box>
										<Box
											style={{
												marginLeft: '10px',
												justifyContent: 'center',
											}}
										>
											<IconWrapper
												color={theme.color.themeGreen4}
												size={20}
											>
												<Icon
													color={
														theme.color.themeGreen4
													}
													glyph={SuccessIcon}
												/>
											</IconWrapper>
										</Box>
									</div>
								</Box>
								<Box
									style={{
										width: '47%',
									}}
								>
									<Box
										style={{
											fontWeight: 500,
											fontSize: forPdf ? '12px' : '14px',
											marginBottom: '12px',
											color: theme.color.black3,
										}}
									>
										{t(
											'traceReport.transactionReferenceFromBlockchain'
										)}
									</Box>
									<div
										style={{
											display: 'flex',
											fontSize: forPdf ? '12px' : '14px',
											height: forPdf ? '75px' : '90px',
											borderRadius: '6px',
											padding: '12px 13px',
											paddingLeft: '35px',
											alignItems: 'center',
											color: theme.color.grey15,
											backgroundColor:
												theme.color.themeBlue4,
											border: `1px solid ${theme.color.themeBlue3}`,
										}}
									>
										<Box style={{ width: '85%' }}>
											<Box
												style={{
													maxWidth: '385px',
													marginBottom: '5px',
													color: theme.color.primary,
													cursor: getIn(
														traceReportData,
														[
															'blockChainReference',
															'explorerUrl',
														]
													)
														? 'pointer'
														: 'default',
												}}
												onClick={() => {
													if (
														getIn(traceReportData, [
															'blockChainReference',
															'explorerUrl',
														])
													) {
														window.open(
															getIn(
																traceReportData,
																[
																	'blockChainReference',
																	'explorerUrl',
																]
															),
															'_blank'
														)
													}
												}}
											>
												<LinkWrap>{`${getIn(
													traceReportData,
													[
														'blockChainReference',
														'topicID',
													]
												)} / ${getIn(traceReportData, [
													'blockChainReference',
													'topicSequenceNumber',
												])}`}</LinkWrap>
											</Box>
											<Box>
												{getDateWithTimeByFormat(
													getIn(traceReportData, [
														'updatedAt',
													])
												) || ''}
											</Box>
										</Box>
										<Box
											style={{
												marginLeft: '20px',
												justifyContent: 'center',
											}}
										>
											<IconWrapper
												color={theme.color.themeGreen4}
												size={20}
											>
												<Icon
													color={
														theme.color.themeGreen4
													}
													glyph={SuccessIcon}
												/>
											</IconWrapper>
										</Box>
									</div>
								</Box>
							</div>
						</Box>
					) : (
						''
					)}
					{getIn(activeDocument, ['docStatus']) === 'submitted' ? (
						<div
							style={{
								display: 'flex',
								fontSize: '14px',
								marginTop: '15px',
								marginBottom: '11px',
								justifyContent: 'end',
								color: theme.color.themeRed3,
							}}
						>
							{t('tdmViewDocument.submittedDOText')}
						</div>
					) : (
						<div
							style={{
								display: 'flex',
								fontSize: forPdf ? '10px' : '14px',
								marginTop: '24px',
								marginBottom: '50px',
								color: theme.color.grey15,
							}}
						>
							<>
								<div
									style={{
										width: forPdf ? '78%' : '73%',
									}}
								>
									<div
										style={{
											display: 'flex',
											marginBottom: '6px',
										}}
									>
										<div
											style={{
												width: '130px',
												color: theme.color.black,
												fontWeight: 400,
											}}
										>
											{t('traceReport.createdOn')}
										</div>
										<div
											style={{
												marginRight: '10px',
												color: theme.color.black,
												fontWeight: 600,
											}}
										>
											:
										</div>
										<div>
											{getDateWithTimeByFormat(
												getIn(traceReportData, [
													'createdAt',
												])
											) || ''}
										</div>
									</div>
									<div
										style={{
											display: 'flex',
										}}
									>
										<div
											style={{
												width: '130px',
												color: theme.color.black,
												fontWeight: 400,
											}}
										>
											{t('traceReport.lastUpdatedOn')}
										</div>
										<div
											style={{
												marginRight: '10px',
												color: theme.color.black,
												fontWeight: 600,
											}}
										>
											:
										</div>
										<div>
											{getDateWithTimeByFormat(
												getIn(traceReportData, [
													'traceUpdatedAt',
												])
											) || ''}
										</div>
									</div>
								</div>
								<div>
									{!getIn(traceReportData, [
										'eudrCompliance',
									]) &&
										getIn(traceReportData, [
											'unknownFlag',
										]) && (
											<div
												style={{
													color: theme.color.info,
												}}
											>
												{t(
													'traceReport.unknownSourceText'
												)}
											</div>
										)}
								</div>
							</>
						</div>
					)}
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<div
							style={{
								padding: '5px 15px',
								backgroundColor: getIn(traceReportData, [
									'eudrCompliance',
								])
									? theme.color.iconGreen
									: theme.color.pureRed,
								color: theme.color.white,
								borderRadius: '7px',
							}}
						>
							{getIn(traceReportData, ['eudrCompliance'])
								? t('traceReport.eudrCompliant')
								: t('traceReport.eudrNotCompliant')}
						</div>
					</div>
					{getNextDate(traceReportData) && !forPdf && (
						<div
							style={{
								padding: '15px',
								margin: '20px 100px',
								borderRadius: '10px',
								backgroundColor: theme.color.themeYellow1,
							}}
						>
							<span style={{ color: theme.color.black4 }}>
								{t('traceReport.traceDataChangeText')}
							</span>
							<span style={{ color: theme.color.primary }}>
								&nbsp;&nbsp;{getNextDate(traceReportData)}.
							</span>
						</div>
					)}
					<Spacer
						size={1}
						horizontal
						style={{
							marginBottom: '50px',
							border: `1px solid ${theme.color.themeBlue3}`,
						}}
					/>
				</Box>
				<TraceReportTable
					forPdf={forPdf}
					blockChainVerification={getVerificationStatus(
						activeDocument,
						hederaMessages,
						getIn(activeDocument, ['id']),
						true
					)}
					hederaMessages={hederaMessages}
					header={
						getHeader(
							activeDocument,
							getIn(traceReportData, ['traceDataset']),
							t
						)?.name
					}
					virtualTraceData={
						getHeader(
							activeDocument,
							getIn(traceReportData, ['traceDataset']),
							t
						)?.virtualTraceData
					}
					receiverOrgId={getIn(activeDocument, ['receivingPartyID'])}
					showVerifiedText
					traceDetails={traceDetails(
						activeDocument,
						getIn(traceReportData, ['traceDataset'])
					)}
					originatingOrganizationDetails={originatingOrganizationDetails(
						getIn(traceReportData, ['traceDataset'])
					)}
					destinationOrganizationDetails={destinationOrganizationDetails(
						getIn(traceReportData, ['traceDataset'])
					)}
					onCertificateclick={certificateData => {
						handleCertificateData(certificateData)
					}}
					submittedState={
						getIn(activeDocument, ['docStatus']) === 'submitted'
					}
					getExplorerLink={(topicID, topicReferenceNumber) =>
						getExplorerLink(topicID, topicReferenceNumber)
					}
				/>
			</Box>
		)
	}

	const getExplorerLink = (topicID, topicReferenceNumber) => {
		dispatch(
			TradeDocDuc.creators.fetchMirrorNodeData(
				topicID,
				topicReferenceNumber
			)
		)
	}

	const getData = currentRecursiveArray => (
		<CollapsibleDocStacks
			documents={currentRecursiveArray || []}
			activeDocumentKey={getIn(traceReportData, [
				'traceDataset',
				'traceID',
			])}
			activeDocument={activeDocument}
			hederaMessages={hederaMessages}
			getExplorerLink={(topicID, topicReferenceNumber) =>
				getExplorerLink(topicID, topicReferenceNumber)
			}
			forTraceReport
		/>
	)

	const getDataForPDF = (currentData, responseData) => {
		const getSecondarytraceReportComp = secondaryTraceParamsList => {
			const { report, currentEntityID, count } = secondaryTraceParamsList

			return (
				<Box
					style={{
						width: '730px',
						marginBottom: '100px',
					}}
				>
					<div style={{ display: 'flex' }}>
						<div style={{ width: '100%' }}>
							<TraceReportTable
								forPdf
								blockChainVerification={getVerificationStatus(
									activeDocument,
									hederaMessages,
									currentEntityID,
									true
								)}
								hederaMessages={hederaMessages}
								headerBackGroundColor={colorArray[count]}
								header={
									getHeader(activeDocument, report, t)?.name
								}
								virtualTraceData={
									getHeader(activeDocument, report, t)
										?.virtualTraceData
								}
								receiverOrgId={getIn(report, [
									'destinationOrg',
									'id',
								])}
								showVerifiedText
								traceDetails={traceDetails(
									activeDocument,
									report
								)}
								originatingOrganizationDetails={originatingOrganizationDetails(
									report
								)}
								destinationOrganizationDetails={destinationOrganizationDetails(
									report
								)}
								onCertificateclick={certificateData => {
									handleCertificateData(certificateData)
								}}
								getExplorerLink={(
									topicID,
									topicReferenceNumber
								) =>
									getExplorerLink(
										topicID,
										topicReferenceNumber
									)
								}
							/>
						</div>
					</div>
				</Box>
			)
		}

		if (currentData && currentData.length > 0) {
			currentData.forEach(item => {
				if (item.visible) {
					const secondaryTraceParamsList = {
						report: item.component,
						id: item.id,
						children: item.children,
						currentEntityID: item.component?.entity?.id || '',
						childCount: currentData.length,
						itemIndex: currentData.indexOf(item),
					}

					responseData.push(
						<div
							style={{
								width: '100%',
								pageBreakAfter: 'auto',
								pageBreakBefore: 'auto',
								pageBreakInside: 'avoid',
								breakInside: 'avoid-page',
							}}
						>
							<div
								style={{
									width: '100%',
									display: 'flex',
								}}
							>
								{getSecondarytraceReportComp(
									secondaryTraceParamsList
								)}
							</div>
						</div>
					)
					if (item.children && item.children.length > 0) {
						// eslint-disable-next-line no-param-reassign
						responseData = getDataForPDF(
							item.children,
							responseData
						)
					}
				}
			})
		}

		return responseData
	}

	const handlePrintToPDF = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: `${t(
			'tdmDetailsEntry.traceReport'
		)} - ${getIn(traceReportData, ['traceDataset', 'entity', 'number']) ||
			''}`,
		onBeforeGetContent: () => setPdfStyle(true),
		onAfterPrint: () => {
			setPdfStyle(false)
			if (fromTraceReportTab) {
				downloadPdfFn(false)
			}
		},
		// maximum canvas height for chrome and firefox is 32767px. so the pdf will break after 29 page for scale: 2.
		// now reduced to scale 1.5 to print more pages. but the clarity reduces.
		print: async printIframe => {
			const document = printIframe.contentDocument
			if (document) {
				const html = document.getElementsByTagName('html')[0]
				setTimeout(() => {
					Html2Pdf(html, {
						filename: `${t(
							'tdmDetailsEntry.traceReport'
						)} - ${getIn(traceReportData, [
							'traceDataset',
							'entity',
							'number',
						]) || ''}.pdf`,
						image: { type: 'svg', quality: 0.98 },
						html2canvas: {
							scale: 1.5,
							logging: true,
							dpi: 192,
							letterRendering: true,
						},
						jsPDF: {
							unit: 'px',
							format: 'a4',
							orientation: 'portrait',
							hotfixes: ['px_scaling'],
							compress: true,
						},
					})
				}, 50)
			}
		},
	})

	useEffect(() => {
		if (fromTraceReportTab) {
			setTimeout(() => {
				// eslint-disable-next-line no-use-before-define
				handlePrintToPDF()
			}, 50)
		}
	}, [fromTraceReportTab, handlePrintToPDF])

	return (
		<Box padding={20} width="100%" height="100%">
			<HeaderContainer>
				<HeaderWrapper>
					<IconWrapper
						size={40}
						style={{ color: theme.color.blue5, cursor: 'pointer' }}
						onClick={() => {
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types
										.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
									{
										rootModule,
										documentReference,
									}
								)
							)
						}}
					>
						<Icon glyph={backArrow} />
					</IconWrapper>
					<Header>{t('tdmDetailsEntry.traceReport')}</Header>
				</HeaderWrapper>
				<Box
					style={{
						padding: '2px',
					}}
				>
					<Button
						label={
							<div
								style={{
									marginLeft: '7px',
								}}
							>
								{t('dealerGeneralReports.downloadRpt')}
							</div>
						}
						rounded
						extendStyles={{
							minWidth: '220px',
							borderRadius: '24px',
							paddingRight: '12px',
							color: theme.color.blue8,
							backgroundColor: theme.color.themeBlue6,
						}}
						customIcon={
							<ButtonIconWrapper>
								<IconWrapper
									size={20}
									style={{
										marginTop: '5px',
									}}
								>
									<Icon glyph={DownloadIcon} />
								</IconWrapper>
							</ButtonIconWrapper>
						}
						isLoading={pdfStyle}
						disabled={!(traceReportData && traceReportData.id)}
						onClick={() => {
							setUpdateNormalFlow(false)
							setPdfStyle(true)
							setTimeout(() => {
								handlePrintToPDF()
							}, 50)
						}}
					/>
				</Box>
			</HeaderContainer>
			<Box padding="25px 0">
				{traceReportData && traceReportData.id ? (
					<Box
						style={{
							padding: '40px 35px',
							backgroundColor: theme.color.white,
						}}
					>
						<Box>{getPrimaryTraceReport()}</Box>
						<Box
							style={{
								overflow: 'auto',
								paddingBottom: '25px',
							}}
						>
							{getData(recursiveArray)}
						</Box>
					</Box>
				) : (
					<Box
						style={{
							height: '500px',
							fontWeight: 500,
							fontSize: '20px',
							alignItems: 'center',
							justifyContent: 'center',
							backgroundColor: theme.color.white,
						}}
					>
						{t('traceReport.noDataAvailable')}
					</Box>
				)}
				{pdfStyle ? (
					<Box
						style={{
							display: 'none',
						}}
					>
						<Box
							style={{
								padding: '40px 35px',
								backgroundColor: theme.color.white,
							}}
							ref={componentRef}
						>
							<Box
								style={{
									pageBreakAfter: 'always',
								}}
							>
								{getPrimaryTraceReport(true)}
							</Box>
							<Box
								style={{
									paddingBottom: '25px',
								}}
							>
								{getDataForPDF(recursiveArrayForPdf, [])}
							</Box>
						</Box>
					</Box>
				) : (
					''
				)}
			</Box>
		</Box>
	)
}

export { CompleteTraceReport }
