import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import Documents from 'ui-lib/icons/documents.svg'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon } from 'ui-lib/icons/components/Icon'
import theme from 'ui-lib/utils/base-theme'
import { currentPlatform } from 'ui-lib/utils/config'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import {
	getOrganizationType,
	getProductTotal,
	getOrganizationTypeForRice,
} from 'ui-tdm-app/utils/helpers'
import { ShippingStaticSection } from '../containers/DocumentControl/Shared/ShippingSection'
import { AdditionalInfoStaticBlock } from '../containers/DocumentControl/Shared/AdditionalInformation'
import { BankDetailStaticBlock } from '../containers/DocumentControl/Shared/BankDetails'

const DocumentView = ({
	partner = {},
	brokerEmailID = '',
	receiverEmailID = '',
	associatedPORef = '',
	associatedDORef = '',
	associatedInvoiceRef = '',
	productDetails = [],
	tankDetails = [],
	user = '',
	role = '',
	issueDate = '',
	attachedFiles = {},
	status = '',
	documentNumber = '',
	activeDocument = {},
	isMobile = false,
	rootModule,
	supplyChainModel = '',
	actor,
	hasInitiatedByCurrentOrg = false,
	preview = false,
	toBeShippedTo = {},
}) => {
	const { t } = useTranslation()
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)
	const displayedRole = !hasInitiatedByCurrentOrg
		? t('tdmDetailsEntry.sender')
		: t('tdmDetailsEntry.recipient')
	const enableGlobalPartner = getIn(activeDocument, [
		'meta',
		'enableGlobalPartner',
	])
	const globalPartnerDetails = getIn(activeDocument, [
		'meta',
		'incomingVirtualDO',
	])
		? getIn(activeDocument, ['meta', 'globalInitiator'])
		: getIn(activeDocument, ['meta', 'globalReceiver'])
	const isIncomingDO = getIn(activeDocument, ['meta', 'incomingVirtualDO'])

	const getAttachedDocuments = () => {
		const docArray = []

		if (attachedFiles?.id) {
			docArray.push(
				<div style={{ display: 'flex', cursor: 'pointer' }}>
					<div
						style={{
							width: 60,
							height: 60,
							marginRight: 23,
							cursor: 'pointer',
							alignItems: 'center',
							display: 'inline-flex',
							justifyContent: 'center',
							backgroundColor: theme.color.themeGrey2,
						}}
						title={getIn(attachedFiles, ['meta', 'name']) || ''}
						aria-hidden="true"
						onClick={() =>
							window.open(
								getIn(attachedFiles, ['meta', 'fullURL'])
							)
						}
					>
						<Icon
							style={{
								height: 46,
								width: 33,
							}}
							glyph={Documents}
							color={theme.color.blue8}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							color: theme.color.themeRed3,
						}}
						aria-hidden="true"
						onClick={() =>
							window.open(
								getIn(attachedFiles, ['meta', 'fullURL'])
							)
						}
					>
						{getIn(attachedFiles, ['meta', 'name']) || ''}
					</div>
				</div>
			)
		}

		return docArray
	}

	const getTankdetails = () => {
		const tankArray = []
		if (tankDetails && tankDetails.length > 0) {
			tankDetails.forEach((item, index) => {
				tankArray.push(
					<div
						id="tankDetails"
						style={{
							padding: 20,
							borderRadius: 10,
							marginBottom:
								index !== tankDetails.length - 1 ? '24px' : '',
							border: `1px solid ${theme.color.grey4}`,
						}}
					>
						<div
							style={{
								fontSize: 14,
								fontWeight: 500,
								marginBottom: 5,
								color: theme.color.themeGrey1,
							}}
						>
							{`${t('products.tankName')} ${t(
								'createAsset.name'
							)}`}
						</div>
						<div
							style={{
								fontSize: 16,
								fontWeight: 500,
								marginBottom: 12,
								color: theme.color.accent2,
							}}
						>
							{getIn(item, ['tank', 'name'])}
						</div>
						<Spacer size={10} />
						<div
							style={{
								fontSize: 14,
								fontWeight: 500,
								marginBottom: 5,
								color: theme.color.themeGrey1,
							}}
						>
							(in MT)
						</div>
						<div
							style={{
								fontSize: 16,
								fontWeight: 500,
								color: theme.color.accent2,
							}}
						>
							{getIn(item, ['tankQuantity'])}
						</div>
					</div>
				)
			})
		}

		return tankArray
	}

	const getProductDetails = () => {
		const productArray = []
		if (productDetails && productDetails.length > 0) {
			productDetails.forEach((item, index) => {
				productArray.push(
					<div
						id="productDetails"
						style={{
							padding: 20,
							borderRadius: 10,
							border: `1px solid ${theme.color.grey4}`,
							marginBottom:
								index !== productDetails.length - 1
									? '24px'
									: '',
						}}
					>
						<div
							style={{
								fontSize: 14,
								fontWeight: 500,
								marginBottom: 5,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tdmDetailsEntry.productName')}
						</div>
						<div
							style={{
								fontSize: 16,
								fontWeight: 500,
								marginBottom: 12,
								color: theme.color.accent2,
							}}
						>
							{getIn(item, ['name'])}
						</div>
						<div
							style={{
								fontSize: 14,
								fontWeight: 500,
								marginBottom: 5,
								color: theme.color.themeGrey1,
							}}
						>
							{`${t('tdmViewDocument.quantity')} ${
								(actor.includes('palmoil_plantation') &&
									rootModule === 'delivery-order' &&
									(preview || hasInitiatedByCurrentOrg)) ||
								((actor.includes('palmoil_ffbdealer') ||
									actor.includes('palmoil_ffbsubdealer')) &&
									rootModule !== 'purchase-order' &&
									!hasInitiatedByCurrentOrg) ||
								(actor.includes('palmoil_mill') &&
									rootModule !== 'purchase-order' &&
									!hasInitiatedByCurrentOrg &&
									getIn(activeDocument, [
										'meta',
										'uomNonMetric',
									]))
									? `(${getIn(activeDocument, [
											'meta',
											'uomNonMetric',
									  ]) || 'in Nos'})`
									: `(${getIn(activeDocument, [
											'meta',
											'uomNonMetric',
									  ]) || 'in MT'})`
							}`}
						</div>
						<div
							style={{
								fontSize: 16,
								fontWeight: 500,
								marginBottom:
									getIn(item, ['metricUnitPrice']) !==
										undefined ||
									getIn(item, ['tax', 0, 'value']) !==
										undefined
										? 12
										: 5,
								color: theme.color.accent2,
							}}
						>
							<span>
								{getIn(activeDocument, [
									'meta',
									'quantityNonMetric',
								]) || getIn(item, ['quantity'])}
							</span>
							<span
								style={{
									fontSize: 13,
									color: theme.color.grey7,
								}}
							>{` (LCA:${parseFloat(getIn(item, ['lcv'])).toFixed(
								3
							)})`}</span>
						</div>
						{getIn(item, ['metricUnitPrice']) !== undefined &&
						rootModule !== 'delivery-order' ? (
							<>
								<div
									style={{
										fontSize: 14,
										fontWeight: 500,
										marginBottom: 5,
										color: theme.color.themeGrey1,
									}}
								>
									{t('tdmDetailsEntry.unitPrice')}
								</div>
								<div
									style={{
										fontSize: 16,
										fontWeight: 500,
										marginBottom:
											getIn(item, ['tax', 0, 'value']) !==
											undefined
												? 12
												: 5,
										color: theme.color.accent2,
									}}
								>
									{getIn(item, ['metricUnitPrice']) || ''}
								</div>
							</>
						) : (
							''
						)}

						{getIn(item, ['tax', 0, 'value']) !== undefined &&
						rootModule !== 'delivery-order' ? (
							<>
								<div
									style={{
										fontSize: 14,
										fontWeight: 500,
										marginBottom: 5,
										color: theme.color.themeGrey1,
									}}
								>
									{t('tdmDetailsEntry.taxPrice')}
								</div>
								<div
									style={{
										fontSize: 16,
										fontWeight: 500,
										marginBottom: 5,
										color: theme.color.accent2,
									}}
								>
									{getIn(item, ['tax', 0, 'value']) || ''}
								</div>
							</>
						) : (
							''
						)}

						{rootModule !== 'delivery-order' && (
							<>
								<div
									style={{
										fontSize: 14,
										fontWeight: 500,
										marginBottom: 5,
										color: theme.color.themeGrey1,
									}}
								>
									{t('tdmColumnHeaders.totalPrice')}
								</div>
								<div
									style={{
										fontSize: 16,
										fontWeight: 500,
										marginBottom: 5,
										color: theme.color.accent2,
									}}
								>
									{getProductTotal(item)}
								</div>
							</>
						)}
					</div>
				)
			})
		}

		return productArray
	}

	return (
		<Box>
			<div
				style={{
					padding: '35px',
					display: isMobile ? 'block' : 'flex',
					borderRadius: 5,
					background: theme.color.white,
					justifyContent: 'space-between',
					border: `1px solid ${theme.color.grey4}`,
				}}
			>
				<div id="left" style={{ width: isMobile ? '100%' : '45%' }}>
					<div
						style={{
							marginBottom: 8,
							fontWeight: 500,
							color: theme.color.themeGrey1,
						}}
					>
						{displayedRole}
						{isIncomingDO
							? ` (${t(
									'tdmColumnHeaders.incoming-delivery-order'
							  )})`
							: ''}
					</div>
					<div
						id="recipient"
						style={{
							marginBottom: 24,
							borderRadius: 10,
							border: `1px solid ${theme.color.grey4}`,
						}}
					>
						<div
							style={{
								padding: 20,
							}}
						>
							{getIn(partner, ['id']) ? (
								<>
									<div
										style={{
											fontSize: 14,
											fontWeight: 500,
											marginBottom: 5,
											color: theme.color.themeGrey1,
										}}
									>
										{displayedRole}
									</div>
									<div
										style={{
											fontSize: 16,
											fontWeight: 600,
											marginBottom: 8,
											color: theme.color.accent2,
										}}
									>
										{getIn(partner, ['name']) || ''}
									</div>
									<div
										style={{
											fontSize: 16,
											fontWeight: 400,
											marginBottom: 12,
											color: theme.color.accent2,
										}}
									>
										<div>
											{getIn(partner, [
												'primaryAddress',
												'line1',
											]) || ''}
											{getIn(partner, [
												'primaryAddress',
												'line1',
											])
												? ', '
												: ''}
											{getIn(partner, [
												'primaryAddress',
												'line2',
											]) || ''}
										</div>
										<div>
											{getIn(partner, [
												'primaryAddress',
												'city',
											]) || ''}
											{getIn(partner, [
												'primaryAddress',
												'city',
											])
												? ', '
												: ''}
											{getIn(partner, [
												'primaryAddress',
												'state',
											]) || ''}
										</div>
										<div>
											{getIn(partner, [
												'primaryAddress',
												'country',
											]) || ''}
											{getIn(partner, [
												'primaryAddress',
												'country',
											])
												? ', '
												: ''}
											{getIn(partner, [
												'primaryAddress',
												'postalCode',
											]) || ''}
										</div>
									</div>
									<Spacer size={10} />{' '}
								</>
							) : (
								''
							)}
							{enableGlobalPartner && (
								<>
									{getIn(globalPartnerDetails, ['name']) && (
										<>
											<div
												style={{
													fontSize: 14,
													fontWeight: 500,
													marginBottom: 5,
													color:
														theme.color.themeGrey1,
												}}
											>
												{t('invitePartner.orgName')}
											</div>
											<div
												style={{
													fontSize: 16,
													fontWeight: 400,
													marginBottom: 12,
													color: theme.color.accent2,
												}}
											>
												{getIn(globalPartnerDetails, [
													'name',
												])}
											</div>
											<Spacer size={10} />
										</>
									)}
									{getIn(globalPartnerDetails, [
										'categories',
										0,
										'name',
									]) && (
										<>
											<div
												style={{
													fontSize: 14,
													fontWeight: 500,
													marginBottom: 5,
													color:
														theme.color.themeGrey1,
												}}
											>
												{t('invitePartner.orgType')}
											</div>
											<div
												style={{
													fontSize: 16,
													fontWeight: 400,
													marginBottom: 12,
													color: theme.color.accent2,
												}}
											>
												{currentPlatform() === 'rice'
													? getOrganizationTypeForRice(
															t,
															getIn(
																globalPartnerDetails,
																[
																	'categories',
																	0,
																	'name',
																]
															)
													  )
													: getOrganizationType(
															t,
															getIn(
																globalPartnerDetails,
																[
																	'categories',
																	0,
																	'name',
																]
															)
													  )}
											</div>
											<Spacer size={10} />
										</>
									)}
									{getIn(globalPartnerDetails, [
										'address',
										'line1',
									]) && (
										<>
											<div
												style={{
													fontSize: 14,
													fontWeight: 500,
													marginBottom: 5,
													color:
														theme.color.themeGrey1,
												}}
											>
												{t('invitePartner.location')}
											</div>
											<div
												style={{
													fontSize: 16,
													fontWeight: 400,
													marginBottom: 12,
													color: theme.color.accent2,
												}}
											>
												{getIn(globalPartnerDetails, [
													'address',
													'line1',
												])}
											</div>
											<Spacer size={10} />
										</>
									)}
									{getIn(globalPartnerDetails, [
										'contact',
										'mobile',
									]) && (
										<>
											<div
												style={{
													fontSize: 14,
													fontWeight: 500,
													marginBottom: 5,
													color:
														theme.color.themeGrey1,
												}}
											>
												{t('empMgmtHome.phoneNo')}
											</div>
											<div
												style={{
													fontSize: 16,
													fontWeight: 400,
													marginBottom: 12,
													color: theme.color.accent2,
												}}
											>
												{getIn(globalPartnerDetails, [
													'contact',
													'mobile',
												])}
											</div>
											<Spacer size={10} />
										</>
									)}
								</>
							)}
							{receiverEmailID !== '' ? (
								<>
									<div
										style={{
											fontSize: 14,
											fontWeight: 500,
											marginBottom: 5,
											color: theme.color.themeGrey1,
										}}
									>
										{t('tdmDetailsEntry.emailAdded')}
									</div>
									<div
										style={{
											fontSize: 16,
											fontWeight: 400,
											marginBottom: 12,
											color: theme.color.accent2,
										}}
									>
										{receiverEmailID}
									</div>
									<Spacer size={10} />
								</>
							) : (
								''
							)}
							{brokerEmailID !== '' ? (
								<>
									<div
										style={{
											fontSize: 14,
											fontWeight: 500,
											marginBottom: 5,
											color: theme.color.themeGrey1,
										}}
									>
										{t('tdmDetailsEntry.brokerAdded')}
									</div>
									<div
										style={{
											fontSize: 16,
											fontWeight: 400,
											color: theme.color.accent2,
										}}
									>
										{brokerEmailID}
									</div>
								</>
							) : (
								''
							)}
						</div>
						{associatedPORef !== '' ||
						associatedDORef !== '' ||
						associatedInvoiceRef !== '' ? (
							<div
								style={{
									borderTop: `1px solid ${theme.color.grey4}`,
									padding: 20,
								}}
							>
								{associatedPORef !== '' &&
								rootModule !== 'purchase-order' ? (
									<>
										<div
											style={{
												fontSize: 14,
												fontWeight: 500,
												marginBottom: 5,
												color: theme.color.themeGrey1,
											}}
										>
											{t(
												'tdmDetailsEntry.associatedPurchaseOrderReference'
											)}
										</div>
										<div
											style={{
												fontSize: 16,
												fontWeight: 400,
												marginBottom: 12,
												color: theme.color.accent2,
											}}
										>
											{associatedPORef}
										</div>
										<Spacer size={10} />
									</>
								) : (
									''
								)}
								{associatedDORef !== '' &&
								rootModule === 'invoice' ? (
									<>
										<div
											style={{
												fontSize: 14,
												fontWeight: 500,
												marginBottom: 5,
												color: theme.color.themeGrey1,
											}}
										>
											{t(
												'tdmDetailsEntry.associatedDeliveryOrderReference'
											)}
										</div>
										<div
											style={{
												fontSize: 16,
												fontWeight: 400,
												color: theme.color.accent2,
											}}
										>
											{associatedDORef}
										</div>
									</>
								) : (
									''
								)}
								{associatedInvoiceRef !== '' &&
								(rootModule === 'delivery-order' ||
									rootModule ===
										'incoming-delivery-order') ? (
									<>
										<div
											style={{
												fontSize: 14,
												fontWeight: 500,
												marginBottom: 5,
												color: theme.color.themeGrey1,
											}}
										>
											{`${t(
												'tdmDetailsEntry.associated'
											)} ${t(
												'tdmColumnHeaders.invoice'
											)} ${t(
												'tdmDetailsEntry.reference'
											)}`}
										</div>
										<div
											style={{
												fontSize: 16,
												fontWeight: 400,
												color: theme.color.accent2,
											}}
										>
											{associatedInvoiceRef}
										</div>
									</>
								) : (
									''
								)}
							</div>
						) : (
							''
						)}
					</div>
					{supplyChainModel && (
						<div>
							<div
								style={{
									marginBottom: 8,
									fontWeight: 500,
									color: theme.color.themeGrey1,
								}}
							>
								{t('tdmDetailsEntry.supplyChainModelFull')}
							</div>
							<div
								id="supplyChainModel"
								style={{
									padding: 20,
									borderRadius: 10,
									marginBottom: 24,
									border: `1px solid ${theme.color.grey4}`,
								}}
							>
								<div
									style={{
										fontSize: 14,
										fontWeight: 500,
										color: theme.color.accent2,
									}}
								>
									{supplyChainModel || ''}
								</div>
							</div>
						</div>
					)}
					{getIn(activeDocument, ['meta', 'shipping']) ||
					getIn(activeDocument, ['meta', 'tripDetails']) ||
					getIn(activeDocument, ['meta', 'productFor']) ? (
						<>
							<div
								style={{
									marginBottom: 8,
									fontWeight: 500,
									color: theme.color.themeGrey1,
								}}
							>
								{t('tdmDetailsEntry.shippingAndDelivery')}
							</div>
							<div
								id="shippingAndDelivery"
								style={{
									marginBottom: 24,
									borderRadius: 10,
									border: `1px solid ${theme.color.grey4}`,
								}}
							>
								<div
									style={{
										padding: 20,
									}}
								>
									<div
										style={{
											fontSize: 16,
											fontWeight: 400,
											marginBottom: 12,
											color: theme.color.accent2,
										}}
									>
										<Box>
											<ShippingStaticSection
												newDocView
												isMobile={isMobile}
												activeRecord={
													getIn(activeDocument, [
														'meta',
														'shipping',
														'address',
													]) || {}
												}
												shipThrough={getIn(
													activeDocument,
													[
														'meta',
														'shipping',
														'shipThrough',
													]
												)}
												shipperNumber={getIn(
													activeDocument,
													[
														'meta',
														'shipping',
														'shipperNumber',
													]
												)}
												billOfLading={getIn(
													activeDocument,
													[
														'meta',
														'shipping',
														'billOfLading',
													]
												)}
												modeOfTransport={getIn(
													activeDocument,
													[
														'meta',
														'shipping',
														'modeOfTransport',
														'name',
													]
												)}
												transporter={
													getIn(activeDocument, [
														'meta',
														'transporter',
													]) || {}
												}
												tripDetails={
													getIn(activeDocument, [
														'meta',
														'tripDetails',
													]) || {}
												}
												preview={preview}
												toBeShippedTo={toBeShippedTo}
											/>
										</Box>
									</div>
								</div>
							</div>
						</>
					) : (
						''
					)}
					<div>
						<div
							style={{
								marginBottom: 8,
								fontWeight: 500,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tdmDetailsEntry.productDetails')}
						</div>
						{getProductDetails()}
					</div>
				</div>
				<div id="right" style={{ width: isMobile ? '100%' : '45%' }}>
					<div
						style={{
							fontWeight: 500,
							marginBottom: 8,
							marginTop: isMobile ? 24 : 0,
							color: theme.color.themeGrey1,
						}}
					>
						{`${t('common.document')} ${t(
							'tdmViewDocument.number'
						)}`}
					</div>
					<div
						id="status"
						style={{
							padding: 20,
							borderRadius: 10,
							marginBottom: 24,
							border: `1px solid ${theme.color.grey4}`,
						}}
					>
						<div
							style={{
								fontSize: 14,
								fontWeight: 500,
								color: theme.color.accent2,
							}}
						>
							{documentNumber || ''}
						</div>
					</div>
					<div
						style={{
							marginBottom: 8,
							fontWeight: 500,
							color: theme.color.themeGrey1,
						}}
					>
						{t('tdmDetailsEntry.documentStatus')}
					</div>
					<div
						id="status"
						style={{
							padding: 20,
							borderRadius: 10,
							marginBottom: 24,
							border: `1px solid ${theme.color.grey4}`,
						}}
					>
						<div
							style={{
								fontSize: 18,
								fontWeight: 500,
								marginBottom: 5,
								color: theme.color.themeRed3,
							}}
						>
							{status}
						</div>
						<div
							style={{
								fontSize: 14,
								fontWeight: 500,
								color: theme.color.themeGrey1,
							}}
						>
							{getDateByFormat(
								issueDate,
								'dd MMM yyyy',
								activeLocale
							)}
						</div>
					</div>
					{attachedFiles?.id ? (
						<>
							<div
								style={{
									marginBottom: 8,
									fontWeight: 500,
									color: theme.color.themeGrey1,
								}}
							>
								{t('tdmDetailsEntry.documentAttached')}
							</div>
							<div
								id="status"
								style={{
									padding: 20,
									display: 'flex',
									borderRadius: 10,
									marginBottom: 24,
									border: `1px solid ${theme.color.grey4}`,
								}}
							>
								{getAttachedDocuments()}
							</div>
						</>
					) : (
						''
					)}
					{!getIn(activeDocument, ['meta', 'attachmentFlow']) &&
					getIn(activeDocument, ['bankDetails']) &&
					Object.keys(activeDocument.bankDetails).length > 0 ? (
						<>
							<div
								style={{
									marginBottom: 8,
									fontWeight: 500,
									color: theme.color.themeGrey1,
								}}
							>
								{t('tdmPreviewDetails.plantationDetails')}
							</div>
							<div
								id="bankDetails"
								style={{
									padding: 20,
									marginBottom: 24,
									borderRadius: 10,
									border: `1px solid ${theme.color.grey4}`,
								}}
							>
								<BankDetailStaticBlock
									newDocView
									isMobile={isMobile}
									values={activeDocument.bankDetails}
								/>
							</div>
						</>
					) : (
						''
					)}
					{!getIn(activeDocument, ['meta', 'attachmentFlow']) &&
					getIn(activeDocument, ['additionalInfo']) ? (
						<>
							<div
								style={{
									marginBottom: 8,
									fontWeight: 500,
									color: theme.color.themeGrey1,
								}}
							>
								{t('tdmPreviewDetails.additionalInformation')}
							</div>
							<div
								id="additionalDelivery"
								style={{
									padding: 20,
									marginBottom: 24,
									borderRadius: 10,
									border: `1px solid ${theme.color.grey4}`,
								}}
							>
								<AdditionalInfoStaticBlock
									newDocView
									values={getIn(activeDocument, [
										'additionalInfo',
									])}
								/>
							</div>
						</>
					) : (
						''
					)}

					<div
						style={{
							marginBottom: 8,
							fontWeight: 500,
							color: theme.color.themeGrey1,
						}}
					>
						{t('tdmDetailsEntry.createdBy')}
					</div>
					<div
						id="createdBy"
						style={{
							padding: 20,
							marginBottom: 24,
							borderRadius: 10,
							border: `1px solid ${theme.color.grey4}`,
						}}
					>
						<div
							style={{
								fontSize: 14,
								fontWeight: 500,
								marginBottom: 5,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tdmDetailsEntry.user')}
						</div>
						<div
							style={{
								fontSize: 16,
								fontWeight: 500,
								marginBottom: 12,
								color: theme.color.accent2,
							}}
						>
							{user}
						</div>
						<Spacer size={10} />
						<div
							style={{
								fontSize: 14,
								fontWeight: 500,
								marginBottom: 5,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tdmDetailsEntry.role')}
						</div>
						<div
							style={{
								fontSize: 16,
								fontWeight: 500,
								marginBottom: 12,
								color: theme.color.accent2,
							}}
						>
							{role}
						</div>
						<Spacer size={10} />
						<div
							style={{
								fontSize: 14,
								fontWeight: 500,
								marginBottom: 5,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tripsListing.date')}
						</div>
						<div
							style={{
								fontSize: 16,
								fontWeight: 500,
								color: theme.color.accent2,
							}}
						>
							{getDateByFormat(
								issueDate,
								'dd MMM yyyy',
								activeLocale
							)}
						</div>
					</div>
					{tankDetails && tankDetails.length > 0 ? (
						<>
							<div
								style={{
									marginBottom: 8,
									fontWeight: 500,
									color: theme.color.themeGrey1,
								}}
							>
								{`${t('products.tankName')} ${t(
									'breadcrumb.details'
								)}`}
							</div>
							{getTankdetails()}
						</>
					) : (
						''
					)}
				</div>
			</div>
		</Box>
	)
}

DocumentView.defaultProps = {
	editable: false,
	onChange: () => {},
}

export { DocumentView }
