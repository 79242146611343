import React, { useMemo, useState, useRef, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Drop } from 'grommet'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { currentPlatform } from 'ui-lib/utils/config'
import { isIframeForTeamsApp } from 'ui-lib/utils/helpers'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { FileUpload } from 'ui-lib/components/FileUpload'
import { Modal } from 'ui-lib/components/Modal'
import { H4, P } from 'ui-lib/components/Typography'
import { Input } from 'ui-lib/components/Input'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { WebTourDuc } from 'ui-tdm-app/modules/WebTour/duc'
import { MessagesDuc } from 'ui-tdm-app/modules/Messages/duc'
import { AdminDuc } from 'ui-tdm-app/modules/Admin/duc'
import { CookieDuc } from 'ui-tdm-app/modules/App/cookieDuc'
import { WebTour } from 'ui-tdm-app/modules/WebTour'
import { getAutoProductionInterval } from 'ui-tdm-app/config'
import {
	getDateByFormat,
	getDateWithTimeByFormat,
	getDayMonthYearFormat,
} from 'ui-tdm-app/utils/date'
import { ChatModalComponent } from 'ui-tdm-app/modules/Messages/components/ChatModalComponent'
import backArrow from 'ui-lib/icons/left-arrow.svg'
import SuccessIcon from 'ui-lib/icons/success-round.svg'
import verifiedIcon from 'ui-lib/icons/verified.svg'
import NotVerifiedIcon from 'ui-lib/icons/not_verified.svg'
import infoIcon from 'ui-lib/icons/info.svg'
import DownloadIcon from 'ui-lib/icons/file_download_black_24dp.svg'
import PlusIcon from 'ui-lib/icons/plus.svg'
import ChatIcon from 'ui-lib/icons/chat_icon.svg'
import {
	Button,
	ButtonWithDrop,
	ButtonWithNoBorder,
	ButtonIconWrapper,
} from 'ui-lib/components/Button'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import EditIcon from 'ui-lib/icons/edit.svg'
import DOIcon from 'ui-lib/icons/delivery-order.svg'
import InvoiceIcon from 'ui-lib/icons/invoice.svg'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'ui-lib/utils/Box'
import { getIn } from 'timm'
import { TabButtons } from 'ui-lib/components/TabButtons'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useTranslation } from 'react-i18next'
import {
	DocumentLeadCard,
	FFBQualityReportCard,
	WeighBridgeTicketCard,
	CPOQualityReportCard,
} from 'ui-tdm-app/modules/TradeDocumentManager/components/DocumentLead'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { scrollIntoView } from 'ui-lib/utils/scroll-helper'
import { Timeline } from 'ui-lib/components/Timeline/index'
import theme from 'ui-lib/utils/base-theme'
import {
	getVerificationStatus,
	getHeader,
	traceDetails,
	originatingOrganizationDetails,
	destinationOrganizationDetails,
	handleCertificateData,
} from '../../../components/TraceReportComponents'
import { Note } from '../../../components/Title'
import { CollapsibleDocStacks } from '../../../components/CollapsibleDocStacks'
import { RemarksConfirmation } from '../../../components/RemarksConfirmation'
import { MessagesBlock } from '../Shared/MessagesBlock'
import { NAME_ALIASES } from '../../../config'
import { DocumentView } from '../../../components/DocumentView'
import { CompleteTraceReport } from './CompleteTraceReport'
import { TraceReportTable } from '../../../components/TraceReportTable'
import { AttachmentMenu } from './AttachmentMenu'

const HeaderWrapper = styled.div`
	display: flex;
	margin-top: 24px;
	margin-bottom: 24px;
`

const LinkWrap = styled.div`
	&:hover {
		text-decoration: underline;
	}
`

const Illustration = styled.img`
	width: 15%;
	align-self: center;
`
const IllustrationDibiz = styled.img`
	width: 20%;
	align-self: center;
`
const Header = styled.div`
	font-size: 30px;
	font-weight: 500;
	margin-top: -6px;
	padding-left: 28px;
	color: ${theme.color.accent2};
`
const SubText = styled.div`
	padding-top: 3px;
	padding-left: 10px
	color: ${theme.color.blue8};
`

const SubWrapper = styled.div`
	display: flex;
	cursor: pointer;
	color: ${theme.color.blue8};
`

const ViewDocument = () => {
	const dispatch = useDispatch()
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	const purchaseOrderRef = useRef()
	const invoiceRef = useRef()

	const { t } = useTranslation()
	const locationState = useSelector(TradeDocDuc.selectors.location)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { fullName } = useSelector(AuthDuc.selectors.getUserProfile)
	const activeDocument = useSelector(
		TradeDocDuc.selectors.getDocumentActiveRecord
	)
	const { isLoading } = useSelector(
		TradeDocDuc.selectors.getDocumentLoadingState
	)
	const getCurrentOrgID = useSelector(AuthDuc.selectors.getUserOrgId)
	const currentOrgdetails = useSelector(
		AuthDuc.selectors.getCurrentOrgDetails
	)
	const uploadDODetails = useSelector(AdminDuc.selectors.getUploadDOdetails)
	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []
	const isMill = actor.includes('palmoil_mill') || actor.includes('rice-mill')
	const isRefinery = actor.includes('palmoil_refinery')
	const isFFBDealer =
		actor.includes('palmoil_ffbdealer') || actor.includes('rice-dealer')
	const isSubDealer = actor.includes('palmoil_ffbsubdealer')
	const isTraderOrEndManufacturer =
		actor.includes('palmoil_trader') ||
		actor.includes('palmoil_manufacturer') ||
		actor.includes('rice-trader')

	const actorWithoutTraceReport =
		actor.includes('palmoil_ffbdealer') ||
		actor.includes('palmoil_ffbsubdealer') ||
		actor.includes('palmoil_plantation') ||
		actor.includes('rice-dealer')

	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)
	const hederaMessages = useSelector(TradeDocDuc.selectors.getHederaMessages)

	const traceReportData = useSelector(TradeDocDuc.selectors.getBackwardTrace)

	const initiatingPartyDetails = useSelector(state =>
		AuthDuc.selectors.getOrganizationByID(
			state,
			getIn(activeDocument, ['initiatingPartyID'])
		)
	)
	const isSupplyChainModelEnforced = useSelector(
		TradeDocDuc.selectors.getSupplyChainModelEnforcedStatus
	)

	const forTodo = false

	const lengthCheck = data => {
		let length = false

		if (
			getIn(data, ['orgTraceGroupMap']) &&
			Object.keys(getIn(data, ['orgTraceGroupMap'])).length > 0
		) {
			Object.keys(getIn(data, ['orgTraceGroupMap'])).forEach(traceKey => {
				if (
					getIn(data, ['orgTraceGroupMap'])[traceKey] &&
					getIn(data, ['orgTraceGroupMap'])[traceKey].sourceTraces &&
					getIn(traceReportData, [
						'traceDataset',
						'orgTraceGroupMap',
					])[traceKey].sourceTraces.length > 0
				) {
					length = true
				}
			})
		}

		return length
	}

	const getRecursiveArray = (secondarySource, entityIdListArray) => {
		if (secondarySource.length > 0) {
			secondarySource.forEach(item => {
				if (
					!getIn(item, ['entity', 'id']) &&
					!entityIdListArray.includes('unknown-organization')
				) {
					entityIdListArray.push('unknown-organization')
				} else if (
					getIn(item, ['entity', 'id']) &&
					!entityIdListArray.includes(getIn(item, ['entity', 'id']))
				) {
					entityIdListArray.push(getIn(item, ['entity', 'id']))
				}

				if (
					item?.orgTraceGroupMap &&
					Object.keys(item.orgTraceGroupMap).length > 0
				) {
					Object.keys(item.orgTraceGroupMap).forEach(traceKey => {
						if (
							item.orgTraceGroupMap?.[traceKey]?.sourceTraces
								.length > 0
						) {
							getRecursiveArray(
								item.orgTraceGroupMap[traceKey].sourceTraces,
								entityIdListArray
							)
						}
					})
				}
			})

			return entityIdListArray
		}
	}

	const receivingPartyDetails = useSelector(state =>
		AuthDuc.selectors.getOrganizationByID(
			state,
			getIn(activeDocument, ['receivingPartyID'])
		)
	)

	const documentTrace = useSelector(TradeDocDuc.selectors.getDocumentTrace)
	const attachmentDoc = useSelector(TradeDocDuc.selectors.getAttachmentDoc)
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)
	const weighBridgeTickets = attachmentDoc
		? attachmentDoc.filter(doc => doc.category === 'weighbridge')
		: []

	const { id: loggedinOrgID } = useSelector(
		AuthDuc.selectors.getCurrentOrganization
	)

	const loggedInOrgWeighBridgeTicket = weighBridgeTickets.filter(
		wbt => wbt.ticketInfo.organizationID === loggedinOrgID
	)

	const notLoggedInOrgWeighBridgeTicket = weighBridgeTickets.filter(
		wbt => wbt.ticketInfo.organizationID !== loggedinOrgID
	)

	const loggedinOrgNetWeight = getIn(loggedInOrgWeighBridgeTicket, [
		0,
		'ticketInfo',
		'netWeight',
	])

	const notLoggedinOrgNetWeight = getIn(notLoggedInOrgWeighBridgeTicket, [
		0,
		'ticketInfo',
		'netWeight',
	])

	const netWeight = loggedinOrgNetWeight || notLoggedinOrgNetWeight

	const ffbQualityReports = attachmentDoc
		? attachmentDoc.filter(doc => doc.category === 'quality-palmoil-ffb')
		: []

	const cpoQualityReports = attachmentDoc
		? attachmentDoc.filter(doc => doc.category === 'quality-palmoil-cpo')
		: []
	const billOfLadingAttachments = attachmentDoc
		? attachmentDoc.filter(doc => doc.category === 'bill-of-lading')
		: []
	const otherAttachments = attachmentDoc
		? attachmentDoc.filter(doc => doc.category === 'documents')
		: []

	const [showRemarkModal, setShowRemarkModal] = useState(false)
	const [showFileUploadModal, setShowFileUploadModal] = useState(false)
	const [uploadedFiles, setUploadedFiles] = useState([])
	const [selectedFile, setSelectedFile] = useState({})
	const [newFileName, setNewFileName] = useState('')
	const [renameModal, setRenameModal] = useState(false)
	const [showChatBox, setShowChatBox] = useState(false)
	const [attachfile, setAttachfile] = useState({})
	const [traceDataLoader, setTraceDataLoader] = useState(true)
	const [currentAttachmentType, setCurrentAttachmentType] = useState(null)
	const [targetActiveStatus, setTargetActiveStatus] = useState('')
	const [downloadPDF, setDownloadPDF] = useState(false)
	const [stopCount, setStopCount] = useState(0)
	const [showCreateTradeDoc, setShowCreateTradeDoc] = useState(false)
	const [isDOEditable, setIsDOEditable] = useState(false)
	const [showWeighbridgeModal, setShowWeighbridgeModal] = useState(false)
	const [fullEntityListData, setFullEntityListData] = useState([])

	const hasInitiatedByCurrentOrg =
		getCurrentOrgID === initiatingPartyDetails.id
	const receivingOrgCategory =
		getIn(receivingPartyDetails, ['categories', 0, 'id']) || '---'
	const initiatingOrgCategory =
		getIn(initiatingPartyDetails, ['categories', 0, 'id']) || '---'

	const receivingOrgParentName =
		getIn(receivingPartyDetails, [
			'meta',
			'parentOrganization',
			0,
			'name',
		]) || ''
	const initiatingOrgParentName =
		getIn(initiatingPartyDetails, [
			'meta',
			'parentOrganization',
			0,
			'name',
		]) || ''

	let receivingOrgName =
		getIn(receivingPartyDetails, ['name']) ||
		getIn(activeDocument, ['meta', 'receiverEmailID']) ||
		getIn(activeDocument, ['meta', 'globalReceiver', 'name']) ||
		'---'
	let initiatingOrgName =
		getIn(initiatingPartyDetails, ['name']) ||
		getIn(activeDocument, ['meta', 'globalInitiator', 'name']) ||
		'---'
	receivingOrgName =
		receivingOrgCategory === 'palmoil-collectionPoint'
			? `${receivingOrgParentName} - ${receivingOrgName}`
			: receivingOrgName

	initiatingOrgName =
		initiatingOrgCategory === 'palmoil-collectionPoint'
			? `${initiatingOrgParentName} - ${initiatingOrgName}`
			: initiatingOrgName

	const { payload = {} } = locationState
	const { rootModule } = payload
	const _activeTab = useSelector(TradeDocDuc.selectors.getDocumentActiveTab)
	const timelineData = useSelector(TradeDocDuc.selectors.getTimelineData)

	const VIEW_TAB_CONFIG = [
		{
			name: 'original',
			label: t('tdmViewDocument.original'),
		},
		{
			name: 'associated',
			label: t('tdmViewDocument.associatedDocs'),
		},
		{
			name: 'timeline',
			label: t('tdmViewDocument.timeline'),
		},

		{
			name: 'attachments',
			label: t('tdmViewDocument.attachments'),
		},
		...(rootModule === 'delivery-order' &&
		!actorWithoutTraceReport &&
		currentPlatform() === 'palmoil'
			? [
					{
						name: 'traceReport',
						label: t('tdmDetailsEntry.traceReport'),
					},
			  ]
			: ''),

		{
			name: 'version',
			label: t('tdmViewDocument.version'),
		},
	]

	const chatBoxData = data => {
		setShowChatBox(data)
	}

	const activeTabs = useMemo(() => {
		let tabs = [...VIEW_TAB_CONFIG]
		if (!activeDocument.versions) {
			tabs = tabs.filter(tab => tab.name !== 'version')
		}

		return tabs
	}, [VIEW_TAB_CONFIG, activeDocument.versions])

	const [
		originalTabKey,
		associatedTabKey,
		timelineTabKey,
		attachmentTabKey,
		traceReportTabKey,
	] = VIEW_TAB_CONFIG.map(k => k.name)
	const [activeTab, setActiveTab] = useState(_activeTab)

	const SubDocuments = [
		{
			label: t('tdmViewDocument.invoice').toUpperCase(),
			name: 'invoice',
		},
		{
			label: t('tdmViewDocument.deliveryOrder').toUpperCase(),
			name: 'delivery-order',
		},
		{
			label: t('common.attachFile'),
			name: 'file-attachment',
		},
	]

	const invoiceSubDOc = getIn(SubDocuments, [1]) || []

	const menuWithFFB = [
		{
			label: t('common.weighbridge'),
			name: 'weighbridge-slip',
		},
		{
			label: t('common.ffbQuality'),
			name: 'quality-ffb',
		},
		{
			label: t('tdmPreviewDetails.billOfLading'),
			name: 'bill-of-lading',
		},
		{
			label: t('common.attachFile'),
			name: 'file-attachment',
		},
	]

	const menuWithCPO = [
		{
			label: t('common.weighbridge'),
			name: 'weighbridge-slip',
		},
		{
			label: t('common.cpoQuality'),
			name: 'quality-cpo',
		},
		{
			label: t('tdmPreviewDetails.billOfLading'),
			name: 'bill-of-lading',
		},
		{
			label: t('common.attachFile'),
			name: 'file-attachment',
		},
	]

	const subDocs = [
		{
			label: t('common.weighbridge'),
			name: 'weighbridge-slip',
		},
		{
			label: t('tdmPreviewDetails.billOfLading'),
			name: 'bill-of-lading',
		},
		{
			label: t('common.attachFile'),
			name: 'file-attachment',
		},
	]

	const products = getIn(activeDocument, ['products']) || []
	const isCPO = products[0] ? products[0].code === 'CPO' : false
	const isFFB = products[0] ? products[0].code === 'FFB' : false
	const menuForInitiatedOrg = isCPO ? menuWithCPO : subDocs
	const menuForNonInitiatedOrg = isFFB ? menuWithFFB : subDocs
	const millSubDocs = hasInitiatedByCurrentOrg
		? menuForInitiatedOrg
		: menuForNonInitiatedOrg

	const getSubDocuments = () => {
		if (isMill && !cpoQualityReports.length) {
			return millSubDocs
		}
		if (isRefinery && isCPO) {
			return menuWithCPO
		}
		if ((isFFBDealer || isSubDealer) && isFFB) {
			return menuWithFFB
		}

		if (
			isTraderOrEndManufacturer &&
			activeDocument.docStatus !== 'delivered'
		) {
			return subDocs.filter(doc => {
				return doc.name !== 'weighbridge-slip'
			})
		}

		return subDocs
	}

	const getBreadCrumbsList = useCallback(
		({ title }) => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.tradeDocManager'),
				name: 'tradeDocManager',
				isActive: true,
			},
			{
				label: `${t(title)}`,
				name: 'document-view',
				isActive: false,
			},
		],
		[t]
	)

	const title = NAME_ALIASES[rootModule]
	const breadCrumbs = useMemo(() => getBreadCrumbsList({ title }), [
		getBreadCrumbsList,
		title,
	])

	const breadCrumRootModule = getIn(locationState, [
		'prev',
		'payload',
		'rootModule',
	])
		? getIn(locationState, ['prev', 'payload', 'rootModule'])
		: null

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			if (isIframeForTeamsApp()) {
				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
						{
							rootModule:
								breadCrumRootModule === 'incoming' ||
								breadCrumRootModule === 'outgoing' ||
								breadCrumRootModule === 'all'
									? getIn(locationState, [
											'prev',
											'payload',
											'rootModule',
									  ])
									: 'incoming',
						}
					)
				)
			} else {
				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.DASHBOARD
					)
				)
			}
		}
		if (target === 'tradeDocManager') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
					{
						rootModule:
							breadCrumRootModule === 'incoming' ||
							breadCrumRootModule === 'outgoing' ||
							breadCrumRootModule === 'all'
								? getIn(locationState, [
										'prev',
										'payload',
										'rootModule',
								  ])
								: 'incoming',
					}
				)
			)
		}
	}
	const messagesBlockRef = useRef(null)
	const isOriginalTabActive = activeTab === originalTabKey
	const isTimelineTabActive = activeTab === timelineTabKey
	const isHistoryTabActive = activeTab === associatedTabKey
	const isAttachmentTabActive = activeTab === attachmentTabKey
	const isTraceReportTabActive = activeTab === traceReportTabKey

	const messages = getIn(activeDocument, ['remarks']) || []
	const entityFiles = getIn(activeDocument, ['meta', 'files']) || []
	const handleMessagesScroll = useCallback(() => {
		if (!isOriginalTabActive) {
			setActiveTab(originalTabKey)
		}

		setTimeout(() => {
			scrollIntoView(messagesBlockRef.current || {}, -80)
		}, 300)
	}, [isOriginalTabActive, originalTabKey])

	const handleSubmit = remark => {
		dispatch(
			TradeDocDuc.creators.addNewRemark(
				rootModule,
				remark,
				activeDocument.id
			)
		)
	}
	const remarksTtl = getIn(activeDocument, ['remarks']) || []
	const remarkCount = remarksTtl.reduce((n, x) => {
		const testArray = 'comment' in x

		return n + (testArray === true)
	}, 0)
	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			if (actionType === 'finalize_remark_status') {
				const { remarks } = meta
				const documentStatusPropsList = {
					docRefId: activeDocument.id,
					docType: activeDocument.type,
					newStatus: targetActiveStatus,
					remarks,
					successMsg: t('common.statusUpdateSuccess'),
					errorMsg: t('common.statusUpdateError'),
					postChange: () => {
						// retrigger the current route so new data is fetched again.
						dispatch(
							MainRouteDuc.creators.redirect(
								locationState.type,
								locationState.payload,
								locationState.query
							)
						)
					},
				}
				// get the active record
				dispatch(
					TradeDocDuc.creators.changeDocumentStatus(
						documentStatusPropsList
					)
				)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			dispatch,
			activeDocument.id,
			activeDocument.type,
			targetActiveStatus,
			t,
			actor,
			locationState.type,
			locationState.payload,
			locationState.query,
		]
	)

	const getTraceText = doc => {
		let text = t('tdmViewDocument.noTrace')
		const createdAtDate =
			getIn(doc, ['createdAt']) && getIn(doc, ['createdAt']).split('T')[0]
		const createdAtDateFiltered = new Date(createdAtDate)

		const nextDate = createdAtDateFiltered.setDate(
			createdAtDateFiltered.getDate() + 1
		)

		if (new Date(nextDate) > new Date().setHours(5, 30, 0, 0)) {
			text = (
				<div>
					<span>
						{t('tdmViewDocument.traceReportDateText')}&nbsp;
					</span>

					<span style={{ color: theme.color.primary }}>
						{getDateByFormat(nextDate, 'dd MMM yyyy', activeLocale)}
						.
					</span>
				</div>
			)
		}

		return text
	}

	const getNextDate = data => {
		let date = null
		const createdAtDate =
			getIn(data, ['createdAt']) &&
			getIn(data, ['createdAt']).split('T')[0]
		const createdAtDateFiltered = new Date(createdAtDate)

		const nextDate = createdAtDateFiltered.setDate(
			createdAtDateFiltered.getDate() +
				parseInt(getAutoProductionInterval(), 10)
		)

		if (new Date(nextDate) > new Date().setHours(5, 30, 0, 0)) {
			date = getDateByFormat(nextDate, 'dd MMM yyyy', activeLocale)
		}

		return date
	}

	const traceReportContent = () => {
		const getNoTraceText = () => {
			const time = getIn(traceReportData, ['id']) ? 0 : 2000
			setTimeout(() => {
				setTraceDataLoader(false)
			}, time)

			const getText = () => {
				let text = ''
				if (!getIn(activeDocument, ['meta', 'traceGenerated'])) {
					const dateString = getIn(uploadDODetails, ['nextSchedule'])

					if (dateString !== '') {
						const formattedNextDate = new Date(dateString)
						const today = new Date()
						const createdDate = new Date(
							getIn(activeDocument, ['createdAt'])
						)
						const dateAfter14 = new Date(
							createdDate.getTime() + 14 * 24 * 60 * 60 * 1000
						)
						if (
							today < formattedNextDate &&
							dateAfter14 > formattedNextDate // The cron job running date will get updated to next date.
						) {
							text = (
								<div>
									<span>
										{t('traceReport.UploadDOTraceText')}
									</span>
									&nbsp;
									<span
										style={{ color: theme.color.primary }}
									>
										{`${getDayMonthYearFormat(
											dateString
										)}.`}
									</span>
								</div>
							)
						} else if (
							getIn(activeDocument, ['docStatus']) ===
								'delivered' &&
							!(
								getIn(activeDocument, [
									'receiverTraceIDs',
									'0',
									'traceID',
								]) ||
								getIn(activeDocument, [
									'meta',
									'receiverTraceGroupID',
								])
							)
						) {
							text = (
								<div>
									{t('traceReport.inventoryAdditionInfoText')}{' '}
									&nbsp;
									{getIn(activeDocument, [
										'receivingPartyID',
									]) === getCurrentOrgID ? (
										<span>
											{t('traceReport.please')}&nbsp;
											<span
												style={{
													cursor: 'pointer',
													color: theme.color.primary,
													textDecoration: 'underline',
												}}
												onClick={() =>
													dispatch(
														MainRouteDuc.creators.switchPage(
															MainRouteDuc.types
																.REFINERY$SUBROOT,
															{
																rootModule:
																	'input-storage',
															},
															{},
															{},
															true
														)
													)
												}
												role="button"
												tabIndex={0}
												onKeyDown={console.log('')}
											>
												{t('traceReport.clickHere')}
											</span>
											&nbsp;
											{t('traceReport.toAssignStorage')}.
										</span>
									) : (
										''
									)}
								</div>
							)
						} else {
							text = <div>{getTraceText(activeDocument)}</div>
						}
					} else {
						text = <div>{getTraceText(activeDocument)}</div>
					}
				} else {
					text = <div>{getTraceText(activeDocument)}</div>
				}

				return text
			}

			return traceDataLoader ? (
				<>
					<TransparentBgSpinner />
					<div>{t('traceReport.traceReportProcessingText')}</div>
				</>
			) : (
				<div>{getText()}</div>
			)
		}

		return (
			<BoxShadowWrapper
				style={{
					border: 'none',
					padding: '25px',
				}}
			>
				{getIn(traceReportData, ['id']) &&
				getIn(activeDocument, ['meta', 'traceGenerated']) ? (
					<>
						<div
							style={{
								width: '100%',
								marginBottom: 10,
								display: 'inline-block',
							}}
						>
							{getIn(activeDocument, [
								'meta',
								'receiverTraceGroupID',
							]) &&
								currentOrgdetails.id !==
									getIn(activeDocument, [
										'initiatingPartyID',
									]) && (
									<>
										<Button
											label={t('productTrace.title')}
											action
											rounded
											onClick={() => {
												if (
													currentOrgdetails?.meta
														?.industry ===
													'petroleum'
												) {
													dispatch(
														MainRouteDuc.creators.switchPage(
															MainRouteDuc.types
																.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP_V2,
															{
																traceGroupID: getIn(
																	activeDocument,
																	[
																		'meta',
																		'receiverTraceGroupID',
																	]
																),
																orgID:
																	activeDocument.receivingPartyID,
															},
															{},
															{},
															true
														)
													)
												} else {
													dispatch(
														MainRouteDuc.creators.switchPage(
															MainRouteDuc.types
																.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP,
															{
																traceGroupID: getIn(
																	activeDocument,
																	[
																		'meta',
																		'receiverTraceGroupID',
																	]
																),
																orgID:
																	activeDocument.receivingPartyID,
															},
															{},
															{},
															true
														)
													)
												}
											}}
											extendStyles={{
												float: 'right',
											}}
										/>
									</>
								)}
						</div>
						<div
							style={{
								display: 'flow-root',
							}}
						>
							{getIn(activeDocument, ['docStatus']) ===
							'submitted' ? (
								''
							) : (
								<div
									style={{
										float: 'right',
										display: 'flex',
										padding: '10px 15px',
										borderRadius: '20px',
										backgroundColor:
											theme.color.themeGreen3,
									}}
								>
									<div>
										<IconWrapper
											color={theme.color.themeGreen2}
											size={20}
										>
											<Icon
												style={{
													color:
														theme.color.themeGreen2,
												}}
												glyph={
													getVerificationStatus(
														activeDocument,
														hederaMessages,
														getIn(activeDocument, [
															'id',
														])
													) &&
													!fullEntityListData.includes(
														'unknown-organization'
													)
														? verifiedIcon
														: NotVerifiedIcon
												}
											/>
										</IconWrapper>
									</div>

									<div
										style={{
											fontSize: '14px',
											paddingTop: '3px',
											paddingLeft: '13px',
											color: theme.color.themeGreen2,
										}}
									>
										{getVerificationStatus(
											activeDocument,
											hederaMessages,
											getIn(activeDocument, ['id'])
										) &&
										!fullEntityListData.includes(
											'unknown-organization'
										)
											? t(
													'tdmViewDocument.allTransactionsAreVerifiedFromBlockchain'
											  )
											: t(
													'tdmViewDocument.allTransactionsAreNotVerifiedFromBlockchain'
											  )}
									</div>
								</div>
							)}
						</div>
						{/* <Spacer size={24} /> */}
						<div
							style={{
								display: 'flex',
								padding: '0px 12px',
								justifyContent: 'space-between',
							}}
						>
							<IllustrationDibiz
								src="/images/DIBIZ-LOGO.png"
								alt="dibizTour"
							/>
							<Illustration
								src="/images/CU-verified.png"
								alt="dibizTour"
							/>
						</div>
						{forTodo && (
							<Box>
								<div
									style={{
										display: 'flex',
										paddingTop: '27px',
									}}
								>
									<Box
										style={{
											width: '53%',
										}}
									>
										<Box
											style={{
												fontWeight: 500,
												fontSize: '16px',
												marginBottom: '12px',
												color: theme.color.black3,
											}}
										>
											{t(
												'traceReport.hashValueOfDeliveryOrder'
											)}
										</Box>
										<div
											style={{
												display: 'flex',
												fontSize: '14px',
												marginRight: '65px',
												borderRadius: '6px',
												padding: '18px 13px',
												justifyContent: 'center',
												color: theme.color.grey15,
												backgroundColor:
													theme.color.themeBlue4,
												border: `1px solid ${theme.color.themeBlue3}`,
											}}
										>
											<Box
												style={{
													marginTop: '2px',
													maxWidth: '385px',
													wordBreak: 'break-all',
												}}
											>
												{getIn(traceReportData, [
													'data',
													'hashValueOfDeliveryOrder',
												]) || ''}
											</Box>
											<Box
												style={{
													marginLeft: '20px',
													justifyContent: 'center',
												}}
											>
												<IconWrapper
													color={
														theme.color.themeGreen4
													}
													size={20}
												>
													<Icon
														color={
															theme.color
																.themeGreen4
														}
														glyph={SuccessIcon}
													/>
												</IconWrapper>
											</Box>
										</div>
									</Box>
									<Box
										style={{
											width: '47%',
										}}
									>
										<Box
											style={{
												fontWeight: 500,
												fontSize: '16px',
												marginBottom: '12px',
												color: theme.color.black3,
											}}
										>
											{t(
												'traceReport.hashValueOfTraceReport'
											)}
										</Box>
										<div
											style={{
												display: 'flex',
												fontSize: '14px',
												// marginRight: '35px',
												borderRadius: '6px',
												padding: '18px 13px',
												justifyContent: 'center',
												color: theme.color.grey15,
												backgroundColor:
													theme.color.themeBlue4,
												border: `1px solid ${theme.color.themeBlue3}`,
											}}
										>
											<Box
												style={{
													marginTop: '2px',
													maxWidth: '385px',
													wordBreak: 'break-all',
												}}
											>
												{getIn(traceReportData, [
													'data',
													'hashValueOfTraceReport',
												]) || ''}
											</Box>
											<Box
												style={{
													marginLeft: '20px',
													justifyContent: 'center',
												}}
											>
												<IconWrapper
													color={
														theme.color.themeGreen4
													}
													size={20}
												>
													<Icon
														color={
															theme.color
																.themeGreen4
														}
														glyph={SuccessIcon}
													/>
												</IconWrapper>
											</Box>
										</div>
									</Box>
								</div>
							</Box>
						)}
						{/*  Remove this after implementation change confirmed */}
						<Box>
							{getIn(traceReportData, [
								'blockChainReference',
								'traceDataHashValue',
							]) ? (
								<div
									style={{
										display: 'flex',
										paddingTop: '27px',
									}}
								>
									<Box
										style={{
											width: '53%',
										}}
									>
										<Box
											style={{
												fontWeight: 500,
												fontSize: '16px',
												marginBottom: '12px',
												color: theme.color.black3,
											}}
										>
											{t(
												'traceReport.hashValueCombinationOfDeliveryOrderAndTraceReport'
											)}
										</Box>
										<div
											style={{
												display: 'flex',
												height: '90px',
												fontSize: '14px',
												marginRight: '65px',
												borderRadius: '6px',
												padding: '18px 13px',
												alignItems: 'center',
												// justifyContent: 'center',
												color: theme.color.grey15,
												backgroundColor:
													theme.color.themeBlue4,
												border: `1px solid ${theme.color.themeBlue3}`,
											}}
										>
											<Box
												style={{
													width: '85%',
													marginTop: '2px',
													paddingLeft: '25px',
													// maxWidth: '385px',
													wordBreak: 'break-all',
												}}
											>
												{getIn(traceReportData, [
													'blockChainReference',
													'traceDataHashValue',
												]) || ''}
											</Box>
											<Box
												style={{
													marginLeft: '20px',
													justifyContent: 'center',
												}}
											>
												<IconWrapper
													color={
														theme.color.themeGreen4
													}
													size={20}
												>
													<Icon
														color={
															theme.color
																.themeGreen4
														}
														glyph={SuccessIcon}
													/>
												</IconWrapper>
											</Box>
										</div>
									</Box>
									<Box
										style={{
											width: '47%',
										}}
									>
										<Box
											style={{
												fontWeight: 500,
												fontSize: '16px',
												marginBottom: '12px',
												color: theme.color.black3,
											}}
										>
											{t(
												'traceReport.transactionReferenceFromBlockchain'
											)}
										</Box>
										<div
											style={{
												display: 'flex',
												fontSize: '14px',
												height: '90px',
												// marginRight: '35px',
												borderRadius: '6px',
												padding: '12px 13px',
												paddingLeft: '35px',
												alignItems: 'center',
												color: theme.color.grey15,
												backgroundColor:
													theme.color.themeBlue4,
												border: `1px solid ${theme.color.themeBlue3}`,
											}}
										>
											<Box style={{ width: '85%' }}>
												<Box
													style={{
														maxWidth: '385px',
														marginBottom: '5px',
														color:
															theme.color.primary,
														cursor: getIn(
															traceReportData,
															[
																'blockChainReference',
																'explorerUrl',
															]
														)
															? 'pointer'
															: 'default',
													}}
													onClick={() => {
														if (
															getIn(
																traceReportData,
																[
																	'blockChainReference',
																	'explorerUrl',
																]
															)
														) {
															window.open(
																getIn(
																	traceReportData,
																	[
																		'blockChainReference',
																		'explorerUrl',
																	]
																),
																'_blank'
															)
														}
													}}
												>
													<LinkWrap>{`${getIn(
														traceReportData,
														[
															'blockChainReference',
															'topicID',
														]
													)} / ${getIn(
														traceReportData,
														[
															'blockChainReference',
															'topicSequenceNumber',
														]
													)}`}</LinkWrap>
												</Box>
												<Box>
													{getDateWithTimeByFormat(
														getIn(traceReportData, [
															'updatedAt',
														])
													) || ''}
												</Box>
											</Box>
											<Box
												style={{
													marginLeft: '20px',
													justifyContent: 'center',
												}}
											>
												<IconWrapper
													color={
														theme.color.themeGreen4
													}
													size={20}
												>
													<Icon
														color={
															theme.color
																.themeGreen4
														}
														glyph={SuccessIcon}
													/>
												</IconWrapper>
											</Box>
										</div>
									</Box>
								</div>
							) : (
								''
							)}
						</Box>
						{getIn(activeDocument, ['docStatus']) ===
						'submitted' ? (
							<div
								style={{
									display: 'flex',
									fontSize: '14px',
									marginTop: '15px',
									marginBottom: '11px',
									justifyContent: 'end',
									color: theme.color.themeRed3,
								}}
							>
								{t('tdmViewDocument.submittedDOText')}
							</div>
						) : (
							<div
								style={{
									display: 'flex',
									fontSize: '14px',
									marginTop: '24px',
									marginBottom: '50px',
									color: theme.color.grey15,
								}}
							>
								<div
									style={{
										width: '78%',
									}}
								>
									<div
										style={{
											display: 'flex',
											marginBottom: '6px',
										}}
									>
										<div
											style={{
												width: '130px',
												color: theme.color.black,
												fontWeight: 400,
											}}
										>
											{t('traceReport.createdOn')}
										</div>
										<div
											style={{
												marginRight: '10px',
												color: theme.color.black,
												fontWeight: 600,
											}}
										>
											:
										</div>
										<div>
											{getDateWithTimeByFormat(
												getIn(traceReportData, [
													'createdAt',
												])
											) || ''}
										</div>
									</div>
									<div
										style={{
											display: 'flex',
										}}
									>
										<div
											style={{
												width: '130px',
												color: theme.color.black,
												fontWeight: 400,
											}}
										>
											{t('traceReport.lastUpdatedOn')}
										</div>
										<div
											style={{
												marginRight: '10px',
												color: theme.color.black,
												fontWeight: 600,
											}}
										>
											:
										</div>
										<div>
											{getDateWithTimeByFormat(
												getIn(traceReportData, [
													'traceUpdatedAt',
												])
											) || ''}
										</div>
									</div>
								</div>
								<div>
									<div style={{ marginBottom: '6px' }}>
										{t(
											'traceReport.dataFetchedFromDibizBlockchain'
										)}
									</div>
									<div>
										{!getIn(traceReportData, [
											'eudrCompliance',
										]) &&
											getIn(traceReportData, [
												'unknownFlag',
											]) && (
												<div
													style={{
														color: theme.color.info,
													}}
												>
													{t(
														'traceReport.unknownSourceText'
													)}
												</div>
											)}
									</div>
								</div>
							</div>
						)}
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<div
								style={{
									padding: '5px 15px',
									backgroundColor: getIn(traceReportData, [
										'eudrCompliance',
									])
										? theme.color.iconGreen
										: theme.color.pureRed,
									color: theme.color.white,
									borderRadius: '7px',
								}}
							>
								{getIn(traceReportData, ['eudrCompliance'])
									? t('traceReport.eudrCompliant')
									: t('traceReport.eudrNotCompliant')}
							</div>
						</div>

						{getNextDate(traceReportData) && !downloadPDF && (
							<div
								style={{
									padding: '15px',
									margin: '20px 100px',
									borderRadius: '10px',
									backgroundColor: theme.color.themeYellow1,
								}}
							>
								<span style={{ color: theme.color.black4 }}>
									{t('traceReport.traceDataChangeText')}
								</span>
								<span style={{ color: theme.color.primary }}>
									&nbsp;&nbsp;{getNextDate(traceReportData)}.
								</span>
							</div>
						)}
						<Spacer
							size={30}
							horizontal
							style={{
								marginBottom: '30px',
								border: `1px solid ${theme.color.themeBlue3}`,
							}}
						/>
						<Box>
							<Box
								style={{
									fontWeight: 500,
									fontSize: '16px',
									marginBottom: '12px',
									color: theme.color.black3,
								}}
							>
								{`${t('traceReport.tradeData')} (${t(
									'traceReport.primary'
								)})`}
							</Box>
							<Box>
								<TraceReportTable
									header={
										getHeader(
											activeDocument,
											getIn(traceReportData, [
												'traceDataset',
											]),
											t
										)?.name
									}
									virtualTraceData={
										getHeader(
											activeDocument,
											getIn(traceReportData, [
												'traceDataset',
											]),
											t
										)?.virtualTraceData
									}
									hederaMessages={hederaMessages}
									receiverOrgId={getIn(activeDocument, [
										'receivingPartyID',
									])}
									showVerifiedText
									blockChainVerification={getVerificationStatus(
										activeDocument,
										hederaMessages,
										getIn(activeDocument, ['id']),
										true
									)}
									traceDetails={traceDetails(
										activeDocument,
										getIn(traceReportData, ['traceDataset'])
									)}
									originatingOrganizationDetails={originatingOrganizationDetails(
										getIn(traceReportData, ['traceDataset'])
									)}
									destinationOrganizationDetails={destinationOrganizationDetails(
										getIn(traceReportData, ['traceDataset'])
									)}
									onCertificateclick={certificateData => {
										handleCertificateData(certificateData)
									}}
									submittedState={
										getIn(activeDocument, ['docStatus']) ===
										'submitted'
									}
									getExplorerLink={(
										topicID,
										topicReferenceNumber
									) =>
										getExplorerLink(
											topicID,
											topicReferenceNumber
										)
									}
									isMobile={isMobile}
								/>
							</Box>
							{downloadPDF ? (
								<div style={{ display: 'none' }}>
									<CompleteTraceReport
										fromTraceReportTab
										downloadPdfFn={data =>
											setDownloadPDF(data)
										}
									/>
								</div>
							) : (
								''
							)}
						</Box>
						<div
							style={{
								display: 'flex',
								marginTop: '30px',
								justifyContent: lengthCheck(
									getIn(traceReportData, ['traceDataset'])
								)
									? 'normal'
									: 'end',
							}}
						>
							{lengthCheck(
								getIn(traceReportData, ['traceDataset'])
							) && !isMobile ? (
								<Box
									style={{
										width: '80%',
									}}
								>
									<Box
										style={{
											fontWeight: 500,
											fontSize: '16px',
											marginBottom: '8px',
											color: theme.color.blue8,
											textDecoration: 'underline',
										}}
									>
										<div
											style={{
												width: '225px',
												cursor: 'pointer',
											}}
											onClick={() => {
												dispatch(
													MainRouteDuc.creators.switchPage(
														MainRouteDuc.types
															.TRADE_DOCUMENT_MANAGER$COMPLETETRADEREPORT,
														{
															rootModule: getIn(
																activeDocument,
																[
																	'meta',
																	'referenceName',
																]
															),
															documentReference: getIn(
																activeDocument,
																[
																	'meta',
																	'referenceValue',
																]
															),
															traceReportStatus:
																'completeTraceReport',
														}
													)
												)
											}}
											role="button"
											tabIndex={0}
											onKeyDown={console.log('')}
										>
											{`${t('traceReport.Secondary')} ${t(
												'traceReport.tradeData'
											)}`}
										</div>
									</Box>
									<div
										style={{
											display: 'flex',
										}}
									>
										<IconWrapper size={20}>
											<Icon
												style={{
													borderRadius: '50%',
													color: theme.color.white,
													backgroundColor:
														theme.color.grey15,
												}}
												glyph={infoIcon}
											/>
										</IconWrapper>
										<Box
											style={{
												fontSize: '14px',
												marginTop: '3px',
												marginLeft: '5px',
												color: theme.color.grey15,
											}}
										>
											{t('traceReport.clickToview')}
										</Box>
									</div>
								</Box>
							) : (
								''
							)}
							<Box
								style={{
									display: 'block',
									width: isMobile ? '100%' : 'auto',
								}}
							>
								{isMobile && (
									<Box
										style={{
											marginBottom: '5px',
											alignItems: 'center',
											color: theme.color.themeGreen2,
										}}
									>
										{t(
											'traceReport.detailedReportAndDownloadOptionTextForMobile'
										)}
									</Box>
								)}
								{!isMobile && (
									<Box
										style={{
											padding: '2px',
										}}
									>
										<Button
											label={
												<div
													style={{
														marginLeft: '7px',
													}}
												>
													{t(
														'dealerGeneralReports.downloadRpt'
													)}
												</div>
											}
											rounded
											extendStyles={{
												minWidth: '220px',
												borderRadius: '24px',
												paddingRight: '12px',
												color: theme.color.blue8,
												backgroundColor:
													theme.color.themeBlue6,
											}}
											isLoading={downloadPDF}
											customIcon={
												<ButtonIconWrapper>
													<IconWrapper
														size={20}
														style={{
															marginTop: '5px',
														}}
													>
														<Icon
															glyph={DownloadIcon}
														/>
													</IconWrapper>
												</ButtonIconWrapper>
											}
											onClick={() => setDownloadPDF(true)}
										/>
									</Box>
								)}
							</Box>
						</div>
					</>
				) : (
					getNoTraceText()
				)}
			</BoxShadowWrapper>
		)
	}

	const note =
		!hasInitiatedByCurrentOrg &&
		rootModule === 'purchase-order' &&
		(activeDocument.docStatus !== 'complete' ||
			activeDocument.docStatus !== 'accepted')
			? 'tdmViewDocument.purchaseOrderNote'
			: ''

	const addAttachmentToRFQ = () => {
		setUploadedFiles([])
		dispatch(
			TradeDocDuc.creators.createEntityAttachment(
				rootModule,
				activeDocument.id,
				uploadedFiles,
				'Success',
				currentAttachmentType
			)
		)
	}
	const editFileName = () => {
		const { attachmentID, entityID } = selectedFile
		const newFiles = {
			...selectedFile,
			meta: {
				...selectedFile?.meta,
				name: newFileName,
			},
		}
		dispatch(
			TradeDocDuc.creators.updateEntityAttachment(
				rootModule,
				entityID,
				attachmentID,
				newFiles,
				'updated Success'
			)
		)
	}

	const deleteFileAttachment = file => {
		const { attachmentID, entityID } = file
		dispatch(
			TradeDocDuc.creators.deleteEntityAttachment(
				rootModule,
				entityID,
				attachmentID,
				'Deleted Success'
			)
		)
	}

	const getText = data => {
		let text = ''

		if (data === 'main-header') {
			if (rootModule === 'purchase-order') {
				text = `${t('common.view')} ${t(
					'tdmColumnHeaders.purchase-order'
				)}`
			} else if (rootModule === 'invoice') {
				text = `${t('common.view')} ${t('tdmColumnHeaders.invoice')}`
			} else if (rootModule === 'delivery-order') {
				text = `${t('common.view')} ${t(
					'tdmColumnHeaders.delivery-order'
				)}`
			}
		}

		return text
	}

	const getNameById = id => {
		let name = ''

		if (id === getIn(receivingPartyDetails, ['id'])) {
			name = getIn(receivingPartyDetails, ['name'])
		} else if (id === getIn(initiatingPartyDetails, ['id'])) {
			name = getIn(initiatingPartyDetails, ['name'])
		}

		return name
	}

	const getTimelineDataArray = () => {
		const timelineArray = []
		const typeFormat = item => {
			const firstName =
				item
					.split('-')[0]
					.charAt(0)
					.toUpperCase() + item.split('-')[0].slice(1)
			const secondName =
				item
					.split('-')[1]
					.charAt(0)
					.toUpperCase() + item.split('-')[1].slice(1)

			return `${firstName}-${secondName}`
		}
		if (timelineData && timelineData.length > 0) {
			timelineData.forEach(item => {
				timelineArray.push({
					date: getDateByFormat(
						item.createdAt,
						'dd MMM yyyy hh:mm a',
						activeLocale
					),
					status: `${
						item.entityType.includes('-')
							? typeFormat(item.entityType)
							: item.entityType.charAt(0).toUpperCase() +
							  item.entityType.slice(1)
					} ${item.state.charAt(0).toUpperCase() +
						item.state.slice(1)}`,
					statusDetail: item.createdBy
						? `by ${item.userName},  ${getNameById(item.createdBy)}`
						: '',
					version: item.version === 0 ? '0' : item.version,
					versionPath: `/trade-document-manager/${item.entityType}/${item.entityID}`,
				})
			})
		}

		return timelineArray
	}

	const getAssociatedRef = type => {
		let data = ''
		if (
			getIn(activeDocument, ['meta', 'docReference']) &&
			getIn(activeDocument, ['meta', 'docReference']).length > 0
		) {
			getIn(activeDocument, ['meta', 'docReference']).forEach(item => {
				if (item.referenceDocType === type) {
					data = item.referenceValue
				}
			})
		}

		return data
	}

	const getAttachedFiles = data => {
		const files = []
		data.forEach(document => {
			if (
				getIn(document, ['type']) === 'user-input' ||
				getIn(document, ['organizationID']) === getCurrentOrgID
			) {
				files.push(
					<Box overflow>
						<AttachmentMenu
							key={document?.id}
							data={document}
							currentOrgID={getCurrentOrgID}
							selectedCPID={selectedCPID}
							handleEdit={() => {
								setSelectedFile(document)
							}}
							handleRename={() => {
								setRenameModal(true)
							}}
							handleDelete={() => {
								deleteFileAttachment(selectedFile)
							}}
						/>
					</Box>
				)
			}
		})

		return files
	}
	const getReceiverEmail = () => {
		let receiver = ''

		if (hasInitiatedByCurrentOrg) {
			receiver =
				receivingPartyDetails && receivingPartyDetails.id
					? ''
					: getIn(activeDocument, ['meta', 'receiverEmailID'])
		}

		return receiver
	}

	const getMeasure = (normalMeasure, measureForMobile) => {
		let measure = normalMeasure
		if (isMobile) {
			measure = measureForMobile
		}

		return measure
	}

	const getExplorerLink = (topicID, topicReferenceNumber) => {
		dispatch(
			TradeDocDuc.creators.fetchMirrorNodeData(
				topicID,
				topicReferenceNumber
			)
		)
	}

	useEffect(() => {
		if (
			rootModule !== 'delivery-order' ||
			(((activeDocument?.id &&
				activeDocument?.meta?.enforceSupplyChainModel ===
					isSupplyChainModelEnforced) ||
				actor.includes('palmoil_plantation')) &&
				!getIn(activeDocument, ['meta', 'incomingVirtualDO']))
		) {
			setIsDOEditable(true)
		}
	}, [activeDocument, actor, isSupplyChainModelEnforced, rootModule])

	useEffect(() => {
		dispatch(MessagesDuc.creators.setDocTypeList([]))
		dispatch(TradeDocDuc.creators.setHederaMessages({}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (traceReportData && traceReportData.id) {
			const entityIdList = []
			if (getIn(traceReportData, ['traceDataset', 'entity', 'id'])) {
				entityIdList.push(
					getIn(traceReportData, ['traceDataset', 'entity', 'id'])
				)
			}
			if (
				getIn(traceReportData, ['traceDataset', 'orgTraceGroupMap']) &&
				Object.keys(
					getIn(traceReportData, ['traceDataset', 'orgTraceGroupMap'])
				).length > 0
			) {
				Object.keys(
					getIn(traceReportData, ['traceDataset', 'orgTraceGroupMap'])
				).forEach(traceKey => {
					if (
						getIn(traceReportData, [
							'traceDataset',
							'orgTraceGroupMap',
						])[traceKey] &&
						getIn(traceReportData, [
							'traceDataset',
							'orgTraceGroupMap',
						])[traceKey].sourceTraces &&
						getIn(traceReportData, [
							'traceDataset',
							'orgTraceGroupMap',
						])[traceKey].sourceTraces.length > 0
					) {
						const currentData = getRecursiveArray(
							getIn(traceReportData, [
								'traceDataset',
								'orgTraceGroupMap',
							])[traceKey].sourceTraces,
							entityIdList
						)
						const data = currentData.filter(
							item => item !== 'unknown-organization'
						)
						setFullEntityListData(currentData)
						if (data && data.length > 0) {
							const entityIdString = data.toString()
							if (entityIdString) {
								dispatch(
									TradeDocDuc.creators.getHederaMessages(
										entityIdString
									)
								)
							}
						}
					}
				})
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [traceReportData])

	useEffect(() => {
		if (tourModule !== '') {
			if (tourModule === 'tdmView') {
				if (rootModule === 'purchase-order') {
					dispatch(
						WebTourDuc.creators.setActiveTourModule('tdmPOView')
					)
				}
				if (rootModule === 'delivery-order') {
					dispatch(
						WebTourDuc.creators.setActiveTourModule('tdmDOView')
					)
				}
				if (rootModule === 'invoice') {
					dispatch(
						WebTourDuc.creators.setActiveTourModule(
							'tdmInvoiceView'
						)
					)
				}
			} else {
				dispatch(WebTourDuc.creators.setActiveTourModule(''))
			}
		}
	}, [tourModule, dispatch, rootModule])

	useEffect(() => {
		if (attachmentDoc && attachmentDoc.length > 0) {
			attachmentDoc.forEach(item => {
				if (
					getIn(item, ['type']) === 'file' &&
					getIn(item, ['organizationID']) === getCurrentOrgID
				) {
					setAttachfile(getIn(item, ['file']))
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attachmentDoc])

	useEffect(() => {
		if (activeDocument?.meta?.tradeID) {
			dispatch(
				TradeDocDuc.creators.fetchTimelineData(
					activeDocument.meta.tradeID
				)
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeDocument])

	useEffect(() => {
		if (
			_activeTab === 'traceReport' &&
			locationState &&
			locationState.prev &&
			locationState.prev.pathname &&
			(locationState.prev.pathname.includes('weighbridge-slip') ||
				((actor.includes('palmoil_trader') ||
					actor.includes('palmoil_manufacturer')) &&
					locationState.kind === 'redirect'))
		) {
			if (!traceReportData?.traceID && stopCount < 8) {
				dispatch(AppDuc.creators.showGlobalLoader('document-view'))
				setTimeout(() => {
					dispatch(
						TradeDocDuc.creators.initiateDocumentView(
							payload.rootModule,
							payload.documentReference,
							locationState,
							true
						)
					)
					setStopCount(stopCount + 1)
				}, 1000)
			} else {
				dispatch(AppDuc.creators.hideGlobalLoader('document-view'))
			}
		}
		setActiveTab(_activeTab)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_activeTab, stopCount])

	useEffect(() => {
		let hasWeighbridgeAttachment = false
		if (attachmentDoc && activeDocument.id) {
			hasWeighbridgeAttachment = weighBridgeTickets.length > 0
			if (
				getIn(activeDocument, ['meta', 'incomingVirtualDO']) &&
				!hasWeighbridgeAttachment
			) {
				setShowWeighbridgeModal(true)
			} else {
				setShowWeighbridgeModal(false)
			}
		}
	}, [activeDocument, attachmentDoc, weighBridgeTickets.length])

	useEffect(() => {
		return () => {
			dispatch(AppDuc.creators.hideGlobalLoader('document-view'))
		}
	}, [dispatch])

	const toBeShippedTo = useSelector(state =>
		AuthDuc.selectors.getPartnerOrganizationByID(
			state,
			activeDocument?.meta?.productFor
		)
	)

	return (
		<>
			<Modal
				closeable={false}
				show={showWeighbridgeModal}
				body={<div>{t('tdmDetailsEntry.wantWeighBridgeSlip')}</div>}
				secondaryButtonLabel={t('common.no')}
				confirmlabel={t('common.yes')}
				onConfirm={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.WEIGHBRIDGE$WACTION,
							{
								action: 'create',
							},
							{
								parentDocRef: activeDocument.id,
							}
						)
					)
					setShowWeighbridgeModal(false)
				}}
				secondaryButtonClick={() => {
					const formValues = {
						checkedBy: fullName,
						grossWeight: '',
						netWeight: getIn(activeDocument, [
							'products',
							0,
							'quantity',
						]),
						tareWeight: '',
						ticketNumber: '',
						productID: getIn(activeDocument, ['products', 0, 'id']),
						uom: getIn(activeDocument, ['products', 0, 'uom']),
						entityID: activeDocument.id,
						file: {},
					}
					dispatch(
						TradeDocDuc.creators.createWBSlip(
							formValues,
							getCurrentOrgID === activeDocument.receivingPartyID,
							actor
						)
					)
					setShowWeighbridgeModal(false)
				}}
			/>
			<Modal
				forceCloseviaButton
				show={renameModal}
				heading="Rename File!"
				body={
					<Box>
						<Input
							name="uploadedFileName"
							value={
								selectedFile?.file?.meta?.name
									? selectedFile?.file?.meta?.name
									: ''
							}
							onChange={event =>
								setNewFileName(event.target.value)
							}
							required
						/>
					</Box>
				}
				closelabel={t('common.back')}
				confirmlabel="Rename"
				onClose={() => {
					setRenameModal(false)
				}}
				onConfirm={() => {
					setRenameModal(false)
					editFileName()
				}}
			/>
			<Modal
				forceCloseviaButton
				size="large"
				show={showFileUploadModal}
				heading={
					currentAttachmentType === 'bill-of-lading'
						? `${t('breadcrumb.attach')} ${t(
								'tdmDetailsEntry.billOfLading'
						  )}`
						: t('common.import')
				}
				body={
					<>
						{currentAttachmentType === 'bill-of-lading' &&
						billOfLadingAttachments &&
						billOfLadingAttachments.length > 0 ? (
							<div>
								{t(
									'tdmPreviewDetails.youHaveAlreadyAttachedOneBillOfLading'
								)}
							</div>
						) : (
							<FileUpload
								type="private"
								name="file"
								size="5242880"
								label="Upload Attachment"
								required
								supportedFileType=".jpg,.png,.pdf"
								outline
								modalTriggerBtnLabel={t('common.upload')}
								currentUploads={uploadedFiles}
								onDocumentChange={async uploadedDoc => {
									setUploadedFiles(uploadedDoc)
								}}
								disableUpload={
									currentAttachmentType === 'bill-of-lading'
								}
								onUploadFailure={({ message }) => {
									dispatch(
										AppDuc.creators.showToast({
											messageType: 'error',
											message,
										})
									)
								}}
							/>
						)}
					</>
				}
				confirmlabel={t('common.proceed')}
				isDisabled={uploadedFiles.length === 0}
				closelabel={t('common.close')}
				onClose={() => {
					setUploadedFiles([])
					setShowFileUploadModal(false)
				}}
				onConfirm={() => {
					setShowFileUploadModal(false)
					addAttachmentToRFQ()
				}}
			/>
			<Box
				width="100%"
				height="100%"
				style={{ marginLeft: isIframeForTeamsApp() ? '20px' : 'auto' }}
			>
				{isLoading && <TransparentBgSpinner />}
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>

				<Box
					row
					justifyContent="space-between"
					alignItems="baseline"
					style={{
						padding: '10px 0 15px',
					}}
				>
					<HeaderWrapper>
						<SubWrapper
							onClick={() =>
								hasInitiatedByCurrentOrg === true
									? dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.TRADE_DOCUMENT_MANAGER,
												{
													rootModule: 'outgoing',
												}
											)
									  )
									: dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.TRADE_DOCUMENT_MANAGER,
												{
													rootModule: 'incoming',
												}
											)
									  )
							}
						>
							<IconWrapper
								size={24}
								style={{
									color: theme.color.blue8,
									cursor: 'pointer',
								}}
							>
								<Icon glyph={backArrow} />
							</IconWrapper>
							<SubText>{t('common.back')}</SubText>
						</SubWrapper>

						<Header>{getText('main-header')}</Header>
					</HeaderWrapper>
				</Box>

				<Box
					row
					justifyContent="flex-end"
					alignItems="baseline"
					wrap
					style={{ paddingRight: isMobile ? '10px' : '0px' }}
				>
					{/** <Title
						title={`${t(title)}`}
						icon={TitleIcon}
						note={
							getIn(activeDocument, [
								'meta',
								'entityReference',
							]) || getIn(activeDocument, ['docNumber'])
						}
						noteFontSize={theme.fontSize.l}
					/> */}
					<Box row overflow>
						{
							<>
								{isDOEditable &&
									hasInitiatedByCurrentOrg &&
									!selectedCPID && (
										<>
											<ButtonWithNoBorder
												label={t('common.edit')}
												action
												rounded
												customIcon={
													<ButtonIconWrapper lightBG>
														<Icon
															glyph={EditIcon}
														/>
													</ButtonIconWrapper>
												}
												extendStyles={{
													display:
														activeDocument.docStatus ===
															'complete' ||
														activeDocument.docStatus ===
															'accepted' ||
														activeDocument.docStatus ===
															'rejected' ||
														activeDocument.docStatus ===
															'delivered' ||
														activeDocument.docStatus ===
															'hold' ||
														activeDocument.docStatus ===
															'intransit'
															? 'none'
															: '',
												}}
												onClick={() => {
													dispatch(
														MainRouteDuc.creators.switchPage(
															MainRouteDuc.types
																.TRADE_DOCUMENT_MANAGER$STATUSDOCREFERENCE,
															{
																rootModule,
																action:
																	'attachDetails',
																documentReference: getIn(
																	activeDocument,
																	['id']
																),
																documentStatus:
																	'clone',
															}
														)
													)
												}}
											/>
										</>
									)}
								<Spacer size={30} horizontal />
							</>
						}
						{rootModule === 'purchase-order' &&
							!hasInitiatedByCurrentOrg &&
							!selectedCPID && (
								<>
									<Box
										onClick={() =>
											setShowCreateTradeDoc(
												!showCreateTradeDoc
											)
										}
										style={{
											cursor: 'pointer',
											background: '#3F56C4',
											width: '40px',
											height: '40px',
											boxShadow: '2px 8px 16px #2428453D',
											borderRadius: '6px',
											color: '#3F56C4',
											margin: '0px 20px',
										}}
										ref={purchaseOrderRef}
										center
									>
										<IconWrapper
											size={25}
											// ref={ref}
											style={{ color: theme.color.white }}
										>
											<Icon glyph={PlusIcon} />
										</IconWrapper>
									</Box>
									{forTodo && ( // remove the old create document button
										<ButtonWithDrop
											items={SubDocuments}
											label={t(
												'tdmViewDocument.createTradeDocument'
											)}
											noBackground
											disabled={
												!(
													activeDocument.docStatus ===
														'complete' ||
													activeDocument.docStatus ===
														'accepted'
												)
											}
											onChange={type => {
												if (
													type === 'file-attachment'
												) {
													setShowFileUploadModal(true)
												} else {
													dispatch(
														MainRouteDuc.creators.switchPage(
															MainRouteDuc.types
																.TRADE_DOCUMENT_MANAGER$WACTION,
															{
																rootModule: type,
																action:
																	'details',
															},
															{
																parentDocRef: `${rootModule}~${activeDocument.id}`,
															}
														)
													)
												}
											}}
										/>
									)}
								</>
							)}
						{showCreateTradeDoc && (
							<Box>
								<Drop
									target={purchaseOrderRef.current}
									align={{
										top: 'bottom',
										right: 'right',
									}}
									onEsc={() => setShowCreateTradeDoc(false)}
									onClickOutside={() =>
										setShowCreateTradeDoc(false)
									}
								>
									<Box
										alignItems="flex-start"
										padding="10px 10px"
										style={{
											width: '300px',
											borderBottom: `1px solid ${theme.color.grey4}`,
											cursor: 'pointer',
										}}
										row
										onClick={() => {
											setShowCreateTradeDoc(false)
											dispatch(
												MainRouteDuc.creators.switchPage(
													MainRouteDuc.types
														.TRADE_DOCUMENT_MANAGER$WACTION,
													{
														rootModule: 'invoice',
														action: 'attachDetails',
													},
													{
														parentDocRef: `invoice~${activeDocument.id}`,
													}
												)
											)
										}}
									>
										<Box
											padding="10px 10px"
											style={{
												background: theme.color.blue0,
											}}
										>
											<IconWrapper size={25}>
												<Icon
													color={theme.color.primary}
													glyph={InvoiceIcon}
												/>
											</IconWrapper>
										</Box>
										<Box flex overflow padding="0px 20px">
											<H4 color={theme.color.primary}>
												{t(
													'tdmColumnHeaders.createAnInvoice'
												)}
											</H4>
											<P color={theme.color.grey15}>
												{t(
													'tdmColumnHeaders.POSubText'
												)}
											</P>
										</Box>
									</Box>

									<Box
										alignItems="flex-start"
										padding="10px 10px"
										row
										onClick={() => {
											setShowCreateTradeDoc(false)
											dispatch(
												MainRouteDuc.creators.switchPage(
													MainRouteDuc.types
														.TRADE_DOCUMENT_MANAGER$WACTION,
													{
														rootModule:
															'delivery-order',
														action: 'attachDetails',
													},
													{
														parentDocRef: `delivery-order~${activeDocument.id}`,
													}
												)
											)
										}}
										style={{
											width: '300px',
											cursor: 'pointer',
											borderBottom: `1px solid ${theme.color.grey4}`,
										}}
									>
										<Box
											padding="10px 10px"
											style={{
												background: '#fff3e5',
											}}
										>
											<IconWrapper size={25}>
												<Icon
													color="#e99f52"
													glyph={DOIcon}
												/>
											</IconWrapper>
										</Box>
										<Box flex overflow padding="0px 20px">
											<H4 color="#e99f52">
												{t(
													'tdmColumnHeaders.createDeliveryOrder'
												)}
											</H4>
											<P color={theme.color.grey15}>
												{t(
													'tdmColumnHeaders.DOSubtext'
												)}
											</P>
										</Box>
									</Box>

									<Box
										alignItems="flex-start"
										padding="10px 10px"
										row
										onClick={() => {
											setShowCreateTradeDoc(false)
											setShowFileUploadModal(true)
										}}
										style={{
											width: '300px',
											cursor: 'pointer',
										}}
									>
										<Box
											padding="10px 10px"
											style={{
												background: '#fff3e5',
											}}
										>
											<IconWrapper size={25}>
												<Icon
													color="#e99f52"
													glyph={ChatIcon}
												/>
											</IconWrapper>
										</Box>
										<Box flex overflow padding="0px 20px">
											<H4 color="#e99f52">
												{t(
													'tdmColumnHeaders.attachAnotherFile'
												)}
											</H4>
											<P color={theme.color.grey15}>
												{t(
													'tdmColumnHeaders.attachSubText'
												)}
											</P>
										</Box>
									</Box>
								</Drop>
							</Box>
						)}
						{rootModule === 'invoice' &&
							hasInitiatedByCurrentOrg &&
							!selectedCPID && (
								<>
									<Box
										onClick={() =>
											setShowCreateTradeDoc(
												!showCreateTradeDoc
											)
										}
										style={{
											cursor: 'pointer',
											background: '#3F56C4',
											width: '40px',
											height: '40px',
											boxShadow: '2px 8px 16px #2428453D',
											borderRadius: '6px',
											color: '#3F56C4',
											margin: '0px 20px',
										}}
										ref={invoiceRef}
										center
									>
										<IconWrapper
											size={25}
											// ref={ref}
											style={{ color: theme.color.white }}
										>
											<Icon glyph={PlusIcon} />
										</IconWrapper>
									</Box>
									{forTodo && ( // remove the old create trade doc button
										<ButtonWithDrop
											items={[invoiceSubDOc]}
											label={t(
												'tdmViewDocument.createTradeDocument'
											)}
											noBackground
											onChange={type => {
												if (
													type === 'file-attachment'
												) {
													setShowFileUploadModal(true)
												} else {
													dispatch(
														MainRouteDuc.creators.switchPage(
															MainRouteDuc.types
																.TRADE_DOCUMENT_MANAGER$WACTION,
															{
																rootModule: type,
																action:
																	'details',
															},
															{
																parentDocRef: `${rootModule}~${activeDocument.id}`,
															}
														)
													)
												}
											}}
										/>
									)}
								</>
							)}
						{showCreateTradeDoc && (
							<Box>
								<Drop
									target={invoiceRef.current}
									align={{
										top: 'bottom',
										right: 'right',
									}}
									onEsc={() => setShowCreateTradeDoc(false)}
									onClickOutside={() =>
										setShowCreateTradeDoc(false)
									}
								>
									<Box
										alignItems="flex-start"
										padding="10px 10px"
										row
										onClick={() => {
											setShowCreateTradeDoc(false)
											dispatch(
												MainRouteDuc.creators.switchPage(
													MainRouteDuc.types
														.TRADE_DOCUMENT_MANAGER$WACTION,
													{
														rootModule:
															'delivery-order',
														action: 'attachDetails',
													},
													{
														parentDocRef: `delivery-order~${activeDocument.id}`,
													}
												)
											)
										}}
										style={{
											width: '300px',
											cursor: 'pointer',
											borderBottom: `1px solid ${theme.color.grey4}`,
										}}
									>
										<Box
											padding="10px 10px"
											style={{
												background: '#fff3e5',
											}}
										>
											<IconWrapper size={25}>
												<Icon
													color="#e99f52"
													glyph={DOIcon}
												/>
											</IconWrapper>
										</Box>
										<Box flex overflow padding="0px 20px">
											<H4 color="#e99f52">
												{t(
													'tdmColumnHeaders.createDeliveryOrder'
												)}
											</H4>
											<P color={theme.color.grey15}>
												{t(
													'tdmColumnHeaders.InvSubtext'
												)}
											</P>
										</Box>
									</Box>
								</Drop>
							</Box>
						)}
						{rootModule === 'delivery-order' && !selectedCPID && (
							<Box id="DOAddSubDoc">
								<ButtonWithDrop
									items={getSubDocuments()}
									label={t('tdmViewDocument.addSubDocument')}
									noBackground
									disabled={
										activeDocument.docStatus ===
											'rejected' ||
										activeDocument.docStatus === 'hold'
									}
									onChange={name => {
										if (
											name === 'file-attachment' ||
											name === 'bill-of-lading'
										) {
											if (name === 'bill-of-lading') {
												setCurrentAttachmentType(
													'bill-of-lading'
												)
											} else {
												setCurrentAttachmentType(null)
											}
											setShowFileUploadModal(true)
										} else {
											dispatch(
												name === 'weighbridge-slip'
													? MainRouteDuc.creators.switchPage(
															MainRouteDuc.types
																.WEIGHBRIDGE$WACTION,
															{
																action:
																	'create',
															},
															{
																parentDocRef:
																	activeDocument.id,
															}
													  )
													: MainRouteDuc.creators.switchPage(
															MainRouteDuc.types
																.MILL$WACTION,
															{
																rootModule: name,
																action:
																	'create',
															},
															{
																parentDocRef:
																	activeDocument.id,
															}
													  )
											)
											dispatch(
												TradeDocDuc.creators.setActiveTab(
													'original'
												)
											)
										}
									}}
								/>
							</Box>
						)}
						{!activeDocument?.meta?.enableGlobalPartner &&
							!isIframeForTeamsApp() && (
								<Box
									style={{
										position: 'fixed',
										display: 'grid',
										zIndex: 1,
										bottom: 0,
										right: showChatBox
											? 8
											: getMeasure(75, 90),
									}}
								>
									<ChatModalComponent
										documentId={
											getIn(activeDocument, [
												'meta',
												'baseEntityID',
											])
												? getIn(activeDocument, [
														'meta',
														'baseEntityID',
												  ])
												: getIn(activeDocument, ['id'])
										}
										sellerName={
											rootModule === 'purchase-order'
												? receivingOrgName
												: initiatingOrgName
										}
										buyerName={
											rootModule === 'purchase-order'
												? initiatingOrgName
												: receivingOrgName
										}
										buyerId={
											rootModule === 'purchase-order'
												? getIn(activeDocument, [
														'initiatingPartyID',
												  ])
												: getIn(activeDocument, [
														'receivingPartyID',
												  ])
										}
										sellerId={
											rootModule === 'purchase-order'
												? getIn(activeDocument, [
														'receivingPartyID',
												  ])
												: getIn(activeDocument, [
														'initiatingPartyID',
												  ])
										}
										tradeId={getIn(activeDocument, [
											'meta',
											'tradeID',
										])}
										docType={rootModule}
										chatBoxData={data => chatBoxData(data)}
										isMobile={isMobile}
									/>
								</Box>
							)}
					</Box>
				</Box>
				<Spacer size={8} />

				<Box>
					<Box
						alignItems="flex-end"
						style={{
							paddingLeft: isMobile ? '10px' : '0px',
							paddingRight: isMobile ? '10px' : '0px',
						}}
					>
						<Note
							noteColor={theme.color.error}
							fontSize={theme.fontSize.l}
						>
							{t(note)}
						</Note>
					</Box>
					<Spacer size={4} />
					<div
						style={{
							paddingLeft: isMobile ? '10px' : '0px',
							paddingRight: isMobile ? '10px' : '0px',
						}}
					>
						<DocumentLeadCard
							rootModule={rootModule}
							isDocumentOwner={hasInitiatedByCurrentOrg}
							partner={
								hasInitiatedByCurrentOrg
									? receivingOrgName
									: initiatingOrgName
							}
							// patch this to proper key once backend gives amount.
							amount={getIn(activeDocument, ['meta', 'amount'])}
							sentDate={activeDocument.createdAt || '---'}
							actionedDate={activeDocument.updatedAt || '---'}
							docStatus={activeDocument.docStatus}
							netWeight={netWeight}
							messagesCount={remarkCount}
							onMessagesLinkClick={() => {
								handleMessagesScroll()
							}}
							onActionChange={newStatus => {
								setTargetActiveStatus(newStatus)
								setShowRemarkModal(true)
							}}
						/>
					</div>
				</Box>
				<Box center padding="20px 0px">
					<TabButtons
						fontSize={theme.fontSize.xl}
						list={activeTabs}
						key={activeTab}
						id="DOAttachments"
						activeIndex={VIEW_TAB_CONFIG.findIndex(
							q => q.name === activeTab
						)}
						onChange={({ name }) => {
							setActiveTab(name)
							dispatch(TradeDocDuc.creators.setActiveTab(name))
						}}
						borderRadius={10}
						isMobile={isMobile}
					/>
				</Box>
				{isOriginalTabActive && (
					<DocumentView
						rootModule={rootModule}
						partner={
							hasInitiatedByCurrentOrg
								? receivingPartyDetails
								: initiatingPartyDetails
						}
						brokerEmailID={
							getIn(activeDocument, ['meta', 'brokerEmailID']) ||
							''
						}
						supplyChainModel={t(
							`tdmDetailsEntry.${getIn(activeDocument, [
								'meta',
								'supplyChainModel',
								'id',
							])}`
						)}
						receiverEmailID={getReceiverEmail() || ''}
						associatedPORef={
							getAssociatedRef('purchase-order') || ''
						}
						associatedDORef={
							getAssociatedRef('delivery-order') || ''
						}
						associatedInvoiceRef={getAssociatedRef('invoice') || ''}
						productDetails={
							getIn(activeDocument, ['products']) || []
						}
						receiverTraceIDs={getIn(activeDocument, [
							'receiverTraceIDs',
						])}
						tankDetails={
							getIn(activeDocument, ['tankDetails']) || []
						}
						user={
							getIn(activeDocument, [
								'meta',
								'authorization',
								'reportedBy',
							]) || ''
						}
						role={
							getIn(activeDocument, [
								'meta',
								'authorization',
								'reportedByRole',
							]) || ''
						}
						issueDate={
							getIn(activeDocument, ['meta', 'issueDate']) || ''
						}
						attachedFiles={attachfile || {}}
						status={
							getIn(activeDocument, ['docStatus'])
								? getIn(activeDocument, [
										'docStatus',
								  ]).toUpperCase() || ''
								: ''
						}
						documentNumber={
							getIn(activeDocument, ['docNumber']) || ''
						}
						activeDocument={activeDocument}
						isMobile={isMobile}
						actor={actor}
						hasInitiatedByCurrentOrg={hasInitiatedByCurrentOrg}
						toBeShippedTo={toBeShippedTo}
					/>
				)}
				{isTimelineTabActive && (
					<div
						style={{
							zIndex: 0,
							borderRadius: 10,
							padding: isMobile
								? '30px 7px 100px'
								: '30px 50px 100px',
							background: theme.color.white,
							marginLeft: isMobile ? '10px' : '0px',
							marginRight: isMobile ? '10px' : '0px',
							border: `1px solid ${theme.color.grey4}`,
						}}
					>
						<Timeline
							title={t('tdmViewDocument.historyOfEvents')}
							data={getTimelineDataArray()}
							isMobile={isMobile}
						/>
					</div>
				)}
				<br />
				{isHistoryTabActive && (
					<>
						<BoxShadowWrapper
							style={{
								background: theme.color.white,
								padding: isMobile ? '10px' : '16px',
							}}
						>
							<CollapsibleDocStacks
								documents={documentTrace}
								activeDocumentKey={activeDocument.id}
							/>
						</BoxShadowWrapper>
						<Spacer size={24} />
						<MessagesBlock messages={messages} readOnly />
					</>
				)}
				{billOfLadingAttachments &&
				billOfLadingAttachments.length > 0 &&
				isAttachmentTabActive ? (
					<>
						<div
							style={{
								fontSize: 20,
								marginLeft: 30,
								marginBottom: 8,
								fontWeight: 500,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tdmPreviewDetails.billOfLading')}
						</div>
						<Box row>
							{isAttachmentTabActive &&
								billOfLadingAttachments.length > 0 &&
								getAttachedFiles(billOfLadingAttachments)}
						</Box>
					</>
				) : (
					''
				)}
				{isAttachmentTabActive && otherAttachments.length > 0 ? (
					<>
						<div
							style={{
								fontSize: 20,
								marginLeft: 30,
								marginBottom: 8,
								fontWeight: 500,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tdmPreviewDetails.attachments')}
						</div>
						<Box row style={{ flexWrap: 'wrap' }}>
							{isAttachmentTabActive &&
								otherAttachments.length > 0 &&
								getAttachedFiles(otherAttachments)}
						</Box>
					</>
				) : (
					''
				)}
				<Box row>
					{isTraceReportTabActive ? traceReportContent() : ''}
				</Box>

				{isAttachmentTabActive &&
					attachmentDoc.length > 0 &&
					weighBridgeTickets.map((document, index) => (
						<Box key={document.id}>
							<Box overflow>
								<WeighBridgeTicketCard
									actor={actor}
									key={`${document.title}-${document.category}`}
									title={`${t(
										`${document.title}`
									)} - ${index + 1}`}
									additionalMeta={document.ticketInfo}
									isMobile={isMobile}
									activeDocument={activeDocument}
									allWeighBridgeData={weighBridgeTickets}
									initiatingPartyDetails={
										initiatingPartyDetails
									}
									receivingPartyDetails={
										receivingPartyDetails
									}
								/>
							</Box>
							<Spacer size={16} />
						</Box>
					))}
				{isAttachmentTabActive &&
					attachmentDoc.length > 0 &&
					ffbQualityReports.map((document, index) => (
						<Box key={`${document.title}-${document.category}`}>
							<Box overflow>
								<FFBQualityReportCard
									title={`${t(
										`${document.title}`
									)} - ${index + 1}`}
									additionalMeta={document.ffbQualityInfo}
									isMobile={isMobile}
								/>
							</Box>
							<Spacer size={16} />
						</Box>
					))}
				{isAttachmentTabActive &&
					attachmentDoc.length > 0 &&
					cpoQualityReports.map((document, index) => (
						<Box key={`${document.title}-${document.category}`}>
							<Box overflow>
								<CPOQualityReportCard
									title={`${t(
										`${document.title}`
									)} - ${index + 1}`}
									additionalMeta={document.cpoQualityInfo}
									isMobile={isMobile}
								/>
							</Box>
							<Spacer size={16} />
						</Box>
					))}
				{isAttachmentTabActive &&
					attachmentDoc.length === 0 &&
					entityFiles.length === 0 && (
						<ErrorBlock
							hideButton
							small
							status="empty"
							message={t('tdmViewDocument.noAttachmentFound')}
						/>
					)}
				{isOriginalTabActive && (
					<>
						{!getIn(activeDocument, ['meta', 'attachmentFlow']) && (
							<div ref={messagesBlockRef} id="TDMmessages">
								<MessagesBlock
									messages={messages}
									onMessageSend={handleSubmit}
								/>
							</div>
						)}
					</>
				)}

				<RemarksConfirmation
					showModal={showRemarkModal}
					handleOnConfirm={remarks => {
						setShowRemarkModal(false)
						handleOnChange('finalize_remark_status', { remarks })
					}}
					currentStatus={targetActiveStatus}
					handleOnSkip={() => {
						setShowRemarkModal(false)
						handleOnChange('finalize_remark_status')
					}}
					handleOnClose={() => {
						setShowRemarkModal(false)
					}}
				/>
			</Box>
			{(tourModule === 'tdmDOView' ||
				tourModule === 'tdmPOView' ||
				tourModule === 'tdmInvoiceView') && <WebTour />}
		</>
	)
}

export { ViewDocument }
