import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { merge, getIn, omit, setIn } from 'timm'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import Incoming from 'ui-lib/icons/incoming.svg'
import Outgoing from 'ui-lib/icons/outgoing.svg'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { Paginator } from 'ui-lib/components/Paginator'
import { Modal } from 'ui-lib/components/Modal'
import { Label, P } from 'ui-lib/components/Typography'
import { getStartIndex, getEndIndex } from 'ui-lib/components/Paginator/utils'
import { TabButtons } from 'ui-lib/components/TabButtons'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { useTranslation } from 'react-i18next'
import { isIframeForTeamsApp } from 'ui-lib/utils/helpers'
import theme from 'ui-lib/utils/base-theme'
import {
	ButtonIconWrapper,
	Button,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import { Icon } from 'ui-lib/icons/components/Icon'

import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { TradeDocDuc } from '../duc'
import { NAME_ALIASES, LabelsMap } from '../config'
import { Title } from '../components/Title'
import { BoxShadowTable } from '../components/BoxShadowTable'
import { initiateSort, getTargetFilterQueries } from '../helpers'

import { getColumnConfig, excelUploadColumnConfig } from '../components/Columns'
import { RemarksConfirmation } from '../components/RemarksConfirmation'

const getBreadCrumbsList = (rootModule, subModule, t) => [
	{
		label: t('breadcrumb.home'),
		name: 'home',
		isActive: true,
	},

	{
		label: t('breadcrumb.tradeDocManager'),
		name: 'trade-doc-dashboard',
		isActive: true,
	},
	{
		label: `${t(rootModule)} ${t(subModule)}`,
		name: 'submodule-dashboard',
		isActive: false,
	},
]

const PaginatorBlock = React.memo(
	({ paginationConfig, onChange, documents, startDesc, toDesc, ofDesc }) => (
		<Box padding={8}>
			<Paginator
				onClick={direction => onChange('paginate', { direction })}
				startIndex={getStartIndex(
					paginationConfig.activeIndex,
					paginationConfig.limit
				)}
				endIndex={getEndIndex(
					paginationConfig.activeIndex,
					paginationConfig.limit,
					paginationConfig.total
				)}
				startDesc={startDesc}
				toDesc={toDesc}
				ofDesc={ofDesc}
				totalCount={paginationConfig.total}
				hidePageResults={(documents || []).length === 0}
			/>
		</Box>
	)
)

const DocumentsListing = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	const location = useSelector(TradeDocDuc.selectors.location)
	const actorArray = useSelector(AuthDuc.selectors.getActor)
	const actor = actorArray[0] || ''
	const documents = useSelector(TradeDocDuc.selectors.getListingDocuments)
	const orgDetails = useSelector(AuthDuc.selectors.getAvailableOrgs)
	const isLoading = useSelector(TradeDocDuc.selectors.getListingLoadingStatus)
	const getCurrentOrgID = useSelector(AuthDuc.selectors.getUserOrgId)
	const activeFilters = useSelector(
		TradeDocDuc.selectors.getListingActiveFilters
	)
	const uploadDOAcceptedList = useSelector(
		TradeDocDuc.selectors.getUploadDOAcceptedList
	)
	const uploadDODraftAcceptedList = useSelector(
		TradeDocDuc.selectors.getUploadDODraftAcceptedList
	)
	const [showAcceptAllDODraftModal, setShowAcceptAllDODraftModal] = useState(
		false
	)
	const [showDuplicateModal, setShowDuplicateModal] = useState(false)
	const partnerList = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)
	const globalOrgs = useSelector(AuthDuc.selectors.getGlobalOrgs)
	const activeDateFilters = getIn(activeFilters, ['createdAt']) || {}
	let activeStartDate = getIn(activeDateFilters, ['gte', 0]) || null
	if (activeStartDate) activeStartDate = new Date(activeStartDate)
	let activeEndDate = getIn(activeDateFilters, ['lte', 0]) || null
	if (activeEndDate) activeEndDate = new Date(activeEndDate)
	const activeStatus = useSelector(TradeDocDuc.selectors.getListingActiveTab)
	const [startDate, setStartDate] = useState(activeStartDate)
	const [endDate, setEndDate] = useState(activeEndDate || new Date())
	const [showRemarkModal, setShowRemarkModal] = useState(false)
	const [activeRecord, setActiveRecord] = useState({})
	const [_activeStatus, setActiveStatus] = useState('any')
	const [showLogOutModal, setShowLogOutModal] = useState(false)
	const activeSorts = useSelector(
		TradeDocDuc.selectors.getDocumentListingActiveSorts
	)
	const [currentActiveDocuments, setCurrentActiveDocuments] = useState([])
	const [fullOrgList, setFullOrgList] = useState([])
	const acceptAllDraftButtonLoading = useSelector(
		TradeDocDuc.selectors.getDODraftAcceptedLoading
	)

	const acceptAllButtonLoading = useSelector(
		TradeDocDuc.selectors.getDOAcceptedLoading
	)
	const paginationConfig = useSelector(
		TradeDocDuc.selectors.getListingPaginationEntries
	)
	const allProducts = useSelector(AuthDuc.selectors.getProducts)

	const { payload = {} } = location
	const { rootModule, submodule: subModule } = payload
	const IconRef = rootModule === 'incoming' ? Incoming : Outgoing

	const title = LabelsMap[rootModule]
	const subModuleTitle = NAME_ALIASES[subModule]

	const breadCrumbs = useMemo(
		() => getBreadCrumbsList(title, subModuleTitle, t),
		[title, subModuleTitle, t]
	)

	const stats = useSelector(
		rootModule === 'incoming'
			? TradeDocDuc.selectors.getListingIncomingStats
			: TradeDocDuc.selectors.getListingOutgoingStats
	)

	const keys = Object.keys(stats)
	const FILTERS_STATUS_CONFIG = [
		{
			name: 'any',
			label: t('tdmFilterConfig.any'),
		},
		{
			name: 'submitted',
			label: t('tdmFilterConfig.pending'),
		},
		{
			name: 'accepted',
			label: t('tdmFilterConfig.accepted'),
		},
		{
			name: 'delivered',
			label: t('tdmFilterConfig.delivered'),
		},
		{
			name: 'acknowledged',
			label: t('tdmFilterConfig.acknowledged'),
		},
		{
			name: 'hold',
			label: t('tdmFilterConfig.on-hold'),
		},
		{
			name: 'rejected',
			label: t('tdmFilterConfig.rejected'),
		},
	]

	const index = keys.indexOf(subModule)
	const statusesList = stats[keys[index]] || {}

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}

		if (target === 'trade-doc-dashboard') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
					{ rootModule }
				)
			)
		}
	}

	const handleChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'initiate_sort': {
					initiateSort(
						dispatch,
						meta,
						TradeDocDuc.creators.fetchDocumentListing,
						location,
						'sort'
					)
					break
				}
				case 'apply_search': {
					const { searchName, searchValue, docType, type } = meta

					const currentQuery = getIn(location, ['query']) || {}

					const targetQuery = merge(currentQuery, {
						q: searchName
							? `${searchName}->${searchValue}`
							: searchValue,
					})

					dispatch(
						TradeDocDuc.creators.fetchDocumentListing(
							docType,
							[type],
							setIn(location, ['query'], targetQuery),
							true
						)
					)

					break
				}

				case 'remove_filter': {
					setStartDate()
					setEndDate(new Date())
					const { docType, type, filterType } = meta

					const currentQuery = getIn(location, ['query']) || {}
					const filterQueries = omit(currentQuery, ['sort', 'q'])
					let targetQueries = getIn(filterQueries, ['status']) || []

					const segments = Array.isArray(filterType)
						? filterType
						: [filterType]

					const _targetFilters = omit(currentQuery, segments)

					targetQueries = getTargetFilterQueries(
						'',
						'',
						_activeStatus,
						'state->'
					)
					const targetFilters = merge(_targetFilters, {
						status: targetQueries,
					})

					dispatch(
						TradeDocDuc.creators.fetchDocumentListing(
							docType,
							[type],
							setIn(location, ['query'], targetFilters),
							true
						)
					)

					break
				}

				case 'apply_filter': {
					const {
						docType,
						type,
						filterType, // the root filter key e.g status
						filterSegment, // optional when you need to clear multiple
						filterValue,
						prefix,
					} = meta

					const _currentQuery = getIn(location, ['query']) || {}

					const currentQuery = {
						..._currentQuery,
						activeIndex: 0,
					}
					const filterQueries = omit(currentQuery, ['sort', 'q']) // exclude sort and search convention
					let targetQueries = getIn(filterQueries, [filterType]) || []
					// if there are multiple segments being invoked as part of one filter, map them one to one via index
					if (
						Array.isArray(filterSegment) &&
						Array.isArray(filterValue)
					) {
						targetQueries = filterSegment.reduce(
							(agg, _filterSegment, _index) => {
								const _filterValue = filterValue[_index]

								if (!_filterValue) return agg

								return getTargetFilterQueries(
									agg,
									_filterSegment,
									_filterValue,
									prefix
								)
							},
							[]
						)
					} else {
						targetQueries = getTargetFilterQueries(
							targetQueries,
							filterSegment,
							filterValue,
							prefix
						)
					}

					const targetFilters = merge(
						_currentQuery,
						currentQuery,
						merge(filterQueries, { [filterType]: targetQueries })
					)
					dispatch(
						TradeDocDuc.creators.fetchDocumentListing(
							docType,
							[type],
							setIn(location, ['query'], targetFilters),
							true
						)
					)

					break
				}

				case 'handle_accept_checkbox': {
					const { checkboxData, entity } = meta || {}

					dispatch(
						TradeDocDuc.creators.setUploadDOAcceptedList(
							entity,
							checkboxData
						)
					)

					break
				}

				case 'finalize_remark_status': {
					const { remarks } = meta

					const documentStatusPropsList = {
						docRefId: activeRecord.id,
						docType: activeRecord.type,
						newStatus: activeRecord.newStatus,
						remarks,
						successMsg: t('common.statusUpdateSuccess'),
						errorMsg: t('common.statusUpdateError'),
						postChange: () => {
							// retrigger the current route so new data is fetched again.
							dispatch(
								MainRouteDuc.creators.redirect(
									location.type,
									location.payload,
									location.query
								)
							)
						},
					}

					// get the active record
					dispatch(
						TradeDocDuc.creators.changeDocumentStatus(
							documentStatusPropsList
						)
					)

					break
				}

				case 'change_status': {
					const { type, docType, row, newStatus } = meta || {}

					if (type && newStatus) {
						setActiveRecord({
							type,
							docType,
							document: row,
							newStatus,
						})
						setShowRemarkModal(true)
					}

					break
				}

				case 'paginate': {
					const { direction } = meta || {}

					const {
						activeIndex = 0,
						currentCursor,
						nextCursor,
					} = paginationConfig

					const targetIndex = Math.max(
						direction === 'next'
							? activeIndex + 1
							: activeIndex - 1,
						0
					)

					let nextIndex = null

					if (nextCursor && activeIndex > targetIndex) {
						nextIndex = nextCursor
					} else if (currentCursor && activeIndex < targetIndex) {
						nextIndex = currentCursor
					}

					// retrigger the current route so new data is fetched again.
					dispatch(
						MainRouteDuc.creators.redirect(
							location.type,
							location.payload,
							{
								...location.query,
								activeIndex: targetIndex,
								limit: location.query.limit || 5,
								nextIndex,
								status: `state->${activeStatus}`,
							}
						)
					)

					break
				}

				case 'reset_remark_status': {
					setActiveRecord({})

					break
				}
				case 'open_document': {
					if (meta.row) {
						const isDraft = meta.row.status.state === 'draft'
						dispatch(
							MainRouteDuc.creators.switchPage(
								isDraft
									? MainRouteDuc.types
											.TRADE_DOCUMENT_MANAGER$STATUSDOCREFERENCE
									: MainRouteDuc.types
											.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
								{
									rootModule: meta.row.type,
									documentReference: meta.row.id,
									documentStatus: null,
								}
							)
						)
					}

					break
				}
				case 'handle_draft_accept_checkbox': {
					const { checkboxData, entity } = meta || {}

					dispatch(
						TradeDocDuc.creators.setUploadDODraftAcceptedList(
							entity,
							checkboxData
						)
					)

					break
				}

				case 'handle_draft_change': {
					const { data, currentUploadDODraftAcceptedList = [] } =
						meta || {}

					const excelUploads = [...currentActiveDocuments]
					const foundIndex = excelUploads.findIndex(
						x => x.id === data.id
					)
					excelUploads[foundIndex] = data

					if (currentUploadDODraftAcceptedList?.length > 0) {
						const tempArray = [...currentUploadDODraftAcceptedList]

						const foundIndexAccepted = tempArray.findIndex(
							x => x.id === data.id
						)
						tempArray[foundIndexAccepted] = data
						dispatch(
							TradeDocDuc.creators.setUploadDODraftAcceptedList(
								tempArray,
								{},
								true
							)
						)
					}

					setCurrentActiveDocuments(excelUploads)
					break
				}

				case 'create_attachment': {
					const { docType } = meta || {}
					dispatch(
						docType === 'weighbridge-slip'
							? MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.WEIGHBRIDGE$WACTION,
									{
										action: 'create',
									},
									{
										parentDocRef: `delivery-order~${meta.row.id}`,
									}
							  )
							: MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.MILL$WACTION,
									{
										rootModule: docType,
										action: 'create',
									},
									{
										parentDocRef: meta.row.id,
									}
							  )
					)
					break
				}

				default:
					break
			}
		},
		[
			dispatch,
			location,
			currentActiveDocuments,
			activeRecord.id,
			activeRecord.type,
			activeRecord.newStatus,
			paginationConfig,
			_activeStatus,
			activeStatus,
			t,
		]
	)

	const activeLocale = useSelector(AppDuc.selectors.activeLocale)

	useEffect(() => {
		setCurrentActiveDocuments([...documents])
	}, [documents])

	useEffect(() => {
		const currentList = []
		if (partnerList?.length > 0) {
			partnerList.forEach(element => {
				if (!currentList.find(item => item.id === element?.id)) {
					currentList.push({
						id: element?.id,
						label: element?.name,
						value: element?.id,
					})
				}
			})
		}
		if (globalOrgs?.length > 0) {
			globalOrgs.forEach(element => {
				if (!currentList.find(item => item.id === element?.id)) {
					currentList.push({
						id: element?.id,
						label: element?.label,
						value: element?.id,
					})
				}
			})
		}

		setFullOrgList(currentList)
	}, [partnerList, globalOrgs])

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Modal
					closeable
					show={showLogOutModal}
					heading={t('topBar.logout')}
					body={
						<P large bold>
							{t('topBar.logoutMsg')}
						</P>
					}
					closelabel={t('common.no')}
					confirmlabel={t('common.yes')}
					onClose={() => {
						setShowLogOutModal(false)
					}}
					onConfirm={() => {
						dispatch(
							AuthDuc.creators.signOutUser(window.location.href)
						)
					}}
					isMobile={isMobile}
				/>
				<Modal
					closeable
					show={showAcceptAllDODraftModal}
					heading={`${t('common.create')} ${t(
						'tdmTabConfig.delivery-orders'
					)}`}
					body={
						<P large bold>
							{t('tdmDocumentListing.acceptAllDOModalContent')}
						</P>
					}
					closelabel={t('common.no')}
					confirmlabel={t('common.yes')}
					onClose={() => {
						setShowAcceptAllDODraftModal(false)
					}}
					onConfirm={() => {
						dispatch(
							TradeDocDuc.creators.setDODraftAcceptAllLoading(
								true
							)
						)
						const documentPropsList = {
							rootModule: 'excel-uploads',
							document: uploadDODraftAcceptedList,
							currentDocId: '',
							t,
							doSuccessMsg: t('common.doSuccess'),
							fromPlantation: actor.includes(
								'palmoil_plantation'
							),
							actionType: 'submit',
							rootType: rootModule,
							fromDocumentListing: true,
						}
						dispatch(
							TradeDocDuc.creators.initiateDocumentSave(
								documentPropsList
							)
						)
						setShowAcceptAllDODraftModal(false)
					}}
					isMobile={isMobile}
				/>
				<Modal
					show={showDuplicateModal}
					heading={t('tdmDocumentListing.duplicateWarning')}
					body={
						<P large bold>
							{t('tdmDocumentListing.duplicateContent')}
						</P>
					}
					confirmlabel={t('common.close')}
					onConfirm={() => {
						setShowDuplicateModal(false)
					}}
					isMobile={isMobile}
				/>
				{!isIframeForTeamsApp() && (
					<Box style={{ padding: '0 5' }}>
						<Breadcrumb
							links={breadCrumbs}
							onClick={target => handleBreadCrumbClick(target)}
						/>
					</Box>
				)}
				{isIframeForTeamsApp() && (
					<div
						style={{
							justifyContent: isMobile
								? 'center'
								: 'space-between',
							display: isMobile ? 'grid' : 'flex',
						}}
					>
						<Box
							style={{
								width: '100px',
								marginRight: '20px',
								marginBottom: isMobile ? '10px' : '0px',
							}}
						>
							<Button
								label={t('common.back')}
								rounded
								customIcon={
									<ButtonIconWrapper lightBG>
										<Icon glyph={LeftArrowIcon} />
									</ButtonIconWrapper>
								}
								onClick={() =>
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types
												.TRADE_DOCUMENT_MANAGER,
											{ rootModule }
										)
									)
								}
							/>
						</Box>
						<Box
							style={{
								alignItems: 'center',
								marginRight: '20px',
							}}
						>
							<Button
								extendStyles={{
									width: '100px',
									height: '40px',
									borderRadius: '4px',
									fontSize: '16px',
									background: '#2A2364',
									padding: '6px',
									textAlign: 'center',
									textDecoration: 'solid',
								}}
								onClick={() => setShowLogOutModal(true)}
								label={t('topBar.logout')}
								primary
								plain
							/>
						</Box>
					</div>
				)}
				<Box
					row={!isMobile}
					column={isMobile}
					justifyContent={isMobile ? 'start' : 'space-between'}
					alignItems={isMobile ? 'start' : 'center'}
					style={{ paddingBottom: 24 }}
				>
					<Title title={t(title)} icon={IconRef} />
					{subModule !== 'excel-uploads' && (
						<Box row={!isMobile}>
							<Box row>
								<Box
									alignItems="flex-start"
									width={125}
									margin="0px 5px"
								>
									<Label small>
										{t('tdmDocumentListing.from')}
									</Label>
									<div style={{ fontSize: theme.fontSize.s }}>
										<DatePicker
											name="startDate"
											placeholder={t(
												'tdmDocumentListing.choose'
											)}
											hideError
											value={startDate}
											maxDate={endDate}
											onChange={value => {
												setStartDate(value)
											}}
										/>
									</div>
								</Box>
								<Box
									alignItems="flex-start"
									width={125}
									margin="0px 5px"
								>
									<Label small>
										{t('tdmDocumentListing.to')}
									</Label>
									<div style={{ fontSize: theme.fontSize.s }}>
										<DatePicker
											name="toDate"
											placeholder={t(
												'tdmDocumentListing.choose'
											)}
											hideError
											minDate={startDate}
											value={endDate}
											maxDate={new Date()}
											onChange={value => {
												setEndDate(value)
											}}
											toDate
										/>
									</div>
								</Box>
							</Box>
							<Spacer size={10} horizontal={!isMobile} />

							<Box
								alignItems="flex-end"
								width={150}
								margin="0 5px"
								row
							>
								<Button
									label={t('productTrace.apply')}
									type="submit"
									extendStyles={{
										padding: '2px 20px',
									}}
									primary
									rounded
									disabled={!startDate}
									onClick={() => {
										dispatch(
											TradeDocDuc.creators.setListingActiveTab(
												'any'
											)
										)

										handleChange('apply_filter', {
											docType: subModule,
											type: rootModule,
											filterSegment: [
												'end_date',
												'start_date',
											],
											filterType: 'createdAt',
											filterValue: [
												new Date(
													startDate
												).toISOString(),
												new Date(endDate).toISOString(),
											],
										})
									}}
								/>
								<Spacer size={10} horizontal={!isMobile} />

								<ButtonWithNoBorder
									label={t('productTrace.clear')}
									style={{
										fontSize: theme.fontSize.xl,
									}}
									onClick={() => {
										handleChange('remove_filter', {
											docType: subModule,
											type: rootModule,
											filterType: [
												'createdAt',
												'updatedAt',
											],
										})
									}}
								/>
							</Box>
						</Box>
					)}
				</Box>

				<Box
					row={!isMobile}
					justifyContent={isMobile ? 'center' : 'space-between'}
					alignItems="center"
					style={{ paddingBottom: 24 }}
				>
					{subModule !== 'excel-uploads' && (
						<Box alignItems="flex-start">
							<Label small style={{ paddingLeft: 5 }}>
								{t('tdmDocumentListing.status')}
							</Label>

							<TabButtons
								list={FILTERS_STATUS_CONFIG}
								activeIndex={FILTERS_STATUS_CONFIG.findIndex(
									q => q.name === activeStatus
								)}
								statusList={statusesList}
								showStatus
								isLoading={isLoading}
								onChange={({ name }) => {
									setActiveStatus(name)
									dispatch(
										TradeDocDuc.creators.setListingActiveTab(
											name
										)
									)
									handleChange('apply_filter', {
										docType: subModule,
										type: rootModule,
										filterType: 'status',
										filterValue: name,
										prefix: 'state->',
									})
								}}
								isMobile={isMobile}
							/>
						</Box>
					)}
					<Box style={{ marginTop: '17px' }}>
						{((rootModule === 'incoming' &&
							subModule === 'delivery-order') ||
							subModule === 'excel-uploads') &&
							(activeStatus === 'any' ||
								activeStatus === 'submitted' ||
								activeStatus === 'acknowledged') && (
								<Button
									label={
										subModule === 'excel-uploads'
											? `${t('common.create')} ${t(
													'tdmTabConfig.delivery-orders'
											  )}`
											: `${t(
													'tdmDetailsEntry.acceptAllSelected'
											  )} DOs`
									}
									type="submit"
									extendStyles={{
										width: 'auto',
										height: '41px',
										minWidth: '200px',
									}}
									primary
									rounded
									disabled={
										subModule === 'excel-uploads'
											? uploadDODraftAcceptedList.length ===
													0 ||
											  acceptAllDraftButtonLoading
											: uploadDOAcceptedList.length ===
													0 || acceptAllButtonLoading
									}
									onClick={() => {
										if (subModule === 'excel-uploads') {
											const uniqueList = uploadDODraftAcceptedList.filter(
												(obj, index) =>
													uploadDODraftAcceptedList.findIndex(
														item =>
															item.number ===
															obj.number
													) !== index
											)

											if (uniqueList?.length > 0) {
												setShowDuplicateModal(true)
											} else {
												setShowAcceptAllDODraftModal(
													true
												)
											}
										} else {
											dispatch(
												TradeDocDuc.creators.setDOAcceptAllLoading(
													true
												)
											)
											const documentStatusPropsList = {
												docType: 'delivery-order',
												successMsg: t(
													'common.statusUpdateSuccess'
												),
												errorMsg: t(
													'common.statusUpdateError'
												),
												uploadDOAcceptedList,
												fromUploadDoSelect: true,
												postChange: () => {
													// retrigger the current route so new data is fetched again.
													dispatch(
														MainRouteDuc.creators.redirect(
															location.type,
															location.payload,
															{
																...location.query,
																status: `state->${activeStatus}`,
															}
														)
													)
												},
											}
											// get the active record
											dispatch(
												TradeDocDuc.creators.changeDocumentStatus(
													documentStatusPropsList
												)
											)
										}
									}}
								/>
							)}
					</Box>
				</Box>
				<PaginatorBlock
					paginationConfig={paginationConfig}
					onChange={handleChange}
					documents={currentActiveDocuments}
					startDesc={t('common.showing')}
					toDesc={t('common.to')}
					ofDesc={t('common.of')}
				/>
				<div key={currentActiveDocuments}>
					<BoxShadowTable
						isLoading={isLoading}
						size="large"
						maxHeight={640}
						minHeight={640}
						columnConfig={
							subModule === 'excel-uploads'
								? excelUploadColumnConfig({
										t,
										type: rootModule,
										onChange: handleChange,
										orgDetails,
										getCurrentOrgID,
										setFullOrgList,
										uploadDODraftAcceptedList,
										isMobile,
										fullOrgList,
										partnerList,
										allProducts,
								  })
								: getColumnConfig({
										type: rootModule,
										docType: subModule,
										sortsMap: activeSorts,
										onChange: handleChange,
										orgDetails,
										activeLocale,
										actor,
										t,
										isMobile,
										uploadDOAcceptedList,
										isIframeForTeamsApp: isIframeForTeamsApp(),
								  })
						}
						rowData={currentActiveDocuments}
						isMobile={isMobile}
					/>
				</div>
				<PaginatorBlock
					paginationConfig={paginationConfig}
					onChange={handleChange}
					documents={currentActiveDocuments}
					startDesc={t('common.showing')}
					toDesc={t('common.to')}
					ofDesc={t('common.of')}
				/>
				<Box
					padding="25px 2px"
					style={{
						maxWidth: 150,
						width: isIframeForTeamsApp() ? '100px' : 'auto',
					}}
				>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
									{ rootModule }
								)
							)
						}
					/>
				</Box>
			</Box>
			<RemarksConfirmation
				showModal={showRemarkModal}
				handleOnConfirm={remarks => {
					setShowRemarkModal(false)
					handleChange('finalize_remark_status', remarks)
				}}
				handleOnClose={() => {
					setShowRemarkModal(false)
					handleChange('reset_remark_status')
				}}
			/>
		</>
	)
}

export { DocumentsListing }
