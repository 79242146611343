import {
	getUploadDeliveryOrderSampleFile,
	getUploadWeighbridgeSampleFile,
	getUploadDeliveryOrderStorageCompanySampleFile,
	getUploadWeighbridgeStorageCompanySampleFile,
} from 'ui-tdm-app/config'

export const NAME_ALIASES = {
	'delivery-order-outgoing': 'tdmColumnHeaders.delivery-order',
	'delivery-order-outgoing-storage': 'tdmColumnHeaders.delivery-order',
	'weighbridge-outgoing': 'tdmViewDocument.weighbridge',
	'weighbridge-outgoing-storage': 'tdmViewDocument.weighbridge',
	assets: 'adminHome.assets',
	partners: 'breadcrumb.partner',
}

export const SAMPLE_FILES = {
	'delivery-order-outgoing': getUploadDeliveryOrderSampleFile,
	'delivery-order-outgoing-storage': getUploadDeliveryOrderStorageCompanySampleFile,
	'weighbridge-outgoing': getUploadWeighbridgeSampleFile,
	'weighbridge-outgoing-storage': getUploadWeighbridgeStorageCompanySampleFile,
}

export const REMARKS_TEXT = {
	'delivery-order': 'tdmDetailsEntry.uploadTwoWeekText',
	weighbridge: 'tdmDetailsEntry.uploadTwoWeekTextForWB',
	assets: 'assetsHome.uploadAssetsTwoWeekText',
	partners: 'breadcrumb.partner',
}

export const FILE_HEADERS = {
	'delivery-order-outgoing': [
		'DO Number',
		'Date',
		'Sent To',
		'Organization Type (Receiver)',
		'Product',
		'Quantity',
		'UOM',
		'Sent For',
		'Remarks',
	],
	'delivery-order-outgoing-storage': [
		'DO Number',
		'Date',
		'Sent To',
		'Organization Type (Receiver)',
		'Product',
		'Quantity',
		'UOM',
		'Sent From',
		'Remarks',
	],
	'weighbridge-outgoing': [
		'DO Number',
		'Date',
		'Sent To',
		'Organization Type (Receiver)',
		'Product',
		'Qty (MT)',
		'Tare (MT)',
		'Vehicle Number',
		'Driver Name',
		'Gross (MT)',
		'Sent For',
		'Remarks',
	],
	'weighbridge-outgoing-storage': [
		'DO Number',
		'Date',
		'Sent To',
		'Organization Type (Receiver)',
		'Product',
		'Qty (MT)',
		'Tare (MT)',
		'Vehicle Number',
		'Driver Name',
		'Gross (MT)',
		'Sent From',
		'Remarks',
	],
}
