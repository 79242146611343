import React, { useMemo, useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
	getIn,
	setIn,
	merge,
	mergeIn,
	addLast,
	removeAt,
	replaceAt,
} from 'timm'
import { useFormik } from 'formik'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { WebTourDuc } from 'ui-tdm-app/modules/WebTour/duc'
import { WebTour } from 'ui-tdm-app/modules/WebTour'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import {
	isEmptyObject,
	getRandomNumber,
	useDebouncedCallback,
	getOrganizationTypes,
	getOrganizationTypesForRice,
	getProductTotal,
} from 'ui-tdm-app/utils/helpers'
import { storageUnitColumnConfig } from 'ui-tdm-app/modules/TradeDocumentManager/components/Columns'
import { Modal } from 'ui-lib/components/Modal'
import {
	NAME_ALIASES,
	plantationDocSources,
} from 'ui-tdm-app/modules/TradeDocumentManager/config'
import * as yup from 'yup'
import { CoreSection } from 'ui-tdm-app/modules/TradeDocumentManager/containers/DocumentControl/Shared/CoreSection'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { P, Text } from 'ui-lib/components/Typography'
import { Label } from 'ui-lib/components/Label'
import { Input, SuggestionsInput } from 'ui-lib/components/Input'
import { Table } from 'ui-lib/components/Table'
import { Select, SelectSearch, CreatableSelect } from 'ui-lib/components/Select'
import { FileUpload } from 'ui-lib/components/FileUpload'
import { ModuleIconMap, currentPlatform } from 'ui-lib/utils/config'
import { Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import theme from 'ui-lib/utils/base-theme'
import PhoneInput from 'react-phone-input-2'
import plusIcon from 'ui-lib/icons/plus.svg'
import collapseCircle from 'ui-lib/icons/collapse-circle.svg'
import {
	getSupplyChainModelNone,
	getSupplyChainModelsWithId,
} from 'ui-tdm-app/modules/Admin/config'
import {
	ALPHANUMERICWITHLIMITEDSPECIALREGEX,
	SCRIPTREPLACEREGEX,
} from 'ui-tdm-app/utils/constants'
import { isMarketplaceOrg } from 'ui-lib/utils/helpers'

const HeaderWrapper = styled.div`
	display: flex;
	margin-top: 24px;
	margin-bottom: 24px;
`
const Header = styled.div`
	font-size: ${props => (props.isMobile ? '25px' : '30px')};
	font-weight: 500;
	margin-top: -6px;
	padding-left: 28px;
	color: ${theme.color.accent2};
`

const SubWrapper = styled.div`
	display: flex;
	cursor: pointer;
	color: ${theme.color.blue8};
`
const RecipientBoxWrapper = styled.div`
	width: 100%;
	display: ${props => (props.isMobile ? 'block' : 'flex')};
	margin-top: 24px;
	border-radius: 5px;
	border: 1px solid ${theme.color.grey4};
`
const RecipientEntriesBox = styled.div`
	width: ${props => (props.isMobile ? '100%' : '50%')};
	padding: ${props => (props.isMobile ? '25px 30px 0px' : '25px 45px 0px')};
	border-right: 1px solid ${theme.color.grey4};
`

const DocumentEntriesBox = styled.div`
	width: ${props => (props.isMobile ? '100%' : '50%')};
	padding: ${props => (props.isMobile ? '25px 30px 0px' : '25px 45px 0px')};
`

const RecipientOptionsBox = styled.div`
	width: ${props => (props.isMobile ? '100%' : '50%')};
	padding: ${props => (props.isMobile ? '25px 30px 5px' : '25px 45px 5px')};
`

const DocumentSecondBox = styled.div`
	width: ${props => (props.isMobile ? '100%' : '50%')};
	padding: ${props => (props.isMobile ? '25px 30px 0px' : '25px 45px 0px')};
	border-left: 1px solid ${theme.color.grey4};
`

const AttachmentSectionWrapper = styled.div`
	width: 100%;
	display: flex;
	margin-top: 24px;
`

const ProductdetailsWrapper = styled.div`
	width: 100%;
	margin-top: 24px;
	padding: 25px 30px 0px;
	border-radius: 5px;
	border: 1px solid ${theme.color.grey4};
`

const ProductRowBox = styled.div`
	display: ${props => (props.isMobile ? 'block' : 'flex')};
`

const ProductColumnBox = styled.div`
	width: ${props =>
		props.widthProp
			? props.widthProp
			: '50%'}; // 33 if supplychain in products
	padding: 5px;
`
const ProductColumnBoxLarge = styled.div`
	width: ${props => (props.isMobile ? '100%' : '50%')};
	display: ${props => (props.isMobile ? 'block' : 'flex')};
`
const OtherOptnHeader = styled.div`
	font-size: 14px;
	color: ${theme.color.accent2};
`
const OptnWrapper = styled.div`
	cursor: ${props => (props.selected ? 'default' : 'pointer')};
	margin-top: 20px;
	margin-bottom: 35px;
`
const OptnHeader = styled.div`
	display: flex;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 10px;
	color: ${props =>
		props.selected ? theme.color.themeGrey1 : theme.color.themeBlue2};
`
const OptnSubHeader = styled.div`
	font-size: 13px;
	color: #6a6a6a;
`
const FindProductText = styled.span`
	cursor: pointer;
`

const getInitialValues = valuesList => {
	const {
		type,
		parentType,
		parentDocID,
		fullName,
		orgRole,
		parentDocRef,
		_partnerID,
		actor,
		documentStatus,
		isSupplyChainModelEnforced,
		isIncomingDO,
		source = {},
	} = valuesList
	const base = {
		key: getRandomNumber(),
		activePartner: _partnerID || '',
		meta: {
			entityReference: '',
			attachmentFlow: false,
			issueDate: '',
			shipping: {
				address: {},
				shipThrough: '',
			},
			enforceSupplyChainModel: isSupplyChainModelEnforced,
			referenceName: parentType,
			referenceValue: parentDocID,
			transporter: {
				vehicleNumber: '',
			},
			authorization: {
				reportedBy: fullName,
				reportedByRole: orgRole,
				authorisedSignatory: fullName,
			},
			receiverEmailID: '',
			enableGlobalPartner: false,
			productFor: '',
			productOwner: '',
		},
		products: [],
		tankDetails: [],
		targetProducts:
			documentStatus &&
			documentStatus === 'clone' &&
			source?.individualProductEntry
				? source.individualProductEntry
				: [],
	}
	const globalInitial = {
		name: '',
		categories: [
			{
				name: '',
			},
		],
		address: {
			line1: '',
		},
		contact: {
			mobile: '',
			email: '',
		},
	}
	if (isIncomingDO) {
		base.meta.globalInitiator = globalInitial
	} else {
		base.meta.globalReceiver = globalInitial
	}

	// if from plantation.
	if (plantationDocSources.includes(parentType)) {
		if (parentType === 'plantation') {
			base.meta.binDetails = {
				ticketGroupID: getIn(parentDocRef, ['id']) || '',
				ticketGroupMeta: getIn(parentDocRef, ['meta']) || {},
				storageUnitID: getIn(parentDocRef, ['name']) || '',
				createdAt: getIn(parentDocRef, ['createdAt']) || '',
			}
		}
		if (parentType === 'storage') {
			base.traceGroupID = parentDocID
		}
	}

	if (type === 'invoice') {
		base.bankDetails = {}
	}

	if (type === 'delivery-order') {
		base.meta.shipping.modeOfTransport = {}
	}

	const _mainSource = mergeIn(source, ['meta'], {
		...base.meta,
		...source.meta,
	})

	if (!_mainSource.meta.issueDate) {
		_mainSource.meta.issueDate = new Date()
	}

	if (!_mainSource.meta.expectedDeliveryDate) {
		_mainSource.meta.expectedDeliveryDate = new Date()
	}

	let binDetails = {}
	let details = {}
	if (parentType === 'plantation') {
		binDetails = mergeIn(
			_mainSource,
			['meta', 'binDetails'],
			base.meta.binDetails
		)
		details = merge(base, binDetails)
	} else {
		details = merge(base, _mainSource)
	}

	if (actor.includes('palmoil_plantation') && parentDocRef?.id) {
		details.products = []
	}

	return details
}

const AttachDetailsEntry = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const locationState = useSelector(TradeDocDuc.selectors.location)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const loggedInUserProfile = useSelector(AuthDuc.selectors.getUserProfile)
	const globalOrgs = useSelector(AuthDuc.selectors.getGlobalOrgs)
	const actorArray = useSelector(AuthDuc.selectors.getActor)
	const activeDocument = useSelector(
		TradeDocDuc.selectors.getDocumentActiveRecord
	)
	const isSupplyChainModelEnforced = useSelector(
		TradeDocDuc.selectors.getSupplyChainModelEnforcedStatus
	)

	const baseProduct = useSelector(TradeDocDuc.selectors.getBaseProduct)

	const { isLoading } = useSelector(
		TradeDocDuc.selectors.getDocumentLoadingState
	)
	const productDetailsBasedOnSupplyChainModel =
		useSelector(
			TradeDocDuc.selectors.getProductDetailsBasedOnSupplyChainModel
		) || false

	const [tankForCurrentSelection, setTankForCurrentSelection] = useState(
		false
	)

	const storageUnits = useSelector(AuthDuc.selectors.getStorageUnits)

	const storageTankLoadingStatus = useSelector(
		TradeDocDuc.selectors.getStorageTankLoadingStatus
	)

	const parentDocRef = useSelector(
		TradeDocDuc.selectors.getDocumentParentDocRef
	)

	const partnerList = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)

	const { id: _partnerID } =
		useSelector(TradeDocDuc.selectors.getDocumentActivePartner) || ''

	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const attachmentDoc = useSelector(TradeDocDuc.selectors.getAttachmentDoc)
	const { results = [], refMessage } = useSelector(
		TradeDocDuc.selectors.getEntityRefResults
	)
	const certificationListing = useSelector(AuthDuc.selectors.getCertificates)
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	const orgdetails = useSelector(AuthDuc.selectors.getCurrentOrgDetails)
	const category = getIn(orgdetails, ['categories', 0, 'id']) || ''
	const certificationTypes = certificationListing.map(certs => {
		return {
			label: certs,
			id: certs,
		}
	})
	const uncertifiedType = {
		label: t('common.uncertified'),
		id: 'uncertified',
	}
	const checkIfCertificatesExist = certificationListing.length !== 0
	const actor = actorArray && actorArray.length > 0 ? actorArray[0] : ''
	const getCurrentOrgID = useSelector(AuthDuc.selectors.getUserOrgId)
	const { fullName, orgRole } = loggedInUserProfile
	const { payload = {}, query = {} } = locationState
	const { parentDocRef: _query = '' } = query
	const { rootModule, action, documentStatus } = payload
	const TitleIcon = ModuleIconMap[rootModule]
	const title = NAME_ALIASES[rootModule]
	const parentDocType = parentDocRef.type
	const parentDocID = parentDocRef.traceGroupID
	const isPlantation =
		actor.includes('palmoil_plantation') || actor.includes('rice-farmer')
	const isMill = actor.includes('palmoil_mill') || actor.includes('rice-mill')
	const isDO = rootModule === 'delivery-order'
	const isInvoice = rootModule === 'invoice'
	const isPO = rootModule === 'purchase-order'
	const isIncomingDO = rootModule === 'incoming-delivery-order'

	const [currentAvailableQty, setCurrentAvailableQty] = useState(0)
	const [vehicleNumberRefError, setVehicleNumberRefError] = useState(false)
	const [showConfirmationModal, setShowConfirmationModal] = useState(false)
	const [showFindProductModal, setShowFindProductModal] = useState(false)
	const [attachfileArray, setAttachfileArray] = useState([])
	const [emailAdded, setEmailAdded] = useState(
		getIn(activeDocument, ['meta', 'enableGlobalPartner']) || false
	)
	const [globalOrgAdded, setGlobalOrgAdded] = useState(
		getIn(activeDocument, ['meta', 'enableGlobalPartner']) || false
	)
	const [recipientSelected, setRecipientSelected] = useState(false)
	const [brokerAdded, setBrokerAdded] = useState(false)
	const [productChange, setProductChange] = useState(false)
	const [activePartnerError, setActivePartnerError] = useState(false)
	const [supplyChainError, setSupplyChainError] = useState(false)
	const [entityRefKey, setEntityRefKey] = useState(0)
	const [storageUnitData, setStorageUnitData] = useState([])
	const [quantityErrorMessage, setQuantityErrorMessage] = useState(false)
	const [tankList, setTankList] = useState([])
	const [keyValue, setKeyValue] = useState(getRandomNumber())
	const [totalAvailableQty, setTotalAvailableQty] = useState(0)
	const [tankQuantityError, setTankQuantityError] = useState(false)
	const [quantityError, setQuantityError] = useState(false)
	const [phoneNotCheck, setPhoneNotCheck] = useState(false)
	const [phoneError, setPhoneError] = useState(false)

	const validationSchema = () => {
		const shape = yup.object().shape({
			activePartner: yup.string(),
			meta: yup.object().shape({
				supplyChainModel: yup.string(),
				entityReference: yup
					.string()
					.required(
						`${t('tdmDetailsEntry.enter')} ${t(
							'tdmDetailsEntry.reference'
						)} ${t('tdmViewDocument.number')}`
					),
				transporter: yup.object().shape({
					vehicleNumber: yup.string(),
				}),
				globalInitiator: yup.object().shape({
					name: yup.string().when([], {
						is: () => !globalOrgAdded || !isIncomingDO,
						then: yup.string(),
						otherwise: yup
							.string()
							.required(t('validation.addOrgName')),
					}),
					categories: yup.array().when([], {
						is: () => !globalOrgAdded || !isIncomingDO,
						then: yup.array().of(
							yup.object().shape({
								name: yup.string(),
							})
						),
						otherwise: yup.array().of(
							yup.object().shape({
								name: yup
									.string()
									.required(t('validation.addOrgType')),
							})
						),
					}),
					address: yup.object().shape({
						line1: yup.string(),
					}),
					contact: yup.object().shape({
						mobile: yup.string(),
						email: yup.string(),
					}),
				}),
				globalReceiver: yup.object().shape({
					name: yup.string().when([], {
						is: () => !globalOrgAdded || isIncomingDO,
						then: yup.string(),
						otherwise: yup
							.string()
							.required(t('validation.addOrgName')),
					}),
					categories: yup.array().when([], {
						is: () => !globalOrgAdded || isIncomingDO,
						then: yup.array().of(
							yup.object().shape({
								name: yup.string(),
							})
						),
						otherwise: yup.array().of(
							yup.object().shape({
								name: yup
									.string()
									.required(t('validation.addOrgType')),
							})
						),
					}),
					address: yup.object().shape({
						line1: yup.string(),
					}),
					contact: yup.object().shape({
						mobile: yup.string(),
						email: yup.string(),
					}),
				}),
				issueDate: yup
					.date()
					.required(t('validation.issuingDateRequired')),
				receiverEmailID: yup
					.string()
					.email(t('validation.emailFormat')),
				brokerEmailID: yup.string().email(t('validation.emailFormat')),
				productFor: yup.string(),
				productOwner: yup.string(),
			}),
			products: yup
				.array()
				.of(
					yup.object().shape({
						id: yup
							.string()
							.required(t('validation.addAtleast1Product')),
					})
				)
				.required(t('validation.addAtleast1Product')),
		})

		if (!isDO && !isIncomingDO && currentPlatform() === 'palmoil') {
			shape.fields.meta.fields.supplyChainModel = shape.fields.meta.fields.supplyChainModel.required(
				`${t('tdmDocumentListing.select')} ${t(
					'tdmDetailsEntry.supplyChainModelFull'
				)}`
			)
		}

		if (!emailAdded && !globalOrgAdded) {
			shape.fields.activePartner = shape.fields.activePartner.required(
				t('validation.selectRecipient')
			)
		} else if (emailAdded && !globalOrgAdded) {
			shape.fields.meta.fields.receiverEmailID = shape.fields.meta.fields.receiverEmailID.required(
				t('validation.emailFormat')
			)
		}

		if (isDO) {
			shape.fields.meta.fields.transporter.fields.vehicleNumber = shape.fields.meta.fields.transporter.fields.vehicleNumber.required(
				`${t('tdmDetailsEntry.enter')} ${t(
					'tdmDetailsEntry.vehicleNumber'
				)}`
			)
		}

		if (isDO && actor.includes('palmoil_storage')) {
			shape.fields.meta.fields.productOwner = shape.fields.meta.fields.productOwner.required()
		}

		return shape
	}

	const getBreadCrumbsList = useCallback(
		() => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.tradeDocManager'),
				name: 'trade-doc-dashboard',
				isActive: true,
			},
			...(action === 'details'
				? [
						{
							label: `${t('breadcrumb.new')} ${t(
								actor.includes('palmoil_tdm') && isPO
									? t('breadcrumb.salesOrder')
									: title
							)} - ${t('breadcrumb.details')}`,
							name: 'document-details',
							isActive: false,
						},
				  ]
				: [
						{
							label: `${t('breadcrumb.new')} ${t(
								actor.includes('palmoil_tdm') && isPO
									? t('breadcrumb.salesOrder')
									: title
							)}`,
							name: 'document-attach',
							isActive: false,
						},
				  ]),
		],
		[action, actor, isPO, t, title]
	)

	const onChange = useCallback(
		(_action, meta) => {
			if (_action === 'storage_unit') {
				const existingStorageUnit = storageUnitData.filter(
					storage => storage.storageUnitID === meta.storageUnitID
				)

				const rowIndex = storageUnitData.findIndex(
					st => st.storageUnitID === meta.storageUnitID
				)

				if (existingStorageUnit.length === 0 && meta.quantity !== 0) {
					const addRow = addLast(storageUnitData, meta)
					setStorageUnitData(addRow)
					setQuantityErrorMessage(false)
				}
				if (existingStorageUnit.length > 0 && meta.quantity === 0) {
					setStorageUnitData(removeAt(storageUnitData, rowIndex))
					setQuantityErrorMessage(false)
				} else if (existingStorageUnit.length > 0) {
					const targetArray = replaceAt(
						storageUnitData,
						rowIndex,
						meta
					)

					setStorageUnitData(targetArray)
					setQuantityErrorMessage(false)
				}

				if (meta.availableQty < meta.quantity) {
					setQuantityErrorMessage(true)
				}
			}
		},
		[storageUnitData]
	)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const docCheck = useCallback(() => {
		let check = true
		if (locationState?.query?.parentDocRef?.includes('~')) {
			const type = locationState.query.parentDocRef.split('~')
			if (type[0] !== rootModule) {
				check = false
			}
		}

		return check
	})

	const initialValues = useMemo(() => {
		const valuesList = {
			type: rootModule,
			parentType: parentDocType,
			parentDocID,
			fullName,
			orgRole,
			parentDocRef,
			_partnerID,
			actor,
			documentStatus,
			isSupplyChainModelEnforced,
			isIncomingDO,
			source: activeDocument,
		}

		return getInitialValues(valuesList)
	}, [
		rootModule,
		parentDocType,
		parentDocID,
		fullName,
		orgRole,
		parentDocRef,
		_partnerID,
		actor,
		documentStatus,
		isSupplyChainModelEnforced,
		isIncomingDO,
		activeDocument,
	])

	const breadCrumbs = useMemo(() => getBreadCrumbsList(), [
		getBreadCrumbsList,
	])

	const isPlantationFlow = actor?.includes('palmoil_plantation')

	const isDealerFlow =
		actor?.includes('palmoil_ffbdealer') ||
		actor?.includes('palmoil_ffbsubdealer') ||
		actor?.includes('palmoil_collectionpoint') ||
		actor?.includes('rice-dealer')

	const isNotSupplyChainFlow =
		actor?.includes('palmoil_plantation') ||
		actor?.includes('palmoil_ffbdealer') ||
		actor?.includes('palmoil_ffbsubdealer') ||
		actor?.includes('palmoil_collectionpoint') ||
		actor?.includes('rice-dealer') ||
		actor?.includes('rice-mill') ||
		actor?.includes('rice-packingcompany') ||
		actor?.includes('rice-packer') ||
		actor?.includes('rice-trader') ||
		actor?.includes('rice-wholesaler') ||
		actor?.includes('rice-importer') ||
		actor?.includes('rice-endbuyer')

	const isSupplyChainFlowWithoutTank =
		actor?.includes('palmoil_trader') ||
		actor?.includes('palmoil_manufacturer')

	const isSupplyChainFlowWithTank =
		actor?.includes('palmoil_mill') ||
		actor?.includes('palmoil_kernelmill') ||
		actor?.includes('palmoil_refinery') ||
		actor?.includes('palmoil_oleochemical') ||
		actor?.includes('palmoil_storage')

	const hasNoInventoryFromProductionMapping =
		category?.includes('palmoil-oleochemicals') ||
		category?.includes('marketplace-palmoil-oleochemicals')

	const getShippingAddress = data => {
		if (data.meta.enableGlobalPartner) {
			return getIn(data, ['meta', 'globalReceiver', 'address']) || {}
		}
		const currentOrg = partners.find(item => item.id === data.activePartner)

		return currentOrg?.primaryAddress ? currentOrg.primaryAddress : {}
	}

	const {
		handleSubmit,
		values,
		errors,
		touched,
		handleBlur,
		setFieldValue,
		isSubmitting,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnMount: true,
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			// replace all script tags
			if (getIn(_values, ['meta', globalType, 'name'])) {
				_values.meta[globalType].name = _values.meta[
					globalType
				].name.replace(SCRIPTREPLACEREGEX, '')
			}
			if (getIn(_values, ['meta', globalType, 'address', 'line1'])) {
				_values.meta[globalType].address.line1 = _values.meta[
					globalType
				].address.line1.replace(SCRIPTREPLACEREGEX, '')
			}
			// replace all script tags ends

			setSubmitting(false)
			const data = { ..._values }

			if (!isIncomingDO) {
				if (isPlantation) {
					const plantationPayloadMeta = { ...data.meta }
					plantationPayloadMeta.uomNonMetric = 'Nos'

					plantationPayloadMeta.quantityNonMetric = getIn(_values, [
						'products',
						0,
						'quantity',
					])
					data.meta = plantationPayloadMeta
				}
			}

			const finalData = []
			if (isDO) {
				const totalQty =
					storageUnitData &&
					storageUnitData.length > 0 &&
					storageUnitData.reduce((prev, current) => {
						return prev + current.quantity
					}, 0)

				if (currentPlatform() === 'rice') {
					const currProduct = { ...data.products[0] }

					currProduct.inventoryType = 'outgoing'
					data.products = [currProduct]
				}

				if (isNotSupplyChainFlow) {
					if (isPlantationFlow) {
						const currProduct = { ...data.products[0] }

						currProduct.supplyChainModel = 'not-applicable'
						currProduct.storageUnitID = 'plantation-yard'
						currProduct.inventoryType = 'incoming'
						currProduct._submitted = true
						data.products = [currProduct]
					} else if (isDealerFlow) {
						// No storage check now
						const currProduct = { ...data.products[0] }

						currProduct.supplyChainModel = 'not-applicable'
						currProduct.inventoryType = 'outgoing'
						data.products = [currProduct]
						data.targetProducts = [currProduct]
					}
				} else if (!isSupplyChainModelEnforced) {
					if (isSupplyChainFlowWithoutTank) {
						if (
							values.products[0].certification === '' ||
							values.products[0].certification === undefined
						) {
							const currProduct = { ...data.products[0] }
							currProduct.inventoryType = actor?.includes(
								'palmoil_trader'
							)
								? 'outgoing'
								: 'incoming'
							data.products = [currProduct]
							data.targetProducts = [currProduct]
						} else if (values.products[0].certification !== '') {
							const currProduct = { ...data.products[0] }

							currProduct.inventoryType = actor?.includes(
								'palmoil_trader'
							)
								? 'outgoing'
								: 'incoming'
							data.products = [
								{
									...currProduct,
									...{
										quantity: quantityWhenSupplyChainIsNotEnforced,
									},
								},
							]
							data.targetProducts =
								currentAvailableQty > 0 ? [currProduct] : []

							if (
								quantityWhenSupplyChainIsNotEnforced >
								currentAvailableQty
							) {
								const currTarProd = { ...values.products[0] }
								currTarProd.id = values.products[0].id
								currTarProd.inventoryType = actor?.includes(
									'palmoil_trader'
								)
									? 'outgoing'
									: 'incoming'
								currTarProd.quantity =
									quantityWhenSupplyChainIsNotEnforced -
									currentAvailableQty
								currTarProd.certification =
									values.products[0].certification
								currTarProd.supplyChainModel =
									values.products[0].supplyChainModel

								data.targetProducts.push(currTarProd)
							}
						}
					} else if (isSupplyChainFlowWithTank) {
						if (
							values.products[0].certification === '' ||
							(values.products[0].certification !== '' &&
								!(
									storageUnitData &&
									storageUnitData.length > 0
								))
						) {
							const currProduct = { ...data.products[0] }
							currProduct.inventoryType = 'outgoing'
							if (
								values.products[0].certification ===
									undefined &&
								values.products[0].supplyChainModel ===
									undefined
							) {
								currProduct.certification = 'uncertified'
								currProduct.supplyChainModel = 'none'
							}
							data.products = [currProduct]
							data.targetProducts = [currProduct]
						} else if (
							values.products[0].certification !== '' &&
							storageUnitData &&
							storageUnitData.length > 0
						) {
							const selectedProduct = allProducts.find(
								item =>
									item.id === storageUnitData[0]?.productID
							)
							storageUnitData.forEach(item => {
								const currItem = { ...item }
								currItem.totalQty = totalQty
								currItem.inventoryType =
									actor.includes('palmoil_storage') &&
									!isIncomingDO
										? 'incoming'
										: 'outgoing'
								currItem.lcv = selectedProduct?.lcv
									? selectedProduct?.lcv * totalQty
									: null
								finalData.push({
									...getIn(data, ['products', 0]),
									...currItem,
								})
							})

							if (
								quantityWhenSupplyChainIsNotEnforced > totalQty
							) {
								const virtualQuantity =
									quantityWhenSupplyChainIsNotEnforced -
									totalQty
								finalData.push({
									id: values.products[0].id,
									lcv: selectedProduct?.lcv
										? selectedProduct?.lcv * virtualQuantity
										: null,
									inventoryType: 'outgoing',
									quantity: virtualQuantity,
									certification:
										values.products[0].certification,
									supplyChainModel:
										values.products[0].supplyChainModel,
								})
							}

							const productItem = { ...finalData[0] }
							productItem.quantity = quantityWhenSupplyChainIsNotEnforced
							productItem.lcv =
								selectedProduct?.lcv *
								quantityWhenSupplyChainIsNotEnforced
							productItem._submitted = true
							data.products = [productItem]
							data.targetProducts = finalData
							data.fromCreate = true
						}
					}
				} else if (isSupplyChainModelEnforced) {
					if (isSupplyChainFlowWithoutTank) {
						if (data.products && data.products.length > 0) {
							const currProduct = { ...data.products[0] }
							currProduct.selectedProdQty = getIn(data, [
								'products',
								0,
								'availableQty',
							])

							currProduct.inventoryType = actor?.includes(
								'palmoil_trader'
							)
								? 'outgoing'
								: 'incoming'
							currProduct.storageUnitID = 'virtual-storage'
							currProduct._submitted = true
							currProduct.prevQty =
								getIn(activeDocument, [
									'products',
									0,
									'quantity',
								]) || 0

							data.products = [currProduct]
							data.targetProducts = [currProduct]
						}
					} else if (isSupplyChainFlowWithTank) {
						if (storageUnitData && storageUnitData.length > 0) {
							const selectedProduct = allProducts.find(
								item =>
									item.id === storageUnitData[0]?.productID
							)
							storageUnitData.forEach(item => {
								const currItem = { ...item }
								currItem.totalQty = totalQty
								currItem.selectedProdQty = getIn(data, [
									'products',
									0,
									'availableQty',
								])
								currItem.availableQty = getIn(data, [
									'products',
									0,
									'availableQty',
								])
								currItem.inventoryType =
									actor.includes('palmoil_storage') &&
									!isIncomingDO
										? 'incoming'
										: 'outgoing'
								currItem.lcv = selectedProduct?.lcv
									? selectedProduct?.lcv * totalQty
									: null
								finalData.push({
									...getIn(data, ['products', 0]),
									...currItem,
								})
							})
							const productItem = { ...finalData[0] }
							productItem.quantity = totalQty
							productItem.lcv = selectedProduct?.lcv * totalQty
							productItem._submitted = true
							data.products = [productItem]
							data.targetProducts = finalData
							data.fromCreate = true
						}
					}
				}
			} else if (isIncomingDO) {
				const currentMeta = { ...data.meta }
				const currProduct = { ...data.products[0] }
				if (isMill) {
					currentMeta.enableBuffer = true
				}
				if (isMill || isDealerFlow) {
					currentMeta.inventoryFromFarm = true
				}
				currentMeta.incomingVirtualDO = true
				data.meta = currentMeta
				data.products = [currProduct]
			}

			if (isDealerFlow) {
				const newMeta = { ...data.meta }
				newMeta.inventoryFromFarm = true
				data.meta = newMeta
			}

			const finalMeta = { ...data.meta }
			const shipping = {
				...finalMeta.shipping,
				address: isIncomingDO
					? orgdetails.primaryAddress
					: getShippingAddress(data),
			}
			finalMeta.shipping = shipping
			data.meta = finalMeta

			dispatch(
				TradeDocDuc.creators.setActiveDocument({
					rootModule,
					baseSchema: data,
				})
			)

			const currentData = { ...data }
			if (documentStatus && documentStatus === 'draft') {
				currentData.fromDraft = true
			}

			if (documentStatus && documentStatus === 'clone') {
				dispatch(
					MainRouteDuc.creators.switchPage(
						locationState.type,
						{
							...locationState.payload,
							action: 'attach-details-confirm',
							documentStatus: 'edit',
							baseValueSchema: currentData,
							forceScrolltoTop: true,
						},
						locationState.query
					)
				)
			} else {
				dispatch(
					MainRouteDuc.creators.switchPage(
						locationState.type,
						{
							...locationState.payload,
							action: 'attach-details-confirm',
							baseValueSchema: currentData,
							forceScrolltoTop: true,
						},
						locationState.query
					)
				)
			}

			dispatch(WebTourDuc.creators.setActiveTourModule(''))
		},
	})

	const handleFormikChange = useCallback(
		(key, _values) => {
			setFieldValue(key, _values)
		},
		[setFieldValue]
	)

	/** Handlers */

	const breadCrumRootModule = getIn(locationState, [
		'prev',
		'payload',
		'rootModule',
	])
		? getIn(locationState, ['prev', 'payload', 'rootModule'])
		: null

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		} else if (target === 'trade-doc-dashboard') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
					{
						rootModule:
							breadCrumRootModule === 'incoming' ||
							breadCrumRootModule === 'outgoing' ||
							breadCrumRootModule === 'all'
								? getIn(locationState, [
										'prev',
										'payload',
										'rootModule',
								  ])
								: 'incoming',
					}
				)
			)
		}
	}

	const partners = []

	partnerList.forEach(list => {
		const orgType = getIn(list, ['categories', 0, 'id']) || ''
		if (isPO || isInvoice) {
			if (
				orgType !== 'palmoil-ffbDealer' &&
				orgType !== 'palmoil-ffbSubDealer' &&
				orgType !== 'rice-dealer'
			) {
				partners.push({
					id: list.id,
					label: list.name,
					primaryAddress: list.primaryAddress,
				})
			}
		} else {
			partners.push({
				id: list.id,
				label: list.name,
				primaryAddress: list.primaryAddress,
			})
		}
	})

	const activeProducts = allProducts.map(({ code: _code, name }) => {
		return {
			name,
			label: `${name} (#${_code})`,
		}
	})

	const [productCount, setProductCount] = useState(
		values?.products?.length > 0 ? values.products.length : 1
	)

	const [
		quantityWhenSupplyChainIsNotEnforced,
		setQuantityWhenSupplyChainIsNotEnforced,
	] = useState(getIn(values, ['products', 0, 'quantity']) || 0)

	const isEdit =
		documentStatus &&
		(documentStatus === 'clone' || documentStatus === 'edit')

	const needsTransportDetails = [
		'delivery-order',
		'weighbridge-slip',
		'invoice',
	].includes(rootModule)

	const disableIndex = partners.findIndex(x => x.id === values.activePartner)
	const disableArrayIndex = [0]
	partners.forEach((_, index) => {
		if (index !== disableIndex) {
			disableArrayIndex.push(index + 1)
		}
	})
	const disableProductArrayIndex = [0]
	activeProducts.forEach((_, index) => {
		if (index !== disableIndex) {
			disableProductArrayIndex.push(index + 1)
		}
	})
	const globalType = isIncomingDO ? 'globalInitiator' : 'globalReceiver'

	const getSupplyChainValue = () => {
		if (
			checkIfCertificatesExist &&
			getIn(values, ['products', 0, 'supplyChainModel'])
		) {
			return getIn(values, ['products', 0, 'supplyChainModel']) || ''
		}

		return 'none'
	}

	const getProduct = index => {
		return (
			<ProductColumnBox
				widthProp={currentPlatform() === 'palmoil' ? '100%' : '50%'}
			>
				{(isMobile || index === 0) && (
					<ProductColumnBox widthProp="100%">
						<Box>
							<Label
								required
								style={{
									width: '94%',
									marginBottom: '10px',
									color: theme.color.accent2,
									fontSize: '14px',
									fontWeight: 500,
								}}
							>{`${t('tdmViewDocument.product')} ${t(
								'createAsset.name'
							)}`}</Label>
						</Box>
					</ProductColumnBox>
				)}
				<SelectSearch
					name="productID"
					id={`product${productCount}`}
					key={`product${productCount}`}
					placeholder={`${t('tdmDocumentListing.choose')} ${t(
						'tdmDocumentListing.one'
					)}`}
					options={activeProducts}
					error={errors.productID}
					onChange={product => {
						if (values && !values.products) {
							values.products = []
						}
						const selectedProduct = allProducts.find(
							item => item.name === product.productID.name
						)
						setFieldValue(['products', [index]], selectedProduct)
						setFieldValue(['products', [index], 'quantity'], '')
						setQuantityWhenSupplyChainIsNotEnforced(0)

						if (
							isDO &&
							!isNotSupplyChainFlow &&
							!isSupplyChainModelEnforced
						) {
							setFieldValue(
								['products', [index], 'supplyChainModel'],
								'none'
							)
							setFieldValue(
								['products', [index], 'certification'],
								'uncertified'
							)
						}
						setKeyValue(getRandomNumber())
						if (isDO && isSupplyChainFlowWithTank) {
							setTankList([])
							setStorageUnitData([])
							setFieldValue(['targetProducts'], [])
						}
						if (!isDO || isPlantationFlow) {
							setProductChange(true)
						}
					}}
					value={getIn(values, ['products', index, 'name']) || ''}
					labelKey="label"
					valueKey="name"
					defaultLabel={t('common.selectDefaultLabel')}
					style={{
						border: 'none',
						borderBottom: `1px solid ${theme.color.blue8}`,
					}}
					parentStyle={{
						width: '100%',
						display: 'block',
					}}
					disabledIndexArr={
						!!_query &&
						!isPlantation &&
						[
							'delivery-order',
							'invoice',
							'purchase-order',
						].includes(parentDocType)
							? disableProductArrayIndex
							: []
					}
				/>
			</ProductColumnBox>
		)
	}

	const getQuantity = index => {
		return (
			<ProductColumnBox
				widthProp={currentPlatform() === 'palmoil' ? '100%' : '50%'}
			>
				{(isMobile || index === 0) && (
					<ProductColumnBox widthProp="100%">
						<Box>
							<Label
								required
								style={{
									width: '94%',
									marginBottom: '10px',
									color: theme.color.accent2,
									fontSize: '14px',
									fontWeight: 500,
								}}
							>
								{t('tdmDetailsEntry.quantity')}
							</Label>
						</Box>
					</ProductColumnBox>
				)}
				<Box>
					<Input
						id={`quantity${productCount}`}
						key={
							getIn(values, ['products', index, 'name']) ||
							`quantity${productCount}`
						}
						name="quantity"
						type="number"
						value={
							getIn(values, ['products', index, 'quantity']) || ''
						}
						disabled={!getIn(values, ['products', index, 'id'])}
						onChange={e => {
							setQuantityWhenSupplyChainIsNotEnforced(
								e.target.valueAsNumber
							)
							if (e.target.value !== '') {
								setFieldValue(
									['products', [index], 'quantity'],
									parseFloat(e.target.value)
								)
								const selectedProduct = allProducts.find(
									item =>
										item.name ===
										values?.products[index]?.name
								)
								const lcv =
									isDO && isPlantation
										? e.target.value *
										  selectedProduct?.lcv *
										  0.02
										: e.target.value * selectedProduct?.lcv
								setFieldValue(['products', [index], 'lcv'], lcv)
								setProductChange(false)
							} else {
								setFieldValue(
									['products', [index], 'quantity'],
									''
								)
								setProductChange(false)
							}
						}}
						extendStyles={{
							border: 'none',
							borderRadius: '0px',
							borderBottom: `1px solid ${theme.color.blue8}`,
						}}
						placeholder={`${t('tdmDetailsEntry.enter')} ${t(
							'tdmDetailsEntry.quantity'
						)}`}
					/>
				</Box>
			</ProductColumnBox>
		)
	}

	const getCertificateTypeAndSupplyChain = index => {
		return (
			<>
				<ProductColumnBox widthProp="100%">
					{(isMobile || index === 0) && (
						<ProductColumnBox widthProp="100%">
							<Box>
								<Label
									required={isSupplyChainModelEnforced}
									style={{
										width: '94%',
										marginBottom: '10px',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t('productTrace.certificationType')}
								</Label>
							</Box>
						</ProductColumnBox>
					)}
					<Select
						required
						value={
							checkIfCertificatesExist &&
							getIn(values, ['products', index, 'certification'])
								? getIn(values, [
										'products',
										index,
										'certification',
								  ])
								: 'uncertified'
						}
						key={getIn(values, [
							'products',
							index,
							'certification',
						])}
						name="certification"
						options={
							checkIfCertificatesExist
								? [...certificationTypes, uncertifiedType]
								: [uncertifiedType]
						}
						onChange={value => {
							if (
								getIn(values, [
									'products',
									index,
									'certification',
								]) !== value.id
							) {
								setTankList([])
								setStorageUnitData([])
								setFieldValue(['targetProducts'], [])
								setFieldValue(
									['products', [index], 'certification'],
									value.id
								)
								if (isDO) {
									if (value.id !== 'uncertified') {
										setFieldValue(
											[
												'products',
												[index],
												'supplyChainModel',
											],
											'mass-balance'
										)
									} else {
										setFieldValue(
											[
												'products',
												[index],
												'supplyChainModel',
											],
											'none'
										)
									}
									if (isSupplyChainModelEnforced) {
										setFieldValue(
											['products', [index], 'quantity'],
											''
										)
									}
								}

								setKeyValue(getRandomNumber())
							}
						}}
						disabled={!getIn(values, ['products', index, 'id'])}
						returnOnlyValue
						labelKey="label"
						valueKey="id"
						onBlur={handleBlur}
						error={touched.certification && errors.certification}
						style={{
							border: 'none',
							borderBottom: `1px solid ${theme.color.blue8}`,
						}}
					/>
				</ProductColumnBox>

				{isDO && (
					<ProductColumnBox widthProp="100%">
						{(isMobile || index === 0) && (
							<ProductColumnBox widthProp="100%">
								<Box>
									<Label
										required={isSupplyChainModelEnforced}
										style={{
											width: '94%',
											marginBottom: '10px',
											color: theme.color.accent2,
											fontSize: '14px',
											fontWeight: 500,
										}}
									>{`${t(
										'tdmDetailsEntry.supplyChainModel'
									)} ${t('createAsset.model')}`}</Label>
								</Box>
							</ProductColumnBox>
						)}
						<Select
							required
							name="supplyChainModel"
							options={
								checkIfCertificatesExist &&
								getIn(values, [
									'products',
									0,
									'certification',
								]) !== 'uncertified' &&
								getIn(values, [
									'products',
									0,
									'certification',
								]) !== undefined
									? getSupplyChainModelsWithId(t)
									: getSupplyChainModelNone(t)
							}
							labelKey="name"
							valueKey="id"
							disabled={!getIn(values, ['products', index, 'id'])}
							key={
								getIn(values, [
									'products',
									0,
									'supplyChainModel',
								]) || ''
							}
							value={getSupplyChainValue()}
							onChange={value => {
								if (
									getIn(values, [
										'products',
										index,
										'supplyChainModel',
									]) !== value.id
								) {
									setTankList([])
									setStorageUnitData([])
									setFieldValue(['targetProducts'], [])
									setFieldValue(
										[
											'products',
											[index],
											'supplyChainModel',
										],
										value.id
									)
									if (isSupplyChainModelEnforced) {
										setFieldValue(
											['products', [index], 'quantity'],
											''
										)
									}
									setKeyValue(getRandomNumber())
								}
							}}
							returnOnlyValue
							style={{
								border: 'none',
								borderBottom: `1px solid ${theme.color.blue8}`,
							}}
						/>
					</ProductColumnBox>
				)}
			</>
		)
	}

	const getUnitPrice = index => {
		return (
			<ProductColumnBox widthProp="100%">
				{(isMobile || index === 0) && (
					<ProductColumnBox widthProp="100%">
						<Box>
							<Label
								required
								style={{
									width: '94%',
									marginBottom: '10px',
									color: theme.color.accent2,
									fontSize: '14px',
									fontWeight: 500,
								}}
							>
								{t('tdmDetailsEntry.unitPrice')}
							</Label>
						</Box>
					</ProductColumnBox>
				)}
				<Box>
					<Input
						id={`metricUnitPrice${productCount}`}
						key={
							getIn(values, ['products', index, 'name']) ||
							`metricUnitPrice${productCount}`
						}
						name="metricUnitPrice"
						type="number"
						value={
							getIn(values, [
								'products',
								index,
								'metricUnitPrice',
							]) || ''
						}
						disabled={!getIn(values, ['products', index, 'id'])}
						onChange={e => {
							setFieldValue(
								['products', [index], 'metricUnitPrice'],
								e.target.value !== ''
									? parseFloat(e.target.value)
									: ''
							)
						}}
						extendStyles={{
							border: 'none',
							borderRadius: '0px',
							borderBottom: `1px solid ${theme.color.blue8}`,
						}}
						placeholder={`${t('tdmDetailsEntry.enter')} ${t(
							'tdmDetailsEntry.unitPrice'
						)}`}
					/>
				</Box>
			</ProductColumnBox>
		)
	}

	const getTaxPrice = index => {
		return (
			<ProductColumnBox widthProp="100%">
				{(isMobile || index === 0) && (
					<ProductColumnBox widthProp="100%">
						<Box>
							<Label
								required
								style={{
									width: '94%',
									marginBottom: '10px',
									color: theme.color.accent2,
									fontSize: '14px',
									fontWeight: 500,
								}}
							>
								{t('tdmDetailsEntry.taxPrice')}
							</Label>
						</Box>
					</ProductColumnBox>
				)}
				<Box>
					<Input
						id={`tax${productCount}`}
						key={
							getIn(values, ['products', index, 'name']) ||
							`tax${productCount}`
						}
						name="tax"
						type="number"
						value={
							getIn(values, [
								'products',
								index,
								'tax',
								0,
								'value',
							]) || ''
						}
						disabled={!getIn(values, ['products', index, 'id'])}
						onChange={e => {
							setFieldValue(
								`products[${index}].tax.[0].value`,
								e.target.value !== ''
									? parseFloat(e.target.value)
									: ''
							)
						}}
						extendStyles={{
							border: 'none',
							borderRadius: '0px',
							borderBottom: `1px solid ${theme.color.blue8}`,
						}}
						placeholder={`${t('tdmDetailsEntry.enter')} ${t(
							'tdmDetailsEntry.taxPrice'
						)}`}
					/>
				</Box>
			</ProductColumnBox>
		)
	}

	const getTotalPrice = index => {
		return (
			<ProductColumnBox widthProp="100%">
				{(isMobile || index === 0) && (
					<ProductColumnBox widthProp="100%">
						<Box>
							<Label
								style={{
									width: '94%',
									marginBottom: '10px',
									color: theme.color.accent2,
									fontSize: '14px',
									fontWeight: 500,
								}}
							>
								{t('tdmColumnHeaders.totalPrice')}
							</Label>
						</Box>
					</ProductColumnBox>
				)}
				{getProductTotal(getIn(values, ['products', index]) || {}) >
					0 && (
					<Box>
						<Label
							style={{
								padding: 5,
								marginTop: 5,
							}}
						>
							{getProductTotal(
								getIn(values, ['products', index]) || {}
							)}
						</Label>
					</Box>
				)}
			</ProductColumnBox>
		)
	}

	const getAvailableQuantity = index => {
		return (
			<ProductColumnBox widthProp="100%">
				{(isMobile || index === 0) && (
					<ProductColumnBox widthProp="100%">
						<Box>
							<Label
								style={{
									width: '94%',
									marginBottom: '10px',
									color: theme.color.accent2,
									fontSize: '14px',
									fontWeight: 500,
								}}
							>
								{t('products.availableQty')}
							</Label>
						</Box>
					</ProductColumnBox>
				)}
				<Box
					style={{
						marginTop: '10px',
					}}
				>
					{currentAvailableQty || ''}
				</Box>
			</ProductColumnBox>
		)
	}

	const getText = data => {
		let text = ''
		switch (data) {
			case 'main-header': {
				if (isPO) {
					text = t('tdmDetailsEntry.sendPurchaseOrderToYourSeller')
				} else if (isInvoice) {
					text = t('tdmDetailsEntry.sendInvoiceToYourBuyer')
				} else if (isDO) {
					text = t('tdmDetailsEntry.SendDeliveryOrderToYourBuyer')
				}

				return text
			}

			case 'attach-button': {
				if (isPO) {
					text = t('tdmDetailsEntry.purchase-order-copy')
				} else if (isInvoice) {
					text = t('tdmDetailsEntry.invoice-copy')
				} else if (isDO) {
					text = t('tdmDetailsEntry.delivery-order-copy')
				}

				return text
			}

			default:
				return text
		}
	}

	const disableSubmit = () => {
		if (
			values?.products?.length > 0 &&
			values.products[productCount - 1] &&
			values.products[productCount - 1].name !== ''
		) {
			if (!isSupplyChainModelEnforced && isDO) {
				if (
					isSupplyChainFlowWithTank &&
					(values.products[0].quantity === '' ||
						values.products[0].quantity === 0 ||
						(values?.products[0]?.certification &&
							tankForCurrentSelection &&
							(storageUnitData.length === 0 ||
								quantityErrorMessage === true)))
				) {
					return true
				}
				if (
					isSupplyChainFlowWithoutTank &&
					(values.products[0].quantity === '' ||
						values.products[0].quantity === 0)
				) {
					return true
				}
			}
			if (
				(!isDO || isNotSupplyChainFlow) &&
				!(
					values.products[productCount - 1].quantity &&
					values.products[productCount - 1].quantity !== ''
				)
			) {
				return true
			}
			if (
				!isDO &&
				!(
					values.products[productCount - 1].metricUnitPrice &&
					values.products[productCount - 1].metricUnitPrice !== ''
				)
			) {
				return true
			}
			if (
				isDO &&
				isSupplyChainFlowWithTank &&
				isSupplyChainModelEnforced &&
				(storageUnitData.length === 0 || quantityErrorMessage === true)
			) {
				return true
			}
			if (
				isDO &&
				isSupplyChainFlowWithoutTank &&
				isSupplyChainModelEnforced &&
				!(
					values.products[productCount - 1].quantity &&
					values.products[productCount - 1].quantity !== '' &&
					values.products[productCount - 1].quantity <=
						currentAvailableQty
				)
			) {
				return true
			}
		}

		return false
	}

	const isPhoneValid =
		phoneNotCheck ||
		(!phoneNotCheck &&
			(!phoneError ||
				!getIn(values, ['meta', globalType, 'contact', 'mobile'])))

	const disableCTA =
		!isEmptyObject(errors) ||
		disableSubmit() ||
		productChange ||
		!isPhoneValid ||
		tankQuantityError

	const entityrefList = type => {
		const list = []
		if (getIn(results, [type])) {
			getIn(results, [type]).forEach(item => {
				list.push({ label: item.number, value: item.number })
			})
		}

		return list
	}

	const getSelectedAssociatedRef = type => {
		let data = ''
		if (values?.meta?.docReference?.length > 0) {
			const selectedDoc = values.meta.docReference.find(
				item => item.referenceDocType === type
			)
			if (selectedDoc?.referenceValue) {
				data = selectedDoc.referenceValue
			}
		}

		return data
	}

	const setAssociatedReference = (type, value) => {
		const getList = () => {
			const list = []
			getIn(values, ['meta', 'docReference']).forEach(item => {
				if (item.referenceDocType !== type) {
					list.push(item)
				}
			})

			return list
		}
		const list =
			getIn(values, ['meta', 'docReference']) &&
			getIn(values, ['meta', 'docReference']).length > 0
				? getList()
				: []
		if (
			value === '' &&
			getIn(values, ['meta', 'docReference']) &&
			getIn(values, ['meta', 'docReference']).length > 0
		) {
			if (getIn(values, ['meta', 'docReference']).length === 1) {
				setFieldValue(['meta', 'docReference'], [])
			} else {
				const newArray = getIn(values, ['meta', 'docReference']).filter(
					item => item.referenceDocType !== type
				)
				setFieldValue(['meta', 'docReference'], newArray)
			}
		} else if (getIn(results, [type])) {
			const selectedItem = getIn(results, [type]).find(
				item => item.number === value
			)
			list.push({
				referenceID: selectedItem?.id ? selectedItem.id : '',
				referenceDocType: type,
				referenceValue: selectedItem?.number ? selectedItem.number : '',
			})
			if (list && list.length > 0) {
				setFieldValue(['meta', 'docReference'], list)
			}
		}
	}

	const handleSearch = useDebouncedCallback((value, docType) => {
		if (value === '') {
			setAssociatedReference(docType, '')
		} else {
			dispatch(TradeDocDuc.creators.searchEntityRef(value, docType))
		}
	}, 800)

	const getStorageUnitColumnProps = (
		currentStorageUnits,
		handleOnChange,
		currentStorageUnitData,
		isEditCheck,
		currentBaseProduct,
		targetProducts,
		attachFlow = false
	) => {
		const props = {
			storageUnits: currentStorageUnits,
			onChange: handleOnChange,
			storageUnitData: currentStorageUnitData,
			isEdit: isEditCheck,
			baseProduct: currentBaseProduct,
			targetProducts,
			t,
			isMobile,
			attachFlow,
		}

		return props
	}

	const showToBeShippedTo =
		actor?.includes('palmoil_mill') ||
		actor?.includes('palmoil_kernelmill') ||
		actor?.includes('palmoil_refinery') ||
		actor?.includes('palmoil_oleochemical') ||
		actor?.includes('palmoil_trader')

	const breadCrumbBlock = () => {
		return (
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
		)
	}

	const headerBlock = () => {
		return (
			<Box
				row
				justifyContent="space-between"
				alignItems="baseline"
				style={{
					padding: '10px 0 15px',
				}}
			>
				<HeaderWrapper>
					<SubWrapper>
						<IconWrapper
							size={24}
							style={{
								color: theme.color.blue8,
								cursor: 'pointer',
							}}
						>
							<Icon glyph={TitleIcon} />
						</IconWrapper>
					</SubWrapper>
					{!isDealerFlow && (
						<Header isMobile={isMobile}>
							{getText('main-header')}
						</Header>
					)}
				</HeaderWrapper>
			</Box>
		)
	}

	const recipientBlock = () => {
		return (
			<Box>
				<Box
					style={{
						color: theme.color.accent2,
						fontSize: '18px',
						fontWeight: 500,
					}}
				>
					{isIncomingDO
						? t('tdmDetailsEntry.sender')
						: t('tdmDetailsEntry.recipient')}
				</Box>
				<RecipientBoxWrapper isMobile={isMobile}>
					<RecipientEntriesBox isMobile={isMobile}>
						{!globalOrgAdded && (
							<Box Box id="input-partner-picker2">
								{actor.includes('palmoil_smallholder') ? (
									<Label
										style={{
											marginBottom: '10px',
											color: theme.color.accent2,
											fontSize: '14px',
											fontWeight: 500,
										}}
										required
									>
										{t('tdmDetailsEntry.choosePartner')}
									</Label>
								) : (
									<Label
										required
										style={{
											marginBottom: '10px',
											color: theme.color.accent2,
											fontSize: '14px',
											fontWeight: 500,
										}}
									>{`${t('tdmDocumentListing.select')} ${t(
										title
									)} ${
										isIncomingDO
											? t('tdmDetailsEntry.sender')
											: t('tdmDetailsEntry.recipient')
									}`}</Label>
								)}
								<div
									onBlur={() => {
										setTimeout(() => {
											setActivePartnerError(true)
										}, 100)
									}}
								>
									<SelectSearch
										name="activePartner"
										key={values.activePartner}
										value={values.activePartner}
										placeholder={`${t(
											'tdmDocumentListing.choose'
										)} ${t('tdmDocumentListing.one')}`}
										options={partners}
										error={
											activePartnerError &&
											errors.activePartner
										}
										onChange={value => {
											dispatch(
												TradeDocDuc.creators.setActivePartner(
													value
												)
											)
											if (value && value.id !== '') {
												setRecipientSelected(true)
											} else {
												setRecipientSelected(false)
											}
											setEmailAdded(false)
											setFieldValue('products', [])
											setFieldValue(
												['meta', 'entityReference'],
												''
											)
											setTankList([])
											setStorageUnitData([])
											setFieldValue(
												['targetProducts'],
												[]
											)
											setProductCount(0)
											setEntityRefKey(getRandomNumber())
											setFieldValue(
												'activePartner',
												value.id
											)
										}}
										labelKey="label"
										valueKey="id"
										defaultLabel={t(
											'common.selectDefaultLabel'
										)}
										style={{
											width: '445px',
											border: 'none',
											marginBottom: '8px',
											borderBottom: `1px solid ${theme.color.blue8}`,
										}}
										returnOnlyValue
										disabledIndexArr={
											(!!_query &&
												[
													'delivery-order',
													'invoice',
													'purchase-order',
												].includes(parentDocType)) ||
											(documentStatus &&
												(documentStatus === 'edit' ||
													documentStatus === 'clone'))
												? disableArrayIndex
												: []
										}
									/>
								</div>
							</Box>
						)}
						{showToBeShippedTo && isDO && (
							<>
								<Spacer size={40} />
								<Label
									style={{
										marginBottom: '10px',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									To be shipped to:
								</Label>
								<SelectSearch
									placeholder={t('createAsset.choose')}
									value={getIn(values, [
										'meta',
										'productFor',
									])}
									name="productFor"
									options={partners}
									onChange={({ id }) => {
										setFieldValue(
											['meta', 'productFor'],
											id
										)
									}}
									returnOnlyValue
									labelKey="label"
									valueKey="id"
									onBlur={handleBlur}
									style={{
										width: '445px',
										border: 'none',
										marginBottom: '8px',
										borderBottom: `1px solid ${theme.color.blue8}`,
									}}
									defaultLabel={t(
										'common.selectDefaultLabel'
									)}
								/>
							</>
						)}
						{actor?.includes('palmoil_storage') && !globalOrgAdded && (
							<>
								<Spacer size={40} />
								<Label
									required
									style={{
										marginBottom: '10px',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									On behalf of:
								</Label>
								<SelectSearch
									placeholder={t('createAsset.choose')}
									value={getIn(values, [
										'meta',
										'productOwner',
									])}
									name="productOwner"
									options={partners}
									onChange={({ id }) => {
										setFieldValue(
											['meta', 'productOwner'],
											id
										)
									}}
									returnOnlyValue
									labelKey="label"
									valueKey="id"
									onBlur={handleBlur}
									style={{
										width: '445px',
										border: 'none',
										marginBottom: '8px',
										borderBottom: `1px solid ${theme.color.blue8}`,
									}}
									defaultLabel={t(
										'common.selectDefaultLabel'
									)}
								/>
							</>
						)}
						{globalOrgAdded && (
							<>
								<Box overflow style={{ marginBottom: 25 }}>
									<div
										style={{
											display: 'flex',
										}}
									>
										<Label
											required
											style={{
												width: '94%',
												marginBottom: '10px',
												color: theme.color.accent2,
												fontSize: '14px',
												fontWeight: 500,
											}}
										>
											{t('invitePartner.orgName')}
										</Label>
										{!isEdit && (
											<IconWrapper
												size={18}
												onClick={() => {
													setGlobalOrgAdded(false)
													setEmailAdded(false)
												}}
												style={{
													color:
														theme.color.themeRed1,
													cursor: 'pointer',
												}}
											>
												<Icon glyph={collapseCircle} />
											</IconWrapper>
										)}
									</div>
									<Spacer size={4} />
									<CreatableSelect
										name="name"
										type="text"
										disabled={isEdit}
										options={globalOrgs}
										disableBoxShadow
										onBlur={handleBlur}
										value={
											getIn(values, [
												'meta',
												globalType,
												'name',
											]) || ''
										}
										onChange={value => {
											setFieldValue(
												['meta', globalType, 'name'],
												value.replace(
													ALPHANUMERICWITHLIMITEDSPECIALREGEX,
													''
												)
											)
										}}
										error={
											getIn(errors, [
												'meta',
												globalType,
												'name',
											]) || ''
										}
										minLenth={3}
										returnOnlyValue
									/>
								</Box>
								<Box>
									<SelectSearch
										label={t('invitePartner.orgType')}
										name="orgType"
										options={
											currentPlatform() === 'rice'
												? getOrganizationTypesForRice(t)
												: getOrganizationTypes(t)
										}
										value={
											getIn(values, [
												'meta',
												globalType,
												'categories',
												0,
												'name',
											]) || ''
										}
										onChange={value => {
											setFieldValue(
												[
													'meta',
													globalType,
													'categories',
													[0],
													'name',
												],
												value.name
											)
										}}
										error={
											getIn(errors, [
												'meta',
												globalType,
												'categories',
												0,
												'name',
											]) || ''
										}
										returnOnlyValue
										labelKey="label"
										valueKey="name"
										disabled={isEdit}
										defaultLabel={t(
											'common.selectDefaultLabel'
										)}
										onBlur={handleBlur}
										required
									/>
								</Box>
								<Box>
									<Input
										regex={
											ALPHANUMERICWITHLIMITEDSPECIALREGEX
										}
										label={t('invitePartner.location')}
										name="location"
										type="text"
										value={
											getIn(values, [
												'meta',
												globalType,
												'address',
												'line1',
											]) || ''
										}
										onChange={event => {
											setFieldValue(
												[
													'meta',
													globalType,
													'address',
													'line1',
												],
												event.target.value
											)
										}}
										error={
											getIn(errors, [
												'meta',
												globalType,
												'address',
												'line1',
											]) || ''
										}
										onBlur={handleBlur}
										disabled={isEdit}
									/>
								</Box>
								<Box>
									<Label
										color={theme.color.grey8}
										style={{
											fontSize: '16px',
											margin: '8px 0',
										}}
									>
										{t('empMgmtHome.phoneNo')}
									</Label>
									<div
										style={{
											marginTop: '10px',
											marginBottom: '30px',
										}}
										onBlur={() => setPhoneError(true)}
									>
										<PhoneInput
											country="my"
											value={
												getIn(values, [
													'meta',
													globalType,
													'contact',
													'mobile',
												]) || ''
											}
											onChange={phone => {
												setFieldValue(
													[
														'meta',
														globalType,
														'contact',
														'mobile',
													],
													phone ? `+${phone}` : ''
												)
											}}
											enableSearch
											disableSearchIcon
											isValid={(value, country) => {
												if (country?.format) {
													const number = country.format.match(
														/\./g
													).length
													setPhoneNotCheck(
														!!(
															value &&
															value.length ===
																number
														)
													)
												}
											}}
											disabled={isEdit}
										/>
										{!isPhoneValid ? (
											<div
												style={{
													fontSize: '11px',
													marginTop: '9px',
													marginLeft: '12px',
													color: theme.color.error,
												}}
											>
												{t(
													'validation.phoneNumNotValid'
												)}
											</div>
										) : (
											''
										)}
									</div>
								</Box>
							</>
						)}
						{!isIncomingDO &&
						emailAdded &&
						(!recipientSelected || globalOrgAdded) ? (
							<Box>
								<div
									style={{
										display: 'flex',
									}}
								>
									<Label
										style={{
											width: '94%',
											marginBottom: '10px',
											color: theme.color.accent2,
											fontSize: '14px',
											fontWeight: 500,
										}}
									>{`${t('common.add')} ${t(
										'login.email'
									)}`}</Label>
									{!isEdit && (
										<IconWrapper
											size={18}
											onClick={() => {
												setEmailAdded(false)
												setFieldValue(
													['meta', 'receiverEmailID'],
													''
												)
											}}
											style={{
												color: theme.color.themeRed1,
												cursor: 'pointer',
											}}
										>
											<Icon glyph={collapseCircle} />
										</IconWrapper>
									)}
								</div>
								<Input
									name="receiverEmailID"
									type="text"
									value={
										getIn(values, [
											'meta',
											'receiverEmailID',
										]) || ''
									}
									onChange={e => {
										setFieldValue(
											['meta', 'receiverEmailID'],
											e.target.value
										)
										setFieldValue(
											[
												'meta',
												globalType,
												'contact',
												'email',
											],
											e.target.value
										)
									}}
									error={
										getIn(errors, [
											'meta',
											'receiverEmailID',
										]) || ''
									}
									disabled={isEdit}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
									}}
								/>
							</Box>
						) : (
							''
						)}
						{brokerAdded ? (
							<Box>
								<div
									style={{
										display: 'flex',
									}}
								>
									<Label
										style={{
											width: '94%',
											marginBottom: '10px',
											color: theme.color.accent2,
											fontSize: '14px',
											fontWeight: 500,
										}}
									>{`${t('common.add')} ${t(
										'tdmDetailsEntry.broker'
									)}`}</Label>
									<IconWrapper
										size={18}
										onClick={() => {
											setBrokerAdded(false)
											setFieldValue(
												['meta', 'brokerEmailID'],
												''
											)
										}}
										style={{
											color: theme.color.themeRed1,
											cursor: 'pointer',
										}}
									>
										<Icon glyph={collapseCircle} />
									</IconWrapper>
								</div>
								<Input
									value={
										values?.meta?.brokerEmailID
											? values.meta.brokerEmailID
											: ''
									}
									name="brokerEmailID"
									type="text"
									onChange={e => {
										setFieldValue(
											['meta', 'brokerEmailID'],
											e.target.value
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
									}}
									error={errors?.meta?.brokerEmailID}
								/>
							</Box>
						) : (
							''
						)}
					</RecipientEntriesBox>
					<RecipientOptionsBox isMobile={isMobile}>
						<OtherOptnHeader>
							{t('tdmDetailsEntry.otherOptions')}
						</OtherOptnHeader>
						{!isIncomingDO && (
							<OptnWrapper
								id="emailAddOption"
								onClick={() => {
									setEmailAdded(true)
								}}
								selected={emailAdded || recipientSelected}
							>
								<OptnHeader
									selected={emailAdded || recipientSelected}
								>
									{`${t('common.add')} ${t('login.email')}`}
								</OptnHeader>
								<OptnSubHeader>
									{t('tdmDetailsEntry.addEmailSubText')}
								</OptnSubHeader>
							</OptnWrapper>
						)}
						<OptnWrapper
							selected={brokerAdded}
							onClick={() => setBrokerAdded(true)}
						>
							<OptnHeader selected={brokerAdded}>
								<div>{`${t('common.add')} ${t(
									'tdmDetailsEntry.broker'
								)}`}</div>
								<div
									style={{
										marginLeft: '20px',
										color: theme.color.themeGrey1,
									}}
								>
									({t('tdmDetailsEntry.optional')})
								</div>
							</OptnHeader>
							<OptnSubHeader>
								{t('tdmDetailsEntry.addBrokerSubText')}
							</OptnSubHeader>
						</OptnWrapper>
						<OptnWrapper
							selected={globalOrgAdded}
							onClick={() => {
								setEmailAdded(true)
								setGlobalOrgAdded(true)
							}}
						>
							<OptnHeader selected={globalOrgAdded}>
								<div>{`${t('common.add')} ${t(
									'tdmDetailsEntry.fromGlobalOrganisation'
								)}`}</div>
								<div
									style={{
										marginLeft: '20px',
										color: theme.color.themeGrey1,
									}}
								>
									({t('tdmDetailsEntry.optional')})
								</div>
							</OptnHeader>
							<OptnSubHeader>
								{t(
									'tdmDetailsEntry.fromGlobalOrganisationSubText'
								)}
							</OptnSubHeader>
						</OptnWrapper>
					</RecipientOptionsBox>
				</RecipientBoxWrapper>
			</Box>
		)
	}

	const attachFilesBlock = () => {
		return (
			<Box
				style={{
					marginTop: '30px',
				}}
			>
				<Box
					style={{
						color: theme.color.accent2,
						fontSize: '18px',
						fontWeight: 500,
					}}
				>
					<span>
						{t('breadcrumb.attach')} {t('tdmDetailsEntry.files')}
					</span>
				</Box>
				<AttachmentSectionWrapper>
					<Box
						style={{
							padding: '1px',
							color: theme.color.blue8,
							backgroundColor: theme.color.white,
						}}
					>
						<FileUpload
							modalTriggerBtnLabel={t('common.upload')}
							type="private"
							name="attachments"
							size="5242880"
							supportedFileType=".pdf"
							newAlignment={!isMobile}
							disableUploadForOne
							width={
								values?.meta?.attachedEntity?.id
									? '320px'
									: '265px'
							}
							currentUploads={attachfileArray || []}
							onDocumentChange={async documents => {
								if (documents && documents.length > 0) {
									setFieldValue(
										['meta', 'attachedEntity'],
										documents[0]
									)
									setFieldValue(
										['meta', 'attachmentFlow'],
										true
									)
								} else {
									setFieldValue(
										['meta', 'attachedEntity'],
										documents
									)
									setFieldValue(
										['meta', 'attachmentFlow'],
										false
									)
								}
							}}
							onUploadFailure={({ message }) => {
								dispatch(
									AppDuc.creators.showToast({
										messageType: 'error',
										message,
									})
								)
							}}
						/>
					</Box>
				</AttachmentSectionWrapper>
			</Box>
		)
	}

	const documentDetailsBlock = () => {
		return (
			<Box>
				<Box
					style={{
						fontWeight: 500,
						fontSize: '18px',
						marginTop: '30px',
						color: theme.color.accent2,
					}}
				>
					{t('common.document')} {t('breadcrumb.details')}
				</Box>
				<RecipientBoxWrapper isMobile={isMobile}>
					<DocumentEntriesBox isMobile={isMobile}>
						{!isDO &&
						!isIncomingDO &&
						currentPlatform() === 'palmoil' ? (
							<Box
								style={{
									marginBottom: '10px',
								}}
							>
								<Label
									required
									style={{
										marginBottom: '10px',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{`${t(
										'tdmDetailsEntry.supplyChainModel'
									)} ${t('createAsset.model')}`}
								</Label>
								<div
									onBlur={() =>
										setTimeout(() => {
											setSupplyChainError(true)
										}, 100)
									}
								>
									<Select
										required
										name="supplyChainModel"
										placeholder={`${t(
											'tdmDocumentListing.choose'
										)} ${t('tdmDocumentListing.one')}`}
										options={[
											...getSupplyChainModelsWithId(t),
											...getSupplyChainModelNone(t),
										]}
										error={
											supplyChainError &&
											errors?.meta?.supplyChainModel
										}
										labelKey="name"
										valueKey="id"
										disabled={
											!!_query &&
											[
												'delivery-order',
												'invoice',
												'purchase-order',
											].includes(parentDocType)
												? disableArrayIndex
												: []
										}
										key={getIn(values, [
											'meta',
											'supplyChainModel',
											'id',
										])}
										value={getIn(values, [
											'meta',
											'supplyChainModel',
											'id',
										])}
										onChange={value => {
											setFieldValue(
												['meta', 'supplyChainModel'],
												value
											)
										}}
										style={{
											border: 'none',
											marginBottom: '8px',
											borderBottom: `1px solid ${theme.color.blue8}`,
										}}
										returnOnlyValue
									/>
								</div>
							</Box>
						) : (
							''
						)}
						<CoreSection
							newTDM
							rootModule={rootModule}
							title={title}
							values={values.meta}
							errors={errors.meta}
							hasActiveParent
							needsTransportDetails={needsTransportDetails}
							isEdit={isEdit}
							onChange={handleFormikChange}
							entityRefKey={entityRefKey}
						/>
					</DocumentEntriesBox>
					{!isPO ? (
						<DocumentSecondBox isMobile={isMobile}>
							<Box
								style={{
									marginBottom: '10px',
								}}
							>
								<Label
									style={{
										marginBottom: '10px',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>{`${t('tdmDetailsEntry.associated')} ${t(
									'tdmColumnHeaders.purchase-order'
								)} ${t('tdmDetailsEntry.reference')}`}</Label>
								<SuggestionsInput
									key={getSelectedAssociatedRef(
										'purchase-order'
									)}
									value={getSelectedAssociatedRef(
										'purchase-order'
									)}
									placeholder={`${t(
										'schedulerListing.search'
									)} ${t('tdmDocumentListing.one')}`}
									onChange={e =>
										handleSearch(
											e.target.value,
											'purchase-order'
										)
									}
									suggestions={entityrefList(
										'purchase-order'
									)}
									onSelect={selected =>
										setAssociatedReference(
											'purchase-order',
											selected.value
										)
									}
									extendStyles={{
										width: '445px',
										border: 'none',
										borderBottom: `1px solid ${theme.color.blue8}`,
									}}
									error={refMessage?.['purchase-order']}
								/>
							</Box>
							<Box>
								<Label
									style={{
										marginBottom: '10px',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>{`${t('tdmDetailsEntry.associated')} ${
									isInvoice
										? t('tdmColumnHeaders.delivery-order')
										: t('tdmColumnHeaders.invoice')
								} ${t('tdmDetailsEntry.reference')}`}</Label>
								{isInvoice ? (
									<SuggestionsInput
										key={getSelectedAssociatedRef(
											'delivery-order'
										)}
										value={getSelectedAssociatedRef(
											'delivery-order'
										)}
										placeholder={`${t(
											'schedulerListing.search'
										)} ${t('tdmDocumentListing.one')}`}
										onChange={e =>
											handleSearch(
												e.target.value,
												'delivery-order'
											)
										}
										suggestions={entityrefList(
											'delivery-order'
										)}
										onSelect={selected =>
											setAssociatedReference(
												'delivery-order',
												selected.value
											)
										}
										extendStyles={{
											width: '445px',
											border: 'none',
											borderBottom: `1px solid ${theme.color.blue8}`,
										}}
										error={refMessage?.['delivery-order']}
									/>
								) : (
									<SuggestionsInput
										key={getSelectedAssociatedRef(
											'invoice'
										)}
										value={getSelectedAssociatedRef(
											'invoice'
										)}
										placeholder={`${t(
											'schedulerListing.search'
										)} ${t('tdmDocumentListing.one')}`}
										onChange={e =>
											handleSearch(
												e.target.value,
												'invoice'
											)
										}
										suggestions={entityrefList('invoice')}
										onSelect={selected =>
											setAssociatedReference(
												'invoice',
												selected.value
											)
										}
										extendStyles={{
											width: '445px',
											border: 'none',
											borderBottom: `1px solid ${theme.color.blue8}`,
										}}
										error={refMessage?.invoice}
									/>
								)}
							</Box>
						</DocumentSecondBox>
					) : (
						''
					)}
				</RecipientBoxWrapper>
			</Box>
		)
	}

	const getProductList = () => {
		return (
			<Box>
				{storageTankLoadingStatus && <TransparentBgSpinner />}
				{[...Array(productCount).keys()].map((value, index) => (
					<div key={`productList-${value}`}>
						<ProductRowBox isMobile={isMobile}>
							{getProduct(index)}
							{((!isSupplyChainModelEnforced &&
								(isSupplyChainFlowWithTank ||
									isSupplyChainFlowWithoutTank) &&
								isDO) ||
								currentPlatform() === 'rice') &&
								getQuantity(index)}
							{(isSupplyChainFlowWithTank ||
								isSupplyChainFlowWithoutTank) &&
								getCertificateTypeAndSupplyChain(index)}

							{(!isDO ||
								isNotSupplyChainFlow ||
								(isSupplyChainFlowWithoutTank &&
									isSupplyChainModelEnforced &&
									productDetailsBasedOnSupplyChainModel &&
									productDetailsBasedOnSupplyChainModel.length >
										0)) &&
								getQuantity(index)}
							{!isDO && getUnitPrice(index)}
							{!isDO && getTaxPrice(index)}
							{!isDO && getTotalPrice(index)}
							{/* {isDO &&
								isSupplyChainFlowWithoutTank &&
								productDetailsBasedOnSupplyChainModel &&
								productDetailsBasedOnSupplyChainModel.length >
									0 &&
								getAvailableQuantity(index)} */}
							{productCount > 1 ? (
								<div
									key={`icon${productCount}`}
									style={{
										display: 'flex',
										marginBottom: 20,
										justifyContent: 'end',
										alignItems:
											index === 0 ? 'center' : 'start',
									}}
								>
									<IconWrapper
										id={`icon${productCount}`}
										size={18}
										onClick={() => {
											const currentProdList = [
												...values.products,
											]
											currentProdList.splice(index, 1)
											setProductCount(productCount - 1)
											setFieldValue(
												'products',
												currentProdList
											)
											setProductChange(false)
										}}
										style={{
											cursor: 'pointer',
											paddingTop: isMobile ? 0 : 17,
											color: theme.color.themeRed1,
										}}
									>
										<Icon glyph={collapseCircle} />
									</IconWrapper>
								</div>
							) : (
								''
							)}
						</ProductRowBox>
					</div>
				))}
			</Box>
		)
	}

	const noTankBlock = () => {
		return (
			<>
				{values?.products?.length > 0 &&
					values?.products[0]?.supplyChainModel !== '' &&
					values?.products[0]?.certification &&
					values?.products[0]?.certification !== '' &&
					tankList.length === 0 &&
					isDO &&
					isSupplyChainFlowWithTank &&
					!storageTankLoadingStatus &&
					isSupplyChainModelEnforced && (
						<Box center margin="0 0 8px 0" alignItems="end">
							<h5 style={{ color: 'red' }}>
								{t('tdmDetailsEntry.noTanks')}
							</h5>
							<p
								style={{
									marginTop: 8,
								}}
							>
								{t('tdmDetailsEntry.tryDifferent')}
							</p>
						</Box>
					)}
			</>
		)
	}

	const tankBlock = () => {
		return (
			<>
				{tankList.length > 0 && isDO && isSupplyChainFlowWithTank && (
					<Box
						height="auto"
						width="auto"
						style={{
							width: '92%',
							margin: '28px 0',
						}}
					>
						<Text
							style={{
								textAlign: 'center',
								padding: 8,
							}}
						>
							{t('tdmDetailsEntry.onlyInventoryMatching')}
						</Text>
						<div>
							<Table
								columnConfig={storageUnitColumnConfig(
									getStorageUnitColumnProps(
										storageUnits,
										onChange,
										storageUnitData,
										values?.fromCreate ||
											(documentStatus &&
												documentStatus === 'clone') ||
											(documentStatus &&
												documentStatus === 'edit') ||
											values?.fromCreate,
										baseProduct,
										values.targetProducts,
										true
									)
								)}
								rowData={tankList}
								noRoundedBorder
								mobileViewCSSOn
							/>
						</div>
					</Box>
				)}
			</>
		)
	}

	const noQuantityBlock = () => {
		return (
			<>
				{values?.products?.length > 0 &&
					values?.products[0]?.supplyChainModel !== '' &&
					values?.products[0]?.certification &&
					values?.products[0]?.certification !== '' &&
					isDO &&
					!isSupplyChainFlowWithoutTank &&
					!productDetailsBasedOnSupplyChainModel && (
						<Box center margin="0 0 8px 0" alignItems="end">
							<h5 style={{ color: 'red' }}>
								{t('tdmDetailsEntry.noQuantity')}
							</h5>
							<p
								style={{
									marginTop: 8,
								}}
							>
								{t('tdmDetailsEntry.tryDifferent')}
							</p>
						</Box>
					)}
			</>
		)
	}

	const addProductBlock = () => {
		return (
			<>
				{!isDO ? (
					<ProductRowBox
						style={{
							fontWeight: 500,
							color: theme.color.themeBlue2,
							marginBottom: '20px',
							display: 'flex',
							justifyContent: 'end',
						}}
					>
						<IconWrapper
							size={18}
							style={{
								color: theme.color.themeBlue2,
								marginRight: '10px',
							}}
						>
							<Icon glyph={plusIcon} />
						</IconWrapper>
						<FindProductText
							onClick={() => {
								if (
									values?.products?.length > 0 &&
									values.products[productCount - 1] &&
									values.products[productCount - 1].name !==
										'' &&
									values.products[productCount - 1]
										.quantity &&
									values.products[productCount - 1]
										.quantity !== ''
								) {
									setProductCount(productCount + 1)
								}
							}}
						>
							{`${t('common.add')} ${t('breadcrumb.products')}`}
						</FindProductText>
					</ProductRowBox>
				) : (
					''
				)}
			</>
		)
	}

	const quantityErrorBlock = () => {
		return (
			<>
				{quantityError && (
					<div
						style={{
							padding: '8px',
							margin: '4px 0',
							borderRadius: '4px',
							color: theme.color.error,
							border: `1px solid ${theme.color.error}`,
						}}
					>
						{t('tdmDetailsEntry.quantityEmpty')}
					</div>
				)}
			</>
		)
	}

	const tankQuantityErrorBlock = () => {
		return (
			<>
				{tankQuantityError && (
					<div
						style={{
							padding: '8px',
							margin: '4px 0',
							borderRadius: '4px',
							color: theme.color.error,
							border: `1px solid ${theme.color.error}`,
						}}
					>
						{t('tdmDetailsEntry.quantityFromTank')}
					</div>
				)}
			</>
		)
	}

	const traceNotAvailableBlock = () => {
		return (
			<>
				{isDO &&
					!hasNoInventoryFromProductionMapping &&
					!isSupplyChainModelEnforced &&
					quantityWhenSupplyChainIsNotEnforced !== 0 &&
					quantityWhenSupplyChainIsNotEnforced >
						totalAvailableQty && (
						<div
							style={{
								padding: 8,
								margin: '4px 0',
								borderRadius: 4,
								color: theme.color.error,
								border: `1px solid ${theme.color.error}`,
							}}
						>
							{t('tdmDetailsEntry.traceNotAvailable')}
						</div>
					)}
				{isDO && hasNoInventoryFromProductionMapping && (
					<div
						style={{
							padding: 8,
							margin: '4px 0',
							borderRadius: 4,
							color: theme.color.error,
							border: `1px solid ${theme.color.error}`,
						}}
					>
						{t(
							'tdmDetailsEntry.traceNotAvailableSinceNoProductInventory'
						)}
					</div>
				)}
			</>
		)
	}

	const cantFindProductBlock = () => {
		return (
			<ProductRowBox isMobile={isMobile} style={{ marginBottom: '23px' }}>
				<ProductColumnBoxLarge
					style={{
						marginTop: isSupplyChainModelEnforced ? 0 : 12,
						fontSize: '13px',
						color: theme.color.themeGrey1,
					}}
					isMobile={isMobile}
				>
					<span
						style={{
							fontWeight: 500,
						}}
					>
						{`${t('common.note')}: `}
						&nbsp;{' '}
						<span
							style={{
								fontWeight: 400,
							}}
						>{`${t('tdmDetailsEntry.createNote')}`}</span>
					</span>
				</ProductColumnBoxLarge>
				<ProductColumnBoxLarge
					isMobile={isMobile}
					style={{
						fontSize: '16px',
						marginTop: isMobile ? '10px' : '0px',
						justifyContent: isMobile ? 'start' : 'end',
						color: theme.color.themeBlue2,
					}}
				>
					<FindProductText
						onClick={() => setShowFindProductModal(true)}
					>
						{t('tdmDetailsEntry.CantFindfMyProduct')}
					</FindProductText>
				</ProductColumnBoxLarge>
			</ProductRowBox>
		)
	}

	const productBlock = () => {
		return (
			<Box>
				<Box
					style={{
						fontWeight: 500,
						fontSize: '18px',
						marginTop: '30px',
						color: theme.color.accent2,
					}}
				>
					{t('tdmViewDocument.product')} {t('breadcrumb.details')}
				</Box>
				<ProductdetailsWrapper>
					<div>{getProductList()}</div>
					{noTankBlock()}
					{tankBlock()}
					{noQuantityBlock()}
					{addProductBlock()}
					{quantityErrorBlock()}
					{tankQuantityErrorBlock()}
					{traceNotAvailableBlock()}
					{cantFindProductBlock()}
				</ProductdetailsWrapper>
			</Box>
		)
	}

	const shippingBlock = () => {
		return (
			<Box>
				<Box
					style={{
						fontWeight: 500,
						fontSize: '18px',
						marginTop: '30px',
						color: theme.color.accent2,
					}}
				>
					{t('tdmDetailsEntry.shippingAndDelivery')}
				</Box>
				<Box
					style={{
						width: '100%',
						marginTop: '24px',
						padding: '25px 30px 0px',
						borderRadius: '5px',
						border: `1px solid ${theme.color.grey4}`,
					}}
				>
					<Box row={!isMobile} justifyContent="space-between">
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t('tdmDetailsEntry.dispatchedThrough')}
								</Label>
								<Input
									name="shipThrough"
									value={getIn(values, [
										'meta',
										'shipping',
										'shipThrough',
									])}
									returnKeyValue
									onChange={value => {
										setFieldValue(
											'meta',
											setIn(
												values.meta,
												['shipping', 'shipThrough'],
												value.shipThrough
											)
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
									}}
								/>
							</Box>
						</Box>
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t(
										'tdmDetailsEntry.shipperReferenceNumber'
									)}
								</Label>
								<Input
									name="shipperNumber"
									value={getIn(values, [
										'meta',
										'shipping',
										'shipperNumber',
									])}
									returnKeyValue
									onChange={value => {
										setFieldValue(
											'meta',
											setIn(
												values.meta,
												['shipping', 'shipperNumber'],
												value.shipperNumber
											)
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
									}}
								/>
							</Box>
						</Box>
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t('tdmDetailsEntry.billOfLading')}
								</Label>
								<Input
									name="billOfLading"
									value={getIn(values, [
										'meta',
										'shipping',
										'billOfLading',
									])}
									returnKeyValue
									onChange={value => {
										setFieldValue(
											'meta',
											setIn(
												values.meta,
												['shipping', 'billOfLading'],
												value.billOfLading
											)
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
									}}
								/>
							</Box>
						</Box>
					</Box>
					<Box row={!isMobile} justifyContent="space-between">
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t('tdmDetailsEntry.modeOfTransport')}
								</Label>
								<Select
									options={[
										{
											name: t('tdmDetailsEntry.truck'),
											id: 'truck',
										},
										{
											name: t('tdmDetailsEntry.sea'),
											id: 'sea',
										},
										{
											name: t('tdmDetailsEntry.pipeline'),
											id: 'pipline',
										},
									]}
									labelKey="name"
									valueKey="id"
									value={getIn(values, [
										'meta',
										'shipping',
										'modeOfTransport',
										'id',
									])}
									onChange={value => {
										setFieldValue(
											'meta',
											setIn(
												values.meta,
												['shipping', 'modeOfTransport'],
												value
											)
										)
									}}
									returnOnlyValue
									style={{
										width: '445px',
										border: 'none',
										marginBottom: '8px',
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
										borderBottom: `1px solid ${theme.color.blue8}`,
									}}
								/>
							</Box>
						</Box>
						{(isDO || isIncomingDO) && (
							<>
								<Box width={isMobile ? '100%' : '30%'}>
									<Box>
										<Label
											required
											style={{
												width: '94%',
												color: theme.color.accent2,
												fontSize: '14px',
												fontWeight: 500,
											}}
										>
											{t('tdmViewDocument.vehicleNumber')}
										</Label>
										<Input
											name="vehicleNumber"
											value={getIn(values, [
												'meta',
												'transporter',
												'vehicleNumber',
											])}
											error={
												vehicleNumberRefError &&
												getIn(errors, [
													'meta',
													'transporter',
													'vehicleNumber',
												])
											}
											onBlur={() =>
												setVehicleNumberRefError(true)
											}
											returnKeyValue
											onChange={value => {
												setFieldValue(
													'meta',
													setIn(
														values.meta,
														[
															'transporter',
															'vehicleNumber',
														],
														value.vehicleNumber
													)
												)
											}}
											extendStyles={{
												border: 'none',
												borderRadius: '0px',
												borderBottom: `1px solid ${theme.color.blue8}`,
												marginTop:
													rootModule ===
													'delivery-order'
														? '12px'
														: '7px',
											}}
										/>
									</Box>
								</Box>
								<Box width={isMobile ? '100%' : '30%'}>
									<Box>
										<Label
											style={{
												width: '94%',
												color: theme.color.accent2,
												fontSize: '14px',
												fontWeight: 500,
											}}
										>
											{t('tdmViewDocument.driverName')}
										</Label>
										<Input
											name="driverName"
											value={getIn(values, [
												'meta',
												'transporter',
												'driverName',
											])}
											returnKeyValue
											onChange={value => {
												setFieldValue(
													'meta',
													setIn(
														values.meta,
														[
															'transporter',
															'driverName',
														],
														value.driverName
													)
												)
											}}
											extendStyles={{
												border: 'none',
												borderRadius: '0px',
												borderBottom: `1px solid ${theme.color.blue8}`,
												marginTop:
													rootModule ===
													'delivery-order'
														? '12px'
														: '7px',
											}}
										/>
									</Box>
								</Box>
							</>
						)}
					</Box>
				</Box>
			</Box>
		)
	}

	const authorizationBlock = () => {
		return (
			<Box>
				<Box
					style={{
						fontWeight: 500,
						fontSize: '18px',
						marginTop: '30px',
						color: theme.color.accent2,
					}}
				>
					{t('tdmDetailsEntry.authorization')}
				</Box>
				<Box
					style={{
						width: '100%',
						marginTop: '24px',
						padding: '25px 30px 0px',
						borderRadius: '5px',
						border: `1px solid ${theme.color.grey4}`,
					}}
				>
					<Box row={!isMobile} justifyContent="space-between">
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									required
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t('tdmPreviewDetails.reportedBy')}
								</Label>
								<Input
									name="reportedBy"
									value={getIn(values, [
										'meta',
										'authorization',
										'reportedBy',
									])}
									onChange={e => {
										setFieldValue(
											[
												'meta',
												'authorization',
												'reportedBy',
											],
											e.target.value
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
									}}
								/>
							</Box>
						</Box>
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									required
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t('tdmPreviewDetails.role')}
								</Label>
								<Input
									name="reportedByRole"
									value={getIn(values, [
										'meta',
										'authorization',
										'reportedByRole',
									])}
									onChange={e => {
										setFieldValue(
											[
												'meta',
												'authorization',
												'reportedByRole',
											],
											e.target.value
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
									}}
								/>
							</Box>
						</Box>
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									required
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t('tdmPreviewDetails.authorisedSignatory')}
								</Label>
								<Input
									name="authorisedSignatory"
									value={getIn(values, [
										'meta',
										'authorization',
										'authorisedSignatory',
									])}
									onChange={e => {
										setFieldValue(
											[
												'meta',
												'authorization',
												'authorisedSignatory',
											],
											e.target.value
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
									}}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		)
	}

	const submitBlock = () => {
		return (
			<Box row justifyContent={isMobile ? 'center' : 'flex-end'}>
				<Button
					label={`${t('common.preview')} & ${t('common.send')}`}
					type="submit"
					disabled={disableCTA}
					isLoading={isSubmitting}
					primary
					rounded
					extendStyles={{
						width: '232px',
						opacity: 'unset',
						backgroundColor: disableCTA
							? theme.color.themeGrey1
							: theme.color.blue8,
						border: disableCTA
							? theme.color.themeGrey1
							: theme.color.blue8,
					}}
				/>
			</Box>
		)
	}

	useEffect(() => {
		const storageUnitQuantity = storageUnitData.reduce((prev, current) => {
			return prev + current.quantity
		}, 0)

		if (
			isSupplyChainModelEnforced === false &&
			!isSupplyChainFlowWithoutTank &&
			!isDealerFlow &&
			storageUnitData.length > 0
		) {
			if (
				quantityWhenSupplyChainIsNotEnforced <= totalAvailableQty &&
				quantityWhenSupplyChainIsNotEnforced !== storageUnitQuantity
			) {
				setTankQuantityError(true)
			} else if (
				quantityWhenSupplyChainIsNotEnforced > totalAvailableQty &&
				totalAvailableQty !== storageUnitQuantity
			) {
				setTankQuantityError(true)
			} else {
				setTankQuantityError(false)
			}
		} else {
			setTankQuantityError(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isSupplyChainModelEnforced,
		quantityWhenSupplyChainIsNotEnforced,
		storageUnitData,
		totalAvailableQty,
	])

	useEffect(() => {
		if (
			productDetailsBasedOnSupplyChainModel?.[0]?.availableQty !==
			undefined
		) {
			setCurrentAvailableQty(
				baseProduct &&
					baseProduct.length > 0 &&
					baseProduct[0]?.quantity &&
					!getIn(parentDocRef, ['id'])
					? baseProduct[0].quantity +
							productDetailsBasedOnSupplyChainModel[0]
								.availableQty
					: productDetailsBasedOnSupplyChainModel[0].availableQty
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productDetailsBasedOnSupplyChainModel, baseProduct])

	useEffect(() => {
		if (
			!isSupplyChainModelEnforced &&
			productDetailsBasedOnSupplyChainModel?.[0]?.availableQty !==
				undefined
		) {
			setTotalAvailableQty(
				productDetailsBasedOnSupplyChainModel.reduce(
					(prev, current) => {
						return prev + current.availableQty
					},
					0
				)
			)
		}
	}, [
		isSupplyChainModelEnforced,
		productDetailsBasedOnSupplyChainModel,
		storageUnitData,
	])

	useEffect(() => {
		if (productCount < 1) {
			setProductCount(1)
		}
	}, [productCount])

	useEffect(() => {
		let currenTankList = []
		if (
			values?.products?.length > 0 &&
			values.products[0].id &&
			values.products[0].supplyChainModel &&
			values.products[0].certification
		) {
			if (
				!isSupplyChainModelEnforced &&
				isSupplyChainFlowWithTank &&
				productDetailsBasedOnSupplyChainModel?.length
			) {
				productDetailsBasedOnSupplyChainModel.forEach(item => {
					if (item.storageUnitID) {
						currenTankList.push(item)
					}
				})
			} else {
				currenTankList = productDetailsBasedOnSupplyChainModel
			}
		}
		setTankList(currenTankList)

		if (values?.targetProducts?.length > 0) {
			let currentStorageUnitData = []
			if (!isSupplyChainModelEnforced && isSupplyChainFlowWithTank) {
				values.targetProducts.forEach(item => {
					if (item.storageUnitID) {
						currentStorageUnitData.push(item)
					}
				})
			} else {
				currentStorageUnitData = values.targetProducts
			}
			setStorageUnitData(currentStorageUnitData)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productDetailsBasedOnSupplyChainModel.length])

	useEffect(() => {
		if (
			values?.meta?.receiverEmailID &&
			(!values.activePartner ||
				!partners.find(item => item.id === values.activePartner))
		) {
			setEmailAdded(true)
		} else if (
			!globalOrgAdded &&
			values.activePartner &&
			partners.find(item => item.id === values.activePartner)
		) {
			setEmailAdded(false)
			setFieldValue(['meta', 'receiverEmailID'], '')
		}
		if (values?.meta?.brokerEmailID) {
			setBrokerAdded(true)
		}
		if (values?.meta?.attachedEntity && docCheck()) {
			const array = []
			array.push(values.meta.attachedEntity)
			setAttachfileArray(array)
		}

		if (
			values?.products?.length > 0 &&
			!isSupplyChainModelEnforced &&
			isDO &&
			(values.products[0].quantity === '' ||
				values.products[0].quantity === 0)
		) {
			setQuantityError(true)
		} else {
			setQuantityError(false)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values])

	useEffect(() => {
		if (
			isDO &&
			!isPlantationFlow &&
			values?.products?.length > 0 &&
			values?.products[0]?.id &&
			(!checkIfCertificatesExist ||
				(values.products[0]?.supplyChainModel &&
					values?.products[0]?.certification))
		) {
			dispatch(
				TradeDocDuc.creators.fetchProductDetailsBasedOnSupplyChainModel(
					values?.products && values?.products.length > 0
						? values?.products[0]?.id || ''
						: '',
					// eslint-disable-next-line no-nested-ternary
					checkIfCertificatesExist
						? (values?.products &&
								values?.products.length > 0 &&
								values.products[0].supplyChainModel) ||
								''
						: 'none',
					// eslint-disable-next-line no-nested-ternary
					checkIfCertificatesExist
						? (values?.products &&
								values?.products.length > 0 &&
								values?.products[0]?.certification) ||
								''
						: 'uncertified',
					isEdit ? values.receivingPartyID : values?.activePartner,
					actor,
					values.targetProducts,
					values?.meta?.productOwner
				)
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		keyValue,
		values?.products?.length,
		values?.products?.length && values?.meta?.productOwner,
	])

	useEffect(() => {
		setProductCount(
			values?.products?.length > 0 ? values?.products?.length : 1
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values?.id])

	useEffect(() => {
		if (locationState?.query?.parentDocRef?.includes('~')) {
			const type = locationState.query.parentDocRef.split('~')
			if (type[0] !== rootModule) {
				setFieldValue(['meta', 'attachedEntity'], {})
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rootModule])

	useEffect(() => {
		if (
			locationState?.payload?.documentReference &&
			locationState?.payload?.documentStatus
		) {
			dispatch(
				TradeDocDuc.creators.fetchDocumentAttachments({
					documentReference: locationState.payload.documentReference,
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationState])

	useEffect(() => {
		if (globalOrgAdded) {
			setFieldValue('activePartner', '')
			setFieldValue(['meta', 'enableGlobalPartner'], true)
		} else {
			setFieldValue(['meta', globalType, 'name'], '')
			setFieldValue(['meta', globalType, 'categories', [0], 'name'], '')
			setFieldValue(['meta', globalType, 'address', 'line1'], '')
			setFieldValue(['meta', globalType, 'contact', 'mobile'], '')
			setFieldValue(['meta', globalType, 'contact', 'email'], '')
		}
	}, [globalOrgAdded, globalType, setFieldValue])

	useEffect(() => {
		if (
			isMarketplaceOrg(orgdetails) &&
			getIn(locationState, ['payload', 'documentStatus']) !== 'draft'
		) {
			setGlobalOrgAdded(true)
		} else if (isEdit) {
			const currentEnablePartner = getIn(activeDocument, [
				'meta',
				'enableGlobalPartner',
			])
			if (
				currentEnablePartner &&
				(currentEnablePartner || false) !== globalOrgAdded
			) {
				setGlobalOrgAdded(
					getIn(activeDocument, ['meta', 'enableGlobalPartner'])
				)
			}
		}
	}, [orgdetails, activeDocument, isEdit, locationState, globalOrgAdded])

	useEffect(() => {
		if (tourModule === 'DOCreate') {
			if (isPO) {
				dispatch(WebTourDuc.creators.setActiveTourModule('POCreate'))
			}
			if (isDO) {
				dispatch(WebTourDuc.creators.setActiveTourModule('DOCreate2'))
			}
			if (isInvoice) {
				dispatch(
					WebTourDuc.creators.setActiveTourModule('invoiceCreate')
				)
			}
		}
	}, [tourModule, dispatch, rootModule, isPO, isDO, isInvoice])

	useEffect(() => {
		if (
			attachmentDoc &&
			attachmentDoc.length > 0 &&
			docCheck() &&
			!(documentStatus && documentStatus === 'edit')
		) {
			const array = []
			attachmentDoc.forEach(doc => {
				if (
					getIn(doc, ['type']) === 'file' &&
					getIn(doc, ['organizationID']) === getCurrentOrgID
				) {
					array.push(getIn(doc, ['file']))
					setAttachfileArray(array)
					setFieldValue(['meta', 'attachedEntity'], doc.file)
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attachmentDoc?.length, getCurrentOrgID])

	useEffect(() => {
		/** This called when edit to set quantity */
		if (values && values.products && values.products.length) {
			setQuantityWhenSupplyChainIsNotEnforced(
				getIn(values, ['products', 0, 'quantity'])
			)
		}
	}, [isEdit, values])

	useEffect(() => {
		if (productDetailsBasedOnSupplyChainModel.length > 0)
			setTankForCurrentSelection(true)
		else setTankForCurrentSelection(false)
	}, [productDetailsBasedOnSupplyChainModel.length])

	return (
		<div key={values.key}>
			<Box padding={8} width="100%" height="100%">
				{isLoading && <TransparentBgSpinner lockToScreen />}
				<Modal
					forceCloseviaButton
					size="large"
					show={showConfirmationModal}
					heading={t('tdmDetailsEntry.confirm')}
					body={
						<div>
							<P
								style={{
									fontSize: '24px',
									fontWeight: 500,
									marginBottom: '10px',
								}}
							>
								{t('tdmDetailsEntry.confirmModalMainText')}
							</P>
							<P
								style={{
									fontSize: '14px',
								}}
							>
								{t('tdmDetailsEntry.confirmModalSubText')}
							</P>
						</div>
					}
					closelabel={t('common.no')}
					confirmlabel={t('common.yes')}
					onClose={() => setShowConfirmationModal(false)}
					onConfirm={() => {
						const data = values
						data.products = []
						dispatch(
							TradeDocDuc.creators.setActiveDocument({
								rootModule,
								baseSchema: data,
							})
						)
						if (documentStatus && documentStatus === 'clone') {
							dispatch(
								MainRouteDuc.creators.switchPage(
									locationState.type,
									{
										...locationState.payload,
										action: 'attach-details-confirm',
										documentStatus: 'edit',
										baseValueSchema: data,
										forceScrolltoTop: true,
									},
									locationState.query
								)
							)
						} else {
							dispatch(
								MainRouteDuc.creators.switchPage(
									locationState.type,
									{
										...locationState.payload,
										action: 'attach-details-confirm',
										baseValueSchema: data,
										forceScrolltoTop: true,
									},
									locationState.query
								)
							)
						}
						dispatch(WebTourDuc.creators.setActiveTourModule(''))
						setShowConfirmationModal(false)
					}}
				/>
				<Modal
					size="large"
					show={showFindProductModal}
					body={
						<div
							style={{
								fontSize: '24px',
								fontWeight: 500,
								marginBottom: '10px',
							}}
						>
							{t('tdmDetailsEntry.contactYourAdmin')}
						</div>
					}
					confirmlabel={t('common.ok')}
					onClose={() => setShowFindProductModal(false)}
					onConfirm={() => {
						setShowFindProductModal(false)
					}}
				/>
				{breadCrumbBlock()}
				{headerBlock()}
				<Spacer size={8} />
				<Box
					style={{
						backgroundColor: theme.color.white,
						padding: '35px',
						border: `1px solid ${theme.color.grey4}`,
						borderRadius: '5px',
					}}
				>
					<form onSubmit={handleSubmit}>
						<Box>
							{recipientBlock()}
							{attachFilesBlock()}
							{documentDetailsBlock()}
							{productBlock()}
							<Spacer size={32} />
							{shippingBlock()}
							<Spacer size={32} />
							{authorizationBlock()}
							<Spacer size={32} horizontal={!isMobile} />
							{submitBlock()}
						</Box>
					</form>
				</Box>
			</Box>
			{(tourModule === 'DOCreate2' ||
				tourModule === 'POCreate' ||
				tourModule === 'invoiceCreate') && <WebTour />}
		</div>
	)
}

export { AttachDetailsEntry }
